import * as z from 'zod';
import { nonEmptyString } from '@vertice/utils/src/validation/sharedSchemas';

export const editUserTaskFormSchema = z.object({
  id: z.string().optional(),
  name: nonEmptyString,
  assignees: z.array(z.string()),
  description: z.string().optional(),
});

export type EditUserTaskFormData = z.infer<typeof editUserTaskFormSchema>;
