import dayjs from 'dayjs';
import keepOnlyLocalDatePart from '@vertice/utils/src/dates/keepOnlyLocalDatePart';

export const SOONEST_TARGET_SIGN_DATE = keepOnlyLocalDatePart(dayjs().add(30, 'day').startOf('day'))!;

export enum SecurityScore {
  NONE = 'NONE',
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
}
