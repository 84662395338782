import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IntegrationSuccess } from '@vertice/assets';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Button } from '@verticeone/design-system/src';
import { SLACK_PROVIDER_ID } from '../../common';
import IntegrationStatus from 'pages/Preferences/Integrations/components/IntegrationStatus';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import { useActivateIntegrationAndProvider } from 'pages/Preferences/Integrations/util';
import { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';

export const ConnectionSuccessStep: FC<WizardStepProps> = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { activate } = useActivateIntegrationAndProvider(SLACK_PROVIDER_ID, '', accountId);

  const navigate = useNavigate();

  const onFinish = () => {
    void activate().then(() => navigate(-1));
  };

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationSuccess />}
          title={t('PREFERENCES.INTEGRATIONS.SLACK.WIZARD.SETUP_SUCCESSFUL')}
        />
      }
      buttons={[
        <Button variant="solid" color="primary" size="S" isCaption onClick={onFinish}>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
