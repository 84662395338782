import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IntegrationSuccess } from '@vertice/assets';
import { Button } from '@verticeone/design-system/src';
import { STEPS, OKTA_INTEGRATION_ID, ConnectionFinishedStepProps } from './common';
import { useActivateIntegrationAndProvider } from 'pages/Preferences/Integrations/util';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import IntegrationStatus from 'pages/Preferences/Integrations/components/IntegrationStatus';

export const ConnectionSuccessStep = ({ setStep, accountId, setTestingInProgress }: ConnectionFinishedStepProps) => {
  const { t } = useTranslation();
  const { activate } = useActivateIntegrationAndProvider(OKTA_INTEGRATION_ID, OKTA_INTEGRATION_ID, accountId);

  useEffect(() => {
    setTestingInProgress(false);
  });

  const navigate = useNavigate();

  const onBack = () => {
    setTestingInProgress(false);
    setStep(STEPS.COLLECT_API_KEYS);
  };

  const onFinish = () => {
    activate()
      .then(() => navigate(-1))
      .catch(() => {});
  };

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationSuccess />}
          title={t('PREFERENCES.INTEGRATIONS.OKTA.USAGE.CONNECTION_SUCCESS')}
        />
      }
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption onClick={onFinish}>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
