import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { RegionItem } from '../types';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices';
import { useEffect, useState } from 'react';
import { useCloudInventory } from '../useCloudInventory';
import {
  ResourceCode,
  ResourceItem,
  useReservedInstancesByCodeQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import useEC2PriceList, { enhanceDataWithOnDemandPrice } from './useEC2PriceList';
import {
  getPriceListFilter,
  groupByRegionAndInstanceFamily,
  processQueryData,
  ReservedInstance,
} from '../useRIUtilizationData';

const useEc2RIUtilizationData = (): LoadableAdvanced<RegionItem[] | undefined> => {
  const { accountId } = useSelector(getAccount);
  const [computedReservedInstancesData, setComputedReservedInstancesData] = useState<RegionItem[] | undefined>([]);
  const [preprocessedData, setPreprocessedData] = useState<ReservedInstance[] | undefined>();
  const { showExpired, activeTab } = useCloudInventory();

  const {
    data,
    error,
    isFetching: isRIFetching,
  } = useReservedInstancesByCodeQuery({
    accountId: accountId!,
    code: ResourceCode.RiEc2,
  });

  const {
    data: priceListData,
    error: priceListError,
    isLoading: isEC2PriceListLoading,
  } = useEC2PriceList({
    filter: getPriceListFilter(preprocessedData),
  });

  useEffect(() => {
    setComputedReservedInstancesData([]);
  }, [showExpired]);

  useEffect(() => {
    if (!data || data?.resourceQuery?.__typename !== 'ResourceQueryResult') return;

    setPreprocessedData(
      processQueryData(data.resourceQuery?.items as ResourceItem[], showExpired, activeTab.instanceTypePosition)
    );
  }, [activeTab.instanceTypePosition, data, showExpired]);

  useEffect(() => {
    if (!preprocessedData?.length || !priceListData) return;

    const enhancedData = enhanceDataWithOnDemandPrice(preprocessedData, priceListData);

    const groupedData = groupByRegionAndInstanceFamily(enhancedData);
    setComputedReservedInstancesData(groupedData);
  }, [preprocessedData, priceListData]);

  return {
    error: error || priceListError,
    isEmpty: Boolean(computedReservedInstancesData && computedReservedInstancesData.length === 0),
    isLoading: isRIFetching || isEC2PriceListLoading,
    data: computedReservedInstancesData,
  };
};

export default useEc2RIUtilizationData;
