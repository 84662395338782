import { formatCurrency, MAXIMUM_FRACTION_DIGITS_DYNAMIC } from '@vertice/utils/src/formatting/currency';
import { formatPercentage } from '@vertice/utils/src/formatting/percentage';

export type TooltipValueFormatter = (value: number | null | undefined) => string;

export const percentageFormatter =
  (locale: string): TooltipValueFormatter =>
  (value) =>
    formatPercentage(Number(value), { locale });

export const storageVolumeFormatter =
  (locale: string, unit: string): TooltipValueFormatter =>
  (value) =>
    new Intl.NumberFormat(locale, { style: 'unit', unit }).format(Number(value));

export const currencyFormatter =
  (
    locale: string,
    currency: string,
    maximumFractionDigits: number | undefined = MAXIMUM_FRACTION_DIGITS_DYNAMIC
  ): TooltipValueFormatter =>
  (value) =>
    typeof value === 'number' ? formatCurrency(value, { currency, locale, maximumFractionDigits }) : 'N/A';
