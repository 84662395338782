import { AccountUser, UserInfo } from '@vertice/slices';
import { useCallback, useMemo } from 'react';
import { UserOption } from '../../user/UserSelect/types';
import { parseUserRef } from '../../../hooks/workflows/refUtils';
import { isNotNil } from '@vertice/utils/src/validation/isNotNil';
import safePick from '@vertice/utils/src/objects/safePick';
import { useRelevantUsersById } from '../../../hooks/useRelevantUsersById';

const isAccountUser = (user: AccountUser | UserInfo): user is AccountUser => 'userStatus' in user;
const accountUserToSelectUser = (user: AccountUser | UserInfo): UserOption => ({
  id: user.userId,
  email: user.email ?? '',
  isVerticeUser: isAccountUser(user),
  ...safePick(user, 'firstName', 'middleName', 'lastName'),
});

const useGetAssignableUsers = () => {
  const { usersById, isLoadingUsers } = useRelevantUsersById({ includeLoggedVerticeUser: true });

  const resolveUsersFromRefs = useCallback(
    (usersRefs: string[]): UserOption[] =>
      usersRefs
        .map((userRef) => {
          const { userId } = parseUserRef(userRef);
          const maybeUser = usersById[userId];
          return maybeUser ? accountUserToSelectUser(maybeUser) : null;
        })
        .filter(isNotNil),
    [usersById]
  );

  return useMemo(
    () => ({
      assignableUsers: Object.values(usersById ?? {}).map(accountUserToSelectUser),
      isLoadingAssignableUsers: isLoadingUsers,
      resolveUsersFromRefs,
    }),
    [isLoadingUsers, resolveUsersFromRefs, usersById]
  );
};

export default useGetAssignableUsers;
