import React, { useCallback, useMemo, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import {
  dayInMonthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { PeriodCostComparisonData } from './types';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import ColorSquare from '@vertice/core/src/components/charts/components/ColorSquare';
import EmptyStateError from '@vertice/dashboard/src/modules/cloud/components/EmptyStateError';
import { Loader } from '@verticeone/design-system/src';
import EmptyStateNoData from '@vertice/dashboard/src/modules/cloud/components/EmptyStateNoData';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import { useDateRangeFormatter } from '../utils';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';
import { useFormatDate } from '@vertice/utils/src/formatting/date';

type LoadableContentWrapperProps = {
  error: unknown;
  isEmpty: boolean;
  isLoading: boolean;
  children: React.ReactNode;
};

type CloudAccountsGraphProps = Omit<LoadableContentWrapperProps, 'children'> & {
  data: PeriodCostComparisonData;
};

const LoadableContentWrapper = ({ error, isEmpty, isLoading, children }: LoadableContentWrapperProps) => {
  const { palette } = useTheme();

  return (
    <Stack
      bgcolor={isEmpty || !!error ? 'transparent' : palette.core.color1}
      pt={6}
      pb={6}
      justifyContent="center"
      minHeight={328}
    >
      {!!error ? (
        <EmptyStateError />
      ) : isLoading ? (
        <Loader color={AWS_BRAND_COLOR} />
      ) : isEmpty ? (
        <EmptyStateNoData />
      ) : (
        children
      )}
    </Stack>
  );
};

const Graph = ({ data }: Pick<CloudAccountsGraphProps, 'data'>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.GRAPH' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const formatCurrency = useFormatCurrency();
  const formatDate = useFormatDate();
  const formatDateRange = useDateRangeFormatter();
  const {
    period: { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd },
  } = useCloudAnalytics();

  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();

  const { costs, previousCosts, dates, previousDates } = data;

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: { type: 'spline', height: 280, backgroundColor: 'transparent' },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          yAxis: {
            labels: { formatter: yLabelCurrencyFormatter(palette, locale, AWS_DEFAULT_CURRENCY, 1, 1) },
          },
          xAxis: {
            categories: dates,
            labels: { formatter: dayInMonthFormatter },
          },
          tooltip: { shared: true },
          series: [
            {
              id: 'cost',
              name: t('COST'),
              type: 'spline',
              color: palette.tertiary.color2,
              data: costs,
            },
            {
              id: 'previous_cost',
              name: t('PREVIOUS_COST'),
              type: 'spline',
              lineWidth: 1,
              dashStyle: 'Dash',
              color: palette.core.color5,
              data: previousCosts,
            },
          ],
        }),
      ]),
    [applyStyledHighcharts, applyXAxisOffset, palette, locale, dates, t, costs, previousCosts]
  );

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  const legendItems = useMemo<LegendItemData[]>(
    () =>
      extractLegendItemsFromSeries(
        {
          ...options,
          series: options.series?.map((serie) => {
            if (serie.id === 'cost') {
              return { ...serie, name: `${serie.name} (${formatDateRange(periodOneStart, periodOneEnd)})` };
            } else if (serie.id === 'previous_cost') {
              return { ...serie, name: `${serie.name} (${formatDateRange(periodTwoStart, periodTwoEnd)})` };
            }
            return serie;
          }),
        },
        ['cost', 'previous_cost']
      ),
    [formatDateRange, options, periodOneEnd, periodOneStart, periodTwoEnd, periodTwoStart]
  );

  return (
    <>
      <Box marginLeft="auto" marginRight={6}>
        {legendItems.length > 0 && <Legend items={legendItems} />}
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {({ points }) => {
          let currentDate: string, previousDate: string;
          if (!!points && points.length > 0) {
            currentDate = points[0].x as string;
            previousDate = previousDates[points[0].point.x] as string;
          }

          return (
            <TooltipWrapper>
              {points?.map((point) => {
                const formattedValue = formatCurrency(point.y!, {
                  currency: AWS_DEFAULT_CURRENCY,
                  maximumFractionDigits: 2,
                });
                const color = point.series.color as string;
                const id = point.series.options.id as string;
                const name = point.series.name;
                const date = id === 'cost' ? currentDate : previousDate;

                return (
                  <Stack direction="row" gap="7px" alignItems="center" key={id}>
                    {color && <ColorSquare color={color} />}
                    <Stack direction="column" flex={1}>
                      {name && (
                        <Text variant="body-regular" size="S" flex={1}>
                          {name}
                        </Text>
                      )}
                      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={8}>
                        <Text variant="body-bold" size="S">
                          {formattedValue}
                        </Text>
                        <Text variant="body-regular" size="S">
                          {date && `(${formatDate(date)})`}
                        </Text>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </>
  );
};

const BaseCloudAnalyticsGraph = ({ data, error, isEmpty, isLoading }: CloudAccountsGraphProps) => {
  return (
    <LoadableContentWrapper isLoading={isLoading} isEmpty={isEmpty} error={error}>
      <Graph data={data} />
    </LoadableContentWrapper>
  );
};

export default BaseCloudAnalyticsGraph;
