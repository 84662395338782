import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import useOptimizedVsUnoptimizedData from './useOptimizedVsUnoptimizedData';
import OptimizedVsUnoptimizedLineChart from './OptimizedVsUnoptimizedLineChart';
import { Grid, useTheme } from '@mui/material';
import SavingsByCategoryPieChart from './SavingsByCategoryPieChart';
import useSavingsByCategoryData from './useSavingsByCategoryData';
import { getLoadingStatus } from '@vertice/utils/src/async/loadableAdvanced';

const EstimatedSavingsCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ESTIMATED_SAVINGS' });
  const { palette } = useTheme();
  const pieChartQuery = useSavingsByCategoryData();
  const lineChartQuery = useOptimizedVsUnoptimizedData();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('TITLE')} tag="h2" />
        <CardHeaderDescription>{t('DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <Grid container alignItems="stretch">
        <Grid item xs={12} lg={4} sx={{ display: 'flex', flexDirection: 'column' }} py={6}>
          <LoadableContentWrapper
            error={pieChartQuery.error}
            isLoading={false} // The chart has its own loading states
            isEmpty={pieChartQuery.isEmpty}
            pb={6}
            pt={6}
            sx={{ justifyContent: 'center', flex: 1 }}
          >
            <SavingsByCategoryPieChart
              points={pieChartQuery.data}
              currency={pieChartQuery.currency}
              total={pieChartQuery.totalAnnualSaving}
            />
          </LoadableContentWrapper>
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          bgcolor={palette.core.color1}
        >
          <LoadableContentWrapper {...getLoadingStatus(lineChartQuery)} pb={6}>
            <OptimizedVsUnoptimizedLineChart data={lineChartQuery.data!} />
          </LoadableContentWrapper>
        </Grid>
      </Grid>
    </Card>
  );
};

export default EstimatedSavingsCard;
