import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { SingleValue } from 'react-select';
import { NotificationSettingsItem } from '@vertice/slices';
import { Text } from '@verticeone/design-system/src';
import { TextFieldCaption } from '@verticeone/design-system/src';
import { Select, SimpleOption } from '@verticeone/design-system/src';
import { IconWrapper } from '@verticeone/design-system/src';
import { SlackPreviewRenewal } from './SlackPreviewRenewal';
import { SaveSettingsData, SaveSettingsDataSections } from './ChannelSetupWrapper';

export type ChannelSettingsFormData = {
  channelId: string;
  timeThreshold?: string;
};

export type ChannelSetupProps = {
  channelSettings: NotificationSettingsItem;
  channels: NotificationSettingsItem[];
  onSave: (formData: SaveSettingsData) => void;
  savingSection?: SaveSettingsDataSections;
  loadingChannels: boolean;
};

type ChannelSetupRenewalProps = ChannelSetupProps;

export const ChannelSetupRenewal: FC<ChannelSetupRenewalProps> = ({
  channelSettings,
  onSave,
  savingSection,
  channels,
  loadingChannels,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<NotificationSettingsItem>(channelSettings);

  useEffect(() => {
    setFormData(channelSettings);
  }, [channelSettings]);

  const timeThresholdOptions = useMemo(() => {
    return [3, 4, 5].map((months) => ({
      label: t(`PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.TIME_${months}_MONTHS`),
      value: `P${months}M`,
    }));
  }, [t]);

  const handleChannelChange = (newValue: SingleValue<NotificationSettingsItem>) => {
    setFormData((current) => ({
      ...current,
      channelId: newValue ? newValue.channelId : '',
    }));
    onSave({
      renewal: {
        channelId: newValue ? newValue.channelId : '',
        timeThreshold: formData.timeThreshold,
      },
    });
  };

  return (
    <Stack direction="row" gap={6} p={6}>
      <Stack flex={1} gap={8}>
        <Stack gap={2}>
          <TextFieldCaption
            id="renewalChannelLabel"
            label={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_RENEWAL.CHANNEL_LABEL')}
            size="S"
            tooltip={{
              content: t(
                'PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_RENEWAL.CHANNEL_SELECTION_TITLE_TOOLTIP'
              ),
            }}
          />
          <Select<NotificationSettingsItem, false>
            value={channels.find((channel) => channel.channelId === formData?.channelId)}
            options={channels}
            isMulti={false}
            isClearable={true}
            variant="outlined"
            isLoading={savingSection === 'renewal' || loadingChannels}
            isDisabled={!!savingSection}
            size="M"
            getOptionLabel={(option) => option.channelName || option.channelId}
            getOptionValue={(option) => option.channelId}
            onChange={handleChannelChange}
            aria-labelledby="renewalChannelLabel"
            menuPosition="fixed"
            formatOptionLabel={(option) => {
              return (
                <Stack gap={1} direction="row" alignItems="center">
                  <IconWrapper icon={option.type === 'PUBLIC' ? TagOutlinedIcon : LockOutlinedIcon} size="M" />
                  {option.channelName}
                </Stack>
              );
            }}
          />
          {['ERROR', 'CHANNEL_NOT_FOUND'].includes(formData?.saveStatus ?? '') && (
            <Text variant="body-regular" size="XS" color="error">
              {t(`PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.ERROR_${formData?.saveStatus}`)}
            </Text>
          )}
        </Stack>
        <Stack gap={2}>
          <TextFieldCaption
            htmlFor="renewalTimeThreshold"
            label={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.TIME_THRESHOLD_TITLE')}
            size="S"
          />
          <Select<SimpleOption, false>
            variant="outlined"
            size="M"
            id="renewalTimeThreshold"
            options={timeThresholdOptions}
            value={timeThresholdOptions?.find(({ value }) => value === formData.timeThreshold)}
            isClearable={false}
            isMulti={false}
            onChange={(option: SingleValue<SimpleOption>) =>
              setFormData((prevSettings) => ({
                ...prevSettings,
                timeThreshold: option?.value,
              }))
            }
            isDisabled
          />
          <Text variant="body-regular" size="XS" color="text2">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.TIME_THRESHOLD_HELPER_TEXT')}
          </Text>
        </Stack>
      </Stack>
      <Stack flex={1} gap={2}>
        <Text variant="caption" size="S" color="text2">
          {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_PREVIEW_TITLE')}
        </Text>
        <SlackPreviewRenewal />
      </Stack>
    </Stack>
  );
};
