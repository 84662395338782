import { Stack } from '@mui/material';

import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { SectionWrapper } from './components/SectionWrapper';
import { DiscoveryAndUsage } from './DiscoveryAndUsage';
import { Security } from './Security';
import { CloudCostOptimization } from './AWS/AWS';
import { Accounting } from './Accounting';
import { Notifications } from './Notifications';
import { Workflows } from './Workflows';

export const Integrations = () => {
  const { isEnabled, getFeature } = useFeatures();
  const usageAnalyticsFeatureEnabled = isEnabled(FEATURES.USAGE_ANALYTICS);
  const ssoFeatureEnabled = isEnabled(FEATURES.SSO);
  const awsFeatureEnabled = isEnabled(FEATURES.AWS_COST_INSIGHT);
  const integrationsFeatureEnabled = isEnabled(FEATURES.INTEGRATIONS);
  const slackFeatureEnabled = isEnabled(FEATURES.INTEGRATION_SLACK);
  const jiraFeatureEnabled = isEnabled(FEATURES.INTEGRATION_JIRA);

  const mergeIntegrationFeature = getFeature(FEATURES.INTEGRATION_MERGE);
  const mergeIntegrationProperties = mergeIntegrationFeature?.properties;
  const mergeAccountingIntegrationAllowed = mergeIntegrationFeature && mergeIntegrationProperties?.accounting;

  return (
    <Stack gap={8}>
      {usageAnalyticsFeatureEnabled && (
        <SectionWrapper>
          <DiscoveryAndUsage />
        </SectionWrapper>
      )}
      {ssoFeatureEnabled && (
        <SectionWrapper>
          <Security />
        </SectionWrapper>
      )}
      {awsFeatureEnabled && (
        <SectionWrapper>
          <CloudCostOptimization />
        </SectionWrapper>
      )}
      {integrationsFeatureEnabled && mergeAccountingIntegrationAllowed && (
        <SectionWrapper>
          <Accounting />
        </SectionWrapper>
      )}
      {slackFeatureEnabled && (
        <SectionWrapper>
          <Notifications />
        </SectionWrapper>
      )}
      {jiraFeatureEnabled && (
        <SectionWrapper>
          <Workflows />
        </SectionWrapper>
      )}
    </Stack>
  );
};
