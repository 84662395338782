import { Button } from '@verticeone/design-system/src';
import { IconWrapper } from '@verticeone/design-system/src';
import dayjs from 'dayjs';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Modal, Stack } from '@mui/material';
import { StaticDateRangePicker } from '@verticeone/design-system/src';
import React from 'react';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { calculateLastBillingPeriodDate, ShortcutItemsWithLastBillingPeriodCount, useShortcutsItems } from './utils';
import keepOnlyLocalDatePart from '@vertice/utils/src/dates/keepOnlyLocalDatePart';
import { useFormatDate } from '@vertice/utils/src/formatting/date';
import { useTranslation } from 'react-i18next';
import { useCloudAnalytics } from '../../CloudAnalyticsContext';

// July 1, 2002 is the day when AWS was founded
const minDate = '2002-07-01';

const DateRangePicker = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.FILTER.DATE_RANGE' });
  const {
    period: { periodOneStart: dateFrom, periodOneEnd: dateTo },
    updatePeriod,
  } = useCloudAnalytics();
  const [open, setOpen] = React.useState(false);
  const formatDate = useFormatDate();

  const shortcutsItems = useShortcutsItems();

  const handleContractPopoverClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        <IconWrapper icon={CalendarTodayOutlined} />
        {dateFrom === dateTo ? (
          formatDate(dateFrom)
        ) : (
          <>
            {(dateFrom && formatDate(dateFrom)) || t('FROM')}
            <IconWrapper icon={ArrowForwardIcon} />
            {(dateTo && formatDate(dateTo)) || t('TO')}
          </>
        )}
      </Button>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleContractPopoverClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack>
          <StaticDateRangePicker
            color="tertiary"
            dateFrom={dateFrom}
            infoText={t('INFO_TEXT')}
            dateTo={dateTo}
            minDate={minDate}
            maxDate={keepOnlyLocalDatePart(calculateLastBillingPeriodDate()) ?? undefined}
            onChange={(newDateFrom, newDateTo, context) => {
              if (newDateFrom && newDateTo) {
                const periodOneStart = dayjs(newDateFrom);
                const periodOneEnd = dayjs(newDateTo);
                const lastBillingPeriodInMonths = (context?.shortcut as ShortcutItemsWithLastBillingPeriodCount)
                  ?.lastBillingPeriodInMonths;

                if (lastBillingPeriodInMonths) {
                  updatePeriod({
                    periodOneStart: keepOnlyLocalDatePart(periodOneStart)!,
                    periodOneEnd: keepOnlyLocalDatePart(periodOneEnd)!,
                    periodTwoStart: keepOnlyLocalDatePart(
                      periodOneStart.subtract(lastBillingPeriodInMonths, 'months').startOf('month')
                    )!,
                    periodTwoEnd: keepOnlyLocalDatePart(
                      periodOneEnd.subtract(lastBillingPeriodInMonths, 'months').endOf('month')
                    )!,
                  });
                } else {
                  const periodOneLength = periodOneEnd.diff(periodOneStart, 'day');
                  const isSameDay = periodOneStart.isSame(periodOneEnd);

                  const periodTwoStart = isSameDay
                    ? periodOneStart.subtract(1, 'day')
                    : periodOneStart.subtract(periodOneLength + 1, 'day');
                  const periodTwoEnd = isSameDay ? periodTwoStart : periodOneStart.subtract(1, 'day');

                  updatePeriod({
                    periodOneStart: keepOnlyLocalDatePart(periodOneStart)!,
                    periodOneEnd: keepOnlyLocalDatePart(periodOneEnd)!,
                    periodTwoStart: keepOnlyLocalDatePart(periodTwoStart)!,
                    periodTwoEnd: keepOnlyLocalDatePart(periodTwoEnd)!,
                  });
                }
              }
              handleContractPopoverClose();
            }}
            onCancel={handleContractPopoverClose}
            shortcutsItems={shortcutsItems}
          />
        </Stack>
      </Modal>
    </Box>
  );
};

export default DateRangePicker;
