import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@verticeone/design-system/src/components/Table';
import { Text } from '@verticeone/design-system/src';
import React, { useCallback } from 'react';
import { InvoiceViewModel } from '../types';
import { Stack, useTheme } from '@mui/material';
import { Divider } from '../../../components/Dialog/Components';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { DashIfUndefined } from './DashIfUndefined';
import { useTranslation } from 'react-i18next';

const HeaderCell: React.FC<{ children: string; width?: number; align?: 'left' | 'right' }> = ({
  children,
  width,
  align = 'right',
}) => (
  <TableCell width={width}>
    <Stack textAlign={align}>
      <Text variant="label" size="XS" color="text3">
        {children}
      </Text>
    </Stack>
  </TableCell>
);

const BodyCell: React.FC<{ children: string | undefined; width?: number }> = ({ children, width }) => {
  return (
    <TableCell width={width} align="right">
      <Text variant="body-regular" size="M" color="text1">
        <DashIfUndefined value={children} />
      </Text>
    </TableCell>
  );
};

const Shortened = ({ children, maxLength }: { children?: string; maxLength: number }) => (
  <>{children && children.length > maxLength ? `${children.substring(0, maxLength - 1)}…` : children}</>
);

export const InvoiceTableDetail = ({ invoice }: { invoice: InvoiceViewModel }) => {
  const { palette } = useTheme();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  const formatWithInvoiceCurrency = useCallback(
    (value: number | undefined) =>
      value !== undefined
        ? formatCurrency(value, { currency: invoice.currency ?? DEFAULT_CURRENCY, maximumFractionDigits: 2 })
        : '-',
    [formatCurrency, invoice.currency]
  );

  return invoice.items.length > 0 ? (
    <Stack bgcolor={palette.input.color1} direction="row">
      <Stack marginLeft={6} borderRight={`1px solid ${palette.core.color4}`} />
      <TableContainer noBorder>
        <Table size="M" color="neutral">
          <TableHead>
            <TableRow>
              <HeaderCell width={328} align={'left'}>
                {t('ENTITIES.INVOICE.ITEMS')}
              </HeaderCell>
              <HeaderCell width={61}>{t('ENTITIES.INVOICE.QUANTITY_SHORT')}</HeaderCell>
              <HeaderCell width={133}>{t('ENTITIES.INVOICE.ITEM_COST')}</HeaderCell>
              <HeaderCell width={133}>{t('ENTITIES.INVOICE.TOTAL')}</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Stack padding="16px 8px 16px 0">
                    <Text variant="body-bold" size="M" color="text1" title={item.name}>
                      <Shortened maxLength={200}>{item.name}</Shortened>
                    </Text>
                    <Text variant="body-regular" size="M" color="text1" title={item.description}>
                      <Shortened maxLength={100}>{item.description}</Shortened>
                    </Text>
                    <Text variant="body-regular" size="S" color="text3">
                      {item.vendor}
                    </Text>
                  </Stack>
                </TableCell>
                <BodyCell>{item.quantity}</BodyCell>
                <BodyCell>{formatWithInvoiceCurrency(item.itemCost)}</BodyCell>
                <BodyCell>{formatWithInvoiceCurrency(item.totalCost)}</BodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
    </Stack>
  ) : null;
};
