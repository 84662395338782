import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { useContractColumns } from '../../../columns';
import React, { useMemo, useState } from 'react';
import { isNotNil } from '@vertice/utils/src/validation/isNotNil';
import { Box } from '@mui/material';
import { GridSelect } from '@verticeone/design-system/src';
import { components, OptionProps } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system/src';

type ColumnSelectCellProps = GridRenderCellParams & {
  allSelectedColumns: string[];
};

export type ColumnSelectOption = {
  value: string;
  label: string;
  customFieldTypeLabel: string | undefined;
};

export const Option = (props: OptionProps<ColumnSelectOption, false>) => (
  <components.Option {...props}>
    {props.data.label}
    {props.data.customFieldTypeLabel && (
      <Text variant="body-regular" color="text3">
        {' '}
        {props.data.customFieldTypeLabel}
      </Text>
    )}
  </components.Option>
);

export const ColumnSelectCell = ({ id, field, value, allSelectedColumns }: ColumnSelectCellProps) => {
  const apiRef = useGridApiContext();
  const allColumns = useContractColumns();
  // We assume that the value used for the first render is the one that is saved in the large form context.
  const [lastSavedValue] = useState(value);
  const { t, i18n } = useTranslation();

  const options: ColumnSelectOption[] = useMemo(() => {
    return allColumns
      .filter(isNotNil)
      .filter(
        (col) =>
          // Exclude columns that are already selected at other rows...
          !allSelectedColumns.includes(col.field) ||
          // ...but keep the option value that was selected previously at this row...
          col.field === lastSavedValue
      )
      .map((column) => {
        const customFieldTypeLabelTKey = column.customFieldType
          ? `ENTITIES.CONTRACT_CUSTOMIZATION.CUSTOM_FIELD_TYPE.${column.customFieldType}`
          : undefined;
        const customFieldTypeLabel = customFieldTypeLabelTKey
          ? i18n.exists(customFieldTypeLabelTKey)
            ? t('ENTITIES.CONTRACT_CUSTOMIZATION.BRACKETS_CUSTOM_X', { fieldType: t(customFieldTypeLabelTKey) })
            : t('ENTITIES.CONTRACT_CUSTOMIZATION.BRACKETS_CUSTOM_FIELD')
          : undefined;

        return {
          value: column.field,
          label: column.headerName ?? '',
          customFieldTypeLabel: customFieldTypeLabel,
        };
      });
  }, [allColumns, allSelectedColumns, i18n, lastSavedValue, t]);

  return (
    <Box px={1} width="100%">
      <GridSelect
        isMulti={false}
        options={options}
        value={options.find((o) => o.value === value)}
        onChange={(o) => apiRef.current.setEditCellValue({ id, field, value: o?.value })}
        menuPosition="fixed"
        components={{
          Option,
        }}
      />
    </Box>
  );
};
