import { useTranslation } from 'react-i18next';
import { Stack, styled, useTheme } from '@mui/material';
import { Banner } from '@vertice/core/src/components/Banner/Banner';
import { Text } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import React, { MouseEvent } from 'react';
import { IconWrapper } from '@verticeone/design-system/src';
import CloudIcon from '@mui/icons-material/Cloud';
import { EDPConfigurationData } from './Configurations/useEDPConfigurationData';
import { ELIGIBILITY_THRESHOLD, NOT_YET_ELIGIBLE_THRESHOLD } from './constants';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

const StyledBanner = styled(Banner)(({ theme }) => ({
  width: '100%',
  height: 69,
  position: 'relative',
  padding: '16px',
}));

const StyledBackgroundIconWrapper = styled('div')(({ theme }) => ({
  color: 'transparent',
  justifyContent: 'center',
  alignItems: 'center',
  '-webkit-text-stroke': '1.5px white',
  fontFamily: 'sans-serif',
  fontSize: 80,
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: '100%',
}));

const StyledOpacityWrapper = styled('div')<{ opacity: number; alignItems: string }>(({ opacity, alignItems }) => ({
  width: 80,
  alignSelf: 'stretch',
  padding: 10,
  justifyContent: 'flex-start',
  alignItems: alignItems,
  gap: 10,
  display: 'flex',
  opacity: opacity,
  '& > div': {
    width: 60,
    height: 60,
    opacity: opacity,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

type OpacityBackgroundIconWrapperProps = {
  opacity: number;
  alignItems?: string;
  children: string;
};

const OpacityBackgroundIconWrapper = ({
  opacity = 1,
  alignItems = 'flex-start',
  children,
}: OpacityBackgroundIconWrapperProps) => (
  <StyledOpacityWrapper opacity={opacity} alignItems={alignItems}>
    <StyledBackgroundIconWrapper>{children}</StyledBackgroundIconWrapper>
  </StyledOpacityWrapper>
);

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.tertiary.color4,
  justifyContent: 'center',
  alignItems: 'center',
}));

type EDPBannerProps = {
  data: EDPConfigurationData;
  onClick?: (evt: MouseEvent) => void;
};

const EDPBanner = ({ data, onClick }: EDPBannerProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_BANNER' });
  const { palette } = useTheme();
  const { isActive, eligibleSpend } = data;
  const formatCurrency = useFormatCurrency();

  const message =
    !isActive && eligibleSpend <= NOT_YET_ELIGIBLE_THRESHOLD
      ? t('NOT_YET_ELIGIBLE', {
          spend_placeholder: formatCurrency(eligibleSpend, {
            currency: AWS_DEFAULT_CURRENCY,
            maximumFractionDigits: 0,
          }),
          threshold_placeholder: formatCurrency(ELIGIBILITY_THRESHOLD, {
            currency: AWS_DEFAULT_CURRENCY,
            maximumFractionDigits: 0,
          }),
        })
      : null;

  if (!message) {
    return null;
  }

  return (
    <StyledBanner color={palette.tertiary.color2}>
      <Stack zIndex={100} direction="row" justifyContent="space-between" alignItems="center" gap={1} flexGrow={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <StyledIconWrapper size="XL" icon={CloudIcon} />
          <Text variant="heading" size="XS" color={palette.core.bg}>
            {message}
          </Text>
        </Stack>
        {onClick && (
          <Button variant="solid" size="M" color="tertiary" onClick={onClick}>
            {t('CONTACT_CS_TEAM')}
          </Button>
        )}
      </Stack>
      <div
        style={{
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          position: 'absolute',
          background: 'linear-gradient(90deg, #7812a56e 0%, #000027 40% 80%, #7812a5c2 100%)',
        }}
      >
        <div
          style={{
            width: 4860,
            height: 1620,
            left: -1350,
            top: -270,
            position: 'absolute',
            boxShadow: '512px 512px 512px ',
            filter: 'blur(512px)',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 512,
            opacity: 0.4,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              flex: '1 1 0',
              alignSelf: 'stretch',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: '1 1 0',
                alignSelf: 'stretch',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div style={{ flex: '1 1 0', alignSelf: 'stretch', background: '#b61dc2' }}></div>
            </div>
          </div>
          <div
            style={{
              flex: '1 1 0',
              alignSelf: 'stretch',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: '1 1 0',
                alignSelf: 'stretch',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div style={{ flex: '1 1 0', alignSelf: 'stretch', background: '#b61dc2' }}></div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            position: 'absolute',
            mixBlendMode: 'soft-light',
            background: '#000027',
            display: 'none',
          }}
        />
      </div>
      <div
        style={{
          height: 118,
          right: 220,
          top: -26,
          position: 'absolute',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 10,
          display: 'inline-flex',
        }}
      >
        <OpacityBackgroundIconWrapper opacity={0.2}>€</OpacityBackgroundIconWrapper>
        <OpacityBackgroundIconWrapper alignItems="flex-end" opacity={0.3}>
          ₣
        </OpacityBackgroundIconWrapper>
        <OpacityBackgroundIconWrapper opacity={0.35}>₹</OpacityBackgroundIconWrapper>
        <OpacityBackgroundIconWrapper alignItems="flex-end" opacity={0.4}>
          $
        </OpacityBackgroundIconWrapper>
        <OpacityBackgroundIconWrapper opacity={0.35}>€</OpacityBackgroundIconWrapper>
      </div>
    </StyledBanner>
  );
};

export default EDPBanner;
