import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { getDaysFromNow, useGetLastUsed, MORE_THAN_MONTH } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ProgressiveDisclosureStack } from './ProgressiveDisclosureStack';
import SkeletonForCell from './SkeletonForCell';
import { useFormatDate } from '@vertice/utils/src/formatting/date';

type LastUsedCellProps = {
  lastUsed: string | null | undefined;
  showFullDate?: boolean;
};

export const LastUsedCell = ({ lastUsed, showFullDate }: LastUsedCellProps) => {
  const { t } = useTranslation();
  const { getDaysFromNowInString } = useGetLastUsed();
  const formatDate = useFormatDate();

  if (lastUsed === undefined) {
    return <SkeletonForCell />;
  }

  if (!lastUsed) {
    return null;
  }

  const lastUsedDays = getDaysFromNow(lastUsed);

  if (lastUsedDays === undefined || lastUsedDays === null || lastUsedDays < 0) {
    return null;
  }

  const lastUsedDate = dayjs().subtract(lastUsedDays, 'day').toISOString();

  const dateFormated =
    lastUsedDays >= MORE_THAN_MONTH
      ? formatDate(lastUsedDate, { formatterOptions: { year: 'numeric', month: 'short' } })
      : formatDate(lastUsedDate);

  return (
    <Stack direction="column">
      {lastUsed && <Typography variant="body-regular-m">{getDaysFromNowInString(lastUsedDays)}</Typography>}
      {!lastUsed && <Divider sx={{ width: '16px', marginTop: '8px', marginBottom: '8px' }} />}
      <ProgressiveDisclosureStack height="22px" direction="row" alignItems="center">
        <Typography variant="caption-s" sx={{ color: 'var(--tokens-color-light-inactive-1)' }}>
          {lastUsed ? dateFormated : t('APPLICATIONS.USAGE.LAST_USED.NO_DATA')}
        </Typography>
      </ProgressiveDisclosureStack>
    </Stack>
  );
};
