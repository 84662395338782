import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { JiraIcon } from '@vertice/assets';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { IconWrapper, Loader } from '@verticeone/design-system/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { IntegrationSetup } from 'pages/Preferences/Integrations/components/IntegrationSetup';
import { ActionButton } from './ActionButton';
import { StepContent } from './StepContent';
import { JIRA_PROVIDER_ID } from './constants';
import { JiraSetupStep } from './types';

export const JiraSetupWizard = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { data: jiraIntegrationData, isLoading: isLoadingJiraIntegrationData } = useGetAccountIntegrationQuery(
    { accountId: accountId, integrationId: JIRA_PROVIDER_ID },
    { skip: !accountId }
  );

  const [step /*setStep*/] = useState<JiraSetupStep>(JiraSetupStep.CONNECTION);

  const activeStep = useMemo(() => {
    if (step === JiraSetupStep.CONNECTION && jiraIntegrationData?.status === 'ACTIVE') {
      return JiraSetupStep.CONFIGURATION;
    }
    return step;
  }, [jiraIntegrationData?.status, step]);

  if (!isLoadingJiraIntegrationData) {
    return (
      <Box sx={{ height: '100%' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <IntegrationSetup
      breadcrumbLabel={t('PREFERENCES.INTEGRATIONS.JIRA.SETUP_CARD.TITLE')}
      breadcrumbIcon={<IconWrapper icon={JiraIcon} />}
      actionButton={<ActionButton activeStep={activeStep} />}
      stepContent={<StepContent activeStep={activeStep} />}
    />
  );
};
