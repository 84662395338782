import { Stack, useTheme } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';

import i18n from '../../../translate';
import { SendTwoTone } from '@mui/icons-material';
import { SendCommentCallback } from '../types';
import { FC, useMemo, useState } from 'react';
import useKeyboardShortcuts from '../../../utils/useKeyboardShortcuts';

import { MentionUser } from '../../Comment/types';
import { MentionsTextArea } from '../../Mentions';
import { Placeholders } from '../../Mentions/types';

import { MENTION_PATTERN_GROUP, useMention } from '../utils';

const CommentInputWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.core.bg,
  padding: theme.spacing(4),
  minHeight: 0,
  flexShrink: 0,
  position: 'relative',
}));

const SendCommentButtonWrapper = styled(Stack)<{ $disabled: boolean }>(({ theme, $disabled }) => ({
  position: 'absolute',
  right: theme.spacing(8),
  bottom: theme.spacing(6),
  cursor: $disabled ? 'not-allowed' : 'pointer',
}));

export type CommentInputProps = {
  currentUser?: string;
  users?: { [x: string]: MentionUser };
  onSend?: SendCommentCallback;
  disabled?: boolean;
};

const CommentInput: FC<CommentInputProps> = ({ currentUser, users = {}, onSend, disabled = false }) => {
  const [comment, setComment] = useState<string | undefined>(undefined);

  const userMention = useMention(currentUser);
  const { t } = useTranslation(undefined, { i18n });
  const { palette } = useTheme();

  const resetField = () => setComment('');

  const handleSendComment = () => {
    if (comment) {
      setTimeout(() => onSend?.(comment, resetField), 0);
    }
  };

  const { handlers } = useKeyboardShortcuts({
    ignoreLetterPresses: true,
    shortcuts: {
      submit: {
        keys: ['Enter'],
        action: handleSendComment,
        onDownCallback: (e) => e.preventDefault(), // Prevents new line on Enter
      },
      newLine: {
        keys: ['Alt', 'Enter'],
        onDownCallback: () => setComment((prev) => `${prev}\n`),
      },
    },
  });

  const props = useMemo(
    () => ({
      trigger: '@',
      markup: `<@${Placeholders.id}>`,
      regex: MENTION_PATTERN_GROUP,
      displayTransform: (id: string) => userMention(users[id]).label,
      appendSpaceOnAdd: true,

      suggestions: Object.entries(users).map(([id, user]) => ({ id, ...userMention(user, '') })),
    }),
    [users, userMention]
  );

  return (
    <CommentInputWrapper justifyContent="center" minHeight={0}>
      <MentionsTextArea
        size="S"
        value={comment}
        onChange={(value) => setComment(value)}
        {...props}
        inputProps={{
          ...handlers,
          disabled: disabled,
          minHeight: 3.5,
          variant: 'outline',
          color: 'transparent',
          placeholder: t('COMMENTS.ADD_COMMENT'),
        }}
      />
      <SendCommentButtonWrapper onClick={handleSendComment} $disabled={disabled}>
        <SendTwoTone htmlColor={palette.secondary.color2} fontSize="small" />
      </SendCommentButtonWrapper>
    </CommentInputWrapper>
  );
};

export default CommentInput;
