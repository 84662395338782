import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { Text } from '@verticeone/design-system/src';

export type BoldMoneyCellProps = { value: number; currency: string };

const BoldMoneyCell = ({ value, currency }: BoldMoneyCellProps) => {
  const formatCurrency = useFormatCurrency();
  return <Text variant="body-bold">{formatCurrency(value, { currency, maximumFractionDigits: 0 })}</Text>;
};

export default BoldMoneyCell;
