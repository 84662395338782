/**
 * Like `groupBy`, but allows to put one item into multiple groups.
 *
 * See tests for example.
 */
const groupByMultipleKeys = <Item, Key extends string>(
  collection: Item[],
  getGroupingKeys: (item: Item) => Key[]
): Record<Key, Item[]> =>
  collection.reduce((result, value) => {
    const keys = getGroupingKeys(value);
    keys.forEach((key) => {
      if (result.hasOwnProperty(key)) {
        result[key].push(value);
      } else {
        result[key] = [value];
      }
    });

    return result;
  }, {} as Record<Key, Item[]>);

export default groupByMultipleKeys;
