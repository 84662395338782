import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro';
import type { OptimizationTestRow } from '../useOptimizationTestsData';

import { RecommendationStatus } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { Text } from '@verticeone/design-system/src';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useCloudOptimization } from '../../../CloudOptimizationContext';

type SavingColumnProps = GridRenderCellParams<OptimizationTestRow, any, any, GridTreeNodeWithRender>;

const SavingColumn = ({ row: { recommendations }, value }: SavingColumnProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.COLUMNS' });
  const { isAnnualPeriod } = useCloudOptimization();
  const formatCurrency = useFormatCurrency();

  const savingsCount = recommendations.length;
  const savingsIgnoredCount = recommendations.filter(({ status }) => status === RecommendationStatus.Cancelled).length;
  const color = savingsIgnoredCount ? 'text3' : 'text1';

  return (
    <Stack gap={2} alignItems="flex-end">
      <Text variant="body-bold" size="M" testId="currency-data" color={color}>
        {formatCurrency(value * (isAnnualPeriod ? 12 : 1), {
          currency: AWS_DEFAULT_CURRENCY,
          maximumFractionDigits: 0,
        })}
      </Text>
      {!!savingsIgnoredCount && (
        <Text variant="body-regular" size="M" testId="currency-data" color={color}>
          {savingsCount !== savingsIgnoredCount ? t('SOME_SAVINGS_IGNORED') : t('SAVINGS_IGNORED')}
        </Text>
      )}
    </Stack>
  );
};

export default SavingColumn;
