import format from 'date-fns/format';
import Highcharts from 'highcharts';
import { kebabCase, chain } from 'lodash';
import { Palette } from '@mui/material';
import { Typography } from '@mui/material/styles/createTypography';
import { PointWithState } from '../types';
import { formatCurrency, MAXIMUM_FRACTION_DIGITS_DYNAMIC } from '@vertice/utils/src/formatting/currency';
import { formatPercentage } from '@vertice/utils/src/formatting/percentage';

export const monthFormatter: Highcharts.AxisLabelsFormatterCallbackFunction = ({ value, isFirst }) => {
  try {
    const date = new Date(value);
    const isJanuary = date.getMonth() === 0;
    return format(date, isJanuary && !isFirst ? "MMM ''yy" : 'MMM');
  } catch (ex) {
    return '';
  }
};

export const dayInMonthFormatter: Highcharts.AxisLabelsFormatterCallbackFunction = ({ value, isFirst }) => {
  const date = new Date(value);
  return format(date, 'MMM dd');
};

/**
 * Takes object with camelCase CSS props and turns it into string that can be used in an HTML style prop.
 */
export const cssObjectToString = (object: Record<string, unknown>) =>
  chain(object)
    .pickBy((value): value is number | string => ['number', 'string'].includes(typeof value))
    .map((value, key) => `${kebabCase(key)}: ${value};`)
    .value()
    .join(' ');

export const yLabelStyles = (palette: Palette) => ({
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: palette.core.color3,
  backgroundColor: palette.core.color1,
  padding: '1px 7px',
});

export const yLabelCurrencyFormatter =
  (
    palette: Palette,
    locale: string,
    currency: string,
    minimumFractionDigits?: number | undefined,
    maximumFractionDigits: number | undefined = MAXIMUM_FRACTION_DIGITS_DYNAMIC,
    compact = false
  ) =>
  ({ value }: { value: number | string }) => {
    const formattedValue = formatCurrency(value as number, {
      currency,
      locale,
      minimumFractionDigits,
      maximumFractionDigits,
      compact,
      withSpace: true,
    });

    return `<span style="${cssObjectToString(yLabelStyles(palette))}">${formattedValue}</span>`;
  };

export const yLabelPercentageFormatter =
  (palette: Palette, locale: string) =>
  ({ value }: { value: number | string }) => {
    const formattedValue = formatPercentage(Number(value), { locale });

    return `<span style="${cssObjectToString(yLabelStyles(palette))}">${formattedValue}</span>`;
  };

export const yLabelStorageVolumeFormatter =
  (palette: Palette, locale: string, unit: string) =>
  ({ value }: { value: number | string }) => {
    const formattedValue = new Intl.NumberFormat(locale, { style: 'unit', unit }).format(Number(value));

    return `<span style="${cssObjectToString(yLabelStyles(palette))}">${formattedValue}</span>`;
  };

export const pieChartLabelFormatter =
  (
    palette: Palette,
    typography: Typography,
    locale: string,
    currency: string,
    maximumFractionDigits: number | undefined
  ) =>
  (point: PointWithState) => {
    const { name, y, percentage, state } = point;

    const formattedValue = formatCurrency(y as number, {
      currency,
      locale,
      maximumFractionDigits,
    });

    const formattedPercentage = percentage ? `(${Math.round(percentage)}%)` : '';

    const labelWrapperStyles = cssObjectToString({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textDecoration: 'none',
    });

    const pieChartNameStyles = cssObjectToString({
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: palette.text.color2,
    });

    const pieChartValueStyles = cssObjectToString({
      ...typography['heading-xs'],
      color: palette.text.color1,
    });

    const pieChartPercentageStyles = cssObjectToString({
      ...typography['heading-xxs'],
      color: state === 'hover' ? palette.text.color2 : 'transparent',
    });

    // prettier-ignore
    return (
      `<div style="${labelWrapperStyles}">` +
        `<span style="${(pieChartNameStyles)}">${name}</span>` +
        `<span style="${(pieChartValueStyles)}">${formattedValue}</span>` +
        `<span style="${pieChartPercentageStyles}">${formattedPercentage}</span>` +
      `</div>`
    );
  };
