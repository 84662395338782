import { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { FormatDateOptions, useFormatDate } from '@vertice/utils/src/formatting/date';

import { Text } from '@verticeone/design-system/src';
import { Divider } from '@verticeone/design-system/src';
import { Timeline, VendorDetails } from './types';

const DATE_FORMAT: FormatDateOptions = {
  fallbackValue: '-',
  formatterOptions: { month: 'short', day: 'numeric' },
};

const RegularText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.color5,
}));
const RegularCoreText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.color4,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: theme.spacing(30),
}));
const UpperText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.color5,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
}));
const UpperCoreText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.color4,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
}));

type ScheduleInfoProps = {
  scheduleDate: string;
} & VendorDetails;
export const VendorInfo: FC<ScheduleInfoProps> = ({ vendorName, products, annualCost, currency, scheduleDate }) => {
  const formatCurrency = useFormatCurrency();
  const formatDate = useFormatDate();
  const { t } = useTranslation();

  return (
    <Stack flexDirection="row" gap={4} justifyContent="space-between">
      <Stack justifyContent="space-between" paddingY={1} flex={1}>
        <RegularText size="M" variant="body-bold">
          {vendorName}
        </RegularText>
        <RegularCoreText size="S" variant="body-regular">
          {products?.join(', ')}
        </RegularCoreText>
      </Stack>

      <Stack justifyContent="space-between" paddingY={1} marginTop={0.75} flex={1}>
        <Stack flexDirection="row" justifyContent="space-between" gap={1}>
          <UpperCoreText size="XS" variant="body-bold">
            {t('DASHBOARD.SCHEDULE.ANNUAL_COST')}
          </UpperCoreText>
          <UpperText size="XS" variant="body-bold">
            {annualCost
              ? formatCurrency(annualCost, {
                  currency,
                  maximumFractionDigits: 0,
                })
              : '-'}
          </UpperText>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" gap={1}>
          <UpperCoreText size="XS" variant="body-bold">
            {t('DASHBOARD.SCHEDULE.DEADLINE')}
          </UpperCoreText>
          <UpperText size="XS" variant="body-bold">
            {formatDate(scheduleDate, DATE_FORMAT)}
          </UpperText>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const TimelineInfo: FC<Timeline> = ({ targetSignDate, renewalDate, autoRenewalDeadline, autoRenewalWaived }) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <Stack flex={1}>
        <Stack flexDirection="row" justifyContent="space-between" gap={1}>
          <UpperCoreText size="XS" variant="body-bold">
            {t('DASHBOARD.SCHEDULE.TARGET_SIGN')}
          </UpperCoreText>
          <UpperText size="XS" variant="body-bold">
            {formatDate(targetSignDate, DATE_FORMAT)}
          </UpperText>
        </Stack>
        {renewalDate && (
          <>
            <Stack flexDirection="row" justifyContent="space-between" gap={1}>
              <UpperCoreText size="XS" variant="body-bold">
                {t('DASHBOARD.SCHEDULE.RENEWAL')}
              </UpperCoreText>
              <UpperText size="XS" variant="body-bold">
                {formatDate(renewalDate, DATE_FORMAT)}
              </UpperText>
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between" gap={1}>
              <UpperCoreText size="XS" variant="body-bold">
                {t('DASHBOARD.SCHEDULE.AUTO_RENEWAL')}
              </UpperCoreText>
              <UpperText
                size="XS"
                variant="body-bold"
                sx={{ textDecoration: autoRenewalWaived ? 'line-through' : 'none' }}
              >
                {formatDate(autoRenewalDeadline, DATE_FORMAT)}
              </UpperText>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};
