import ContractFormEntry, { ContractFormEntryProps } from '../../ContractFormEntry';
import FormProductsField, {
  type FormProductsFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormProductsField';
import React, { FC, ReactNode } from 'react';
import { ContractFormData } from '../../types';
import useWatchContractFormField from '../../hooks/useWatchContractFormField';
import { GridRowParams } from '@mui/x-data-grid-pro';

type Component = FormProductsFieldComponentType<ContractFormData>;

type ProductsContractFormEntryProps = Omit<
  ContractFormEntryProps<Component, Component>,
  'component' | 'writeComponent'
> & {
  showAnnualCost?: boolean;
  showLicenseType?: boolean;
  detailRowRenderer?: (params: GridRowParams) => ReactNode;
};

const ProductsContractFormEntry: FC<ProductsContractFormEntryProps> = ({
  showAnnualCost = true,
  showLicenseType = false,
  detailRowRenderer,
  ...props
}) => {
  const vendor = useWatchContractFormField('parts.contractual.vendor.vendorOption');
  const currency = useWatchContractFormField('parts.contractual.financial.baseCurrency');

  const commonProps = {
    vendorId: vendor?.type === 'INLINE' ? undefined : vendor?.id,
    currency,
    showAnnualCost,
    showLicenseType,
  };
  return (
    <ContractFormEntry<Component, Component>
      {...props}
      component={FormProductsField}
      componentProps={{ ...commonProps, detailRowRenderer }} // Detail row is visible only in view mode
      writeComponentProps={{ ...commonProps, editable: true }}
      writeComponent={FormProductsField}
    />
  );
};

export default ProductsContractFormEntry;
