import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogText,
  DialogTextWrapper,
} from '@verticeone/design-system/src/components/Dialog';
import { Button } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { useContractContext } from '../ContractContext';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

interface DeleteContractDialogProps {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const ContractDetails = () => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const contract = contractContext.fetchedContract.contract;

  const vendorName = contract.parts.contractual?.vendor?.vendorName;
  const simplifiedStage = contract.materialized?.simplifiedStage;
  const annualCost = contract.parts.overview?.effectiveAnnualCost;
  const currency = contract.parts.contractual?.financial?.baseCurrency ?? DEFAULT_CURRENCY;
  const translatedStage = t(`ENTITIES.CONTRACT.STAGES_ON_DETAIL.${simplifiedStage}`);

  return (
    <>
      <DialogText align="left" variant="body-regular">{`${t(
        'ENTITIES.CONTRACT.LABELS.VENDOR'
      )}: ${vendorName}`}</DialogText>
      <DialogText align="left" variant="body-regular">
        {`${t('ENTITIES.CONTRACT.LABELS.STAGE')}: ${translatedStage}`}
      </DialogText>
      {annualCost && (
        <DialogText align="left" variant="body-regular">
          {`${t('ENTITIES.CONTRACT.LABELS.ANNUAL_COST')}: ${formatCurrency(annualCost, { currency })}`}
        </DialogText>
      )}
    </>
  );
};

export const DeleteContractDialog = ({ isOpened, onClose, onSubmit }: DeleteContractDialogProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT.DIALOGS.DELETE_CONTRACT' });

  return (
    <Dialog size="M" open={isOpened} align="center">
      <DialogHeader>{t('TITLE')}</DialogHeader>
      <DialogContent>
        <DialogTextWrapper>
          <DialogText align="left" variant="body-bold">
            {t('MESSAGE_PRIMARY')}
          </DialogText>
          <ContractDetails />
          <DialogText align="left" variant="body-regular">
            {t('MESSAGE_SECONDARY')}
          </DialogText>
        </DialogTextWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline">
          {t('BUTTONS.CANCEL')}
        </Button>
        <Button onClick={onSubmit} variant="solid">
          {t('BUTTONS.DELETE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
