import React, { useCallback, useMemo } from 'react';
import { InvoiceViewModel } from '../types';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton as IconButtonMui, Stack, styled } from '@mui/material';
import { IconButton } from '@verticeone/design-system/src';
import { DataGrid } from '@verticeone/design-system/src';
import { CopyAllOutlined } from '@mui/icons-material';
import { Text } from '@verticeone/design-system/src';
import { InvoiceTableDetail } from './InvoiceTableDetail';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { DashIfUndefined } from './DashIfUndefined';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

type InvoicesTableProps = {
  rows: InvoiceViewModel[];
};

const ExpandCell = (props: GridRenderCellParams) => {
  const { value: isExpanded, row } = props;

  if (row.items.length === 0) {
    return ''; // needs to be an empty string to force the cell to be empty
  }

  return (
    <IconButtonMui size="small" sx={{ margin: '0 -7px' }}>
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 0 : -90}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
          color: (theme) => theme.palette.text.color1,
        }}
        fontSize="inherit"
      />
    </IconButtonMui>
  );
};

const IssueDateCell = ({ value }: GridRenderCellParams) => {
  const { locale } = useLocaleContext();
  return (
    <Text variant="body-regular" size="M">
      <DashIfUndefined
        value={new Date(value).toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' })}
      />
    </Text>
  );
};

const AmountCell = ({ value, row }: GridRenderCellParams) => {
  const formatCurrency = useFormatCurrency();
  const formatted = formatCurrency(value, { currency: row.currency ?? DEFAULT_CURRENCY, maximumFractionDigits: 2 });
  return (
    <Text variant="body-regular" size="M">
      <DashIfUndefined value={formatted} />
    </Text>
  );
};

const StyledInvoiceNumberCell = styled(Stack)({
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiButton-root': {
    display: 'none',
  },
  '&:hover .MuiButton-root': {
    display: 'flex',
  },
});

const InvoiceNumberCell = ({ value }: GridRenderCellParams) => {
  const handleClick = useCallback(() => {
    void navigator.clipboard?.writeText(value);
  }, [value]);
  return (
    <StyledInvoiceNumberCell>
      <Text variant="body-regular" size="M" color="text3">
        <DashIfUndefined value={value} />
      </Text>
      {value && (
        <Stack flexGrow={1} alignItems="flex-end">
          <IconButton icon={CopyAllOutlined} onClick={handleClick} variant="plain" color="neutral" />
        </Stack>
      )}
    </StyledInvoiceNumberCell>
  );
};

export const InvoicesTable: React.FC<InvoicesTableProps> = ({ rows }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.INVOICE' });
  const columns = useMemo(() => {
    const colDefs: GridColDef[] = [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        minWidth: 43,
        renderCell: ExpandCell,
      },
      {
        field: 'issueDateTimestamp',
        headerName: t('ISSUE_DATE'),
        renderCell: IssueDateCell,
        editable: false,
        flex: 1,
      },
      {
        field: 'invoiceNumber',
        headerName: t('INVOICE_NUMBER'),
        renderCell: InvoiceNumberCell,
        editable: false,
        flex: 1,
      },
      {
        field: 'netAmount',
        headerName: t('NET_AMOUNT'),
        renderCell: AmountCell,
        editable: false,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
      },
    ];
    return colDefs;
  }, [t]);

  return (
    <DataGrid<InvoiceViewModel>
      sx={{ border: 'none', borderRadius: 0 }}
      rows={rows}
      disableRowSelectionOnClick
      columns={columns}
      sortingMode="client"
      hideFooter
      rowHeight={60}
      showCellVerticalBorder
      showColumnVerticalBorder
      autoHeight={true}
      getDetailPanelContent={({ row }) => <InvoiceTableDetail invoice={row} />}
      getDetailPanelHeight={() => 'auto'} // Height based on the content.
      initialState={{
        sorting: {
          sortModel: [{ field: 'issueDateTimestamp', sort: 'desc' }],
        },
      }}
      noBorder
      noBorderRadius
    />
  );
};
