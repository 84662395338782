import { useCallback, useMemo } from 'react';
import { camelCase } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { removeNullables } from '@vertice/utils/src/objects/removeNullables';
import { useDataVisibility } from './utils';

export type OptimizationTestsTableState = {
  expandedTests: string[];
};

enum SearchParamKey {
  EXPANDED_TESTS = 'expandedTests',
}

const useQueryParams = () => {
  const [queryParams, setQueryParams] = useSearchParams();

  const updateQueryParams = (searchParamsToUpdate: { [key: string]: string }) => {
    // below we convert iterator queryParams into plain object
    const queryParamsObj = Object.fromEntries(queryParams.entries());

    return setQueryParams(removeNullables({ ...queryParamsObj, ...searchParamsToUpdate }));
  };

  return { queryParams, updateQueryParams };
};

/**
 * Stores the state of the OptimizationTestsTable in the URL query params
 * @param paramPrefix optional prefix for the query params if you need multiple instances of the store
 * @example
 * const { state, onStateChange } = useOptimizationTestsTableSearchParamsStateStore();
 * // => Stores params like `expandedTests=VT-1,VT-2,VT-3`
 * const { state, onStateChange } = useOptimizationTestsTableSearchParamsStateStore('fooBar');
 * // => Stores params like `fooBarExpandedTests=VT-1,VT-2,VT-3`
 */
const useOptimizationTestsSearchParamsStateStore = (paramPrefix = '') => {
  const { queryParams, updateQueryParams } = useQueryParams();
  const { canViewFullVersion } = useDataVisibility();
  const makeParamKey = useCallback((name: string) => camelCase(`${paramPrefix} ${name}`), [paramPrefix]);

  const state = useMemo(
    () => ({
      expandedTests: queryParams.get(makeParamKey(SearchParamKey.EXPANDED_TESTS))?.split(',') ?? [],
    }),
    [makeParamKey, queryParams]
  );

  const onStateChange = useCallback(
    (newState: OptimizationTestsTableState) => {
      updateQueryParams({
        [makeParamKey(SearchParamKey.EXPANDED_TESTS)]: newState.expandedTests.join(','),
      });
    },
    [makeParamKey, updateQueryParams]
  );

  const toggleItem = useCallback(
    (item: string) => {
      if (canViewFullVersion) {
        onStateChange({
          expandedTests: state.expandedTests.includes(item)
            ? state.expandedTests.filter((test) => test !== item)
            : [...state.expandedTests, item],
        });
      }
    },
    [onStateChange, state, canViewFullVersion]
  );

  return useMemo(
    () => ({
      state,
      onStateChange,
      toggleItem,
    }),
    [onStateChange, state, toggleItem]
  );
};

export default useOptimizationTestsSearchParamsStateStore;
