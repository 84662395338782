import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import {
  monthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import {
  AWS_DEFAULT_CURRENCY,
  AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
} from '@vertice/dashboard/src/modules/cloud/constants';
import { type SavingsData } from './useSavingsData';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { ifExpression } from '@vertice/utils/src/ifExpression';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { sortTooltipPointsFunction } from '@vertice/core/src/components/charts/components/Tooltip/sortTooltipPointsUtils';
import extractTooltipContent, {
  TooltipItem,
} from '@vertice/core/src/components/charts/components/Tooltip/extractTooltipContent';
import { currencyFormatter } from '@vertice/core/src/components/charts/components/Tooltip/valueFormatters';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';

type SavingsGraphProps = {
  data: SavingsData;
};

const getDecimalsByValue = (value: number) => {
  // if (Math.abs(value) < 10) return 2;
  return AWS_DEFAULT_CURRENCY_DECIMAL_PLACES;
};

const SavingsGraph = ({ data }: SavingsGraphProps) => {
  const [chart, setChartRef] = useChartRef();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_SAVINGS' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyStackedColumnHover = useStackedColumnHover();

  const coloredValues = data.values as unknown as SeriesOptionsWithData[];

  const series = useMemo(() => {
    const values = coloredValues.map((item) => {
      return {
        ...item,
        ...ifExpression(item.id === 'actualSavings', {
          name: t('ACTUAL_SAVINGS_LABEL'),
          color: palette.visualization.monochromatic.success['30'],
          states: {
            hover: {
              color: palette.visualization.monochromatic.success['40'],
            },
            inactive: {
              color: palette.visualization.monochromatic.success['30'],
            },
          },
        }),
        ...ifExpression(item.id === 'cumulativeSavings', {
          name: t('CUMULATIVE_SAVINGS_LABEL'),
          color: palette.visualization.monochromatic.success['50'],
          states: {
            hover: {
              color: palette.visualization.monochromatic.success['60'],
            },
            inactive: {
              color: palette.visualization.monochromatic.success['50'],
            },
          },
        }),
      };
    });

    return [
      ...(values.filter((item) => item.type === 'line') || []),
      ...getSeriesWithBorderRadius(values, 8),
    ] as SeriesOptionsType[];
  }, [coloredValues, palette, t]);

  const options = useMemo(
    () =>
      buildOptions([
        applyXAxisOffset,
        applyStackedColumnHover,
        applyStyledHighcharts,
        mergeOptions({
          chart: { type: 'column' },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          yAxis: {
            labels: {
              formatter: ({ value }: { value: number | string }) =>
                yLabelCurrencyFormatter(
                  palette,
                  locale,
                  AWS_DEFAULT_CURRENCY,
                  0,
                  getDecimalsByValue(Number(value) ?? 0),
                  true
                )({ value }),
            },
          },
          xAxis: {
            categories: data.months,
            labels: { formatter: monthFormatter },
          },
          tooltip: { shared: true },
          series,
        }),
      ]),
    [applyXAxisOffset, applyStackedColumnHover, applyStyledHighcharts, palette, locale, data.months, series]
  );

  const legendItems = useMemo<LegendItemData[]>(
    () => extractLegendItemsFromSeries(options, data.usedCategories),
    [options, data.usedCategories]
  );

  const tooltipSort = sortTooltipPointsFunction(data.usedCategories, true);
  const tooltipExtractor = extractTooltipContent(tooltipSort, (v) =>
    currencyFormatter(locale, AWS_DEFAULT_CURRENCY, getDecimalsByValue(v ?? 0))(v)
  );

  return (
    <Stack gap={1.5}>
      {legendItems.length > 0 && (
        <Stack direction="row" alignSelf="flex-end" px={6} spacing={4}>
          <Legend items={legendItems} />
        </Stack>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {(context) => {
          const tooltipContent = tooltipExtractor(context);

          if (!tooltipContent.items?.length) return <></>;

          return (
            <TooltipWrapper>
              {tooltipContent.items?.map((item: TooltipItem, index: number) => (
                <TooltipSeriesValuePair
                  seriesColor={item.color}
                  seriesName={item.name}
                  value={item.value}
                  key={item.name || index}
                />
              ))}
              <TooltipTotal value={tooltipContent.total} />
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </Stack>
  );
};

export default SavingsGraph;
