import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { type OptionsStackingValue, type SeriesOptionsType } from 'highcharts';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';

export type GraphSeries = Array<
  {
    isVisible?: boolean;
    states: {
      hover: {
        enabled?: boolean;
        color: string;
      };
    };
    data: Array<number>;
  } & Highcharts.SeriesOptionsType
>;

type GraphProps = {
  series: GraphSeries;
};

const Graph = ({ series }: GraphProps) => {
  const { palette } = useTheme();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyXAxisOffset = useXAxisOffset();

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: {
            type: 'bar',
            height: 54,
            spacing: [0, 0, 0, 0],
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: 'transparent',
            plotBackgroundColor: 'transparent',
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
            },
            series: {
              stacking: 'normal' as OptionsStackingValue,
              dataLabels: {
                enabled: true,
                inside: true,
                format: '{y}%',
              },
              borderWidth: 2,
              borderColor: palette.core.bg,
            },
          },
          xAxis: {
            visible: false,
          },
          yAxis: {
            visible: false,
            tickInterval: 0.1,
          },
          series: series.filter(({ isVisible }) => isVisible).toReversed() as Array<SeriesOptionsType>,
          tooltip: {
            enabled: false,
          },
        }),
      ]),
    [applyStyledHighcharts, applyXAxisOffset, palette, series]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Graph;
