import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatBar, StatBarDivider, StatBarItem } from '@verticeone/design-system/src';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useRecommendationData } from './useRecommendationData';

const RecommendationAnnualSummary = () => {
  const { data } = useRecommendationData();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation(undefined, { keyPrefix: `CLOUD.RIO_NEW.OPTIMIZE.ANNUAL_SUMMARY` });

  return (
    <Stack position="relative">
      <StatBar>
        <StatBarItem
          title={t('ON_DEMAND_COST')}
          value={formatCurrency(data?.annualOnDemandCost ?? 0, {
            currency: AWS_DEFAULT_CURRENCY,
            maximumFractionDigits: 0,
          })}
          tooltip={{
            title: t('ON_DEMAND_COST'),
            content: t('ON_DEMAND_COST_TOOLTIP'),
          }}
          testId="on-demand-cost"
        />
        <StatBarDivider />
        <StatBarItem
          title={t('VERTICE_OPTIMIZED_COST')}
          value={formatCurrency(data?.annualOptimizedCost ?? 0, {
            currency: AWS_DEFAULT_CURRENCY,
            maximumFractionDigits: 0,
          })}
          tooltip={{
            title: t('VERTICE_OPTIMIZED_COST'),
            content: t('VERTICE_OPTIMIZED_COST_TOOLTIP'),
          }}
          testId="vertice-optimized-cost"
        />
        <StatBarDivider />
        <StatBarItem
          title={t('SAVINGS')}
          value={formatCurrency(data?.annualSavings ?? 0, { currency: AWS_DEFAULT_CURRENCY, maximumFractionDigits: 0 })}
          tooltip={{
            title: t('SAVINGS'),
            content: t('SAVINGS_TOOLTIP'),
          }}
          testId="savings"
        />
      </StatBar>
    </Stack>
  );
};

export default RecommendationAnnualSummary;
