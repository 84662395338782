import React from 'react';
import { Text } from '@verticeone/design-system/src';
import { formatCurrency } from '@vertice/utils/src/formatting/currency';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { AWS_DEFAULT_CURRENCY } from '../../../../constants';

type CurrencyCellProps = {
  value: number;
  maximumFractionDigits?: number;
};

const CurrencyCell = ({ value, maximumFractionDigits = 0 }: CurrencyCellProps) => {
  const { locale } = useLocaleContext();

  return (
    <Text variant="body-regular" size="M">
      {formatCurrency(value, {
        currency: AWS_DEFAULT_CURRENCY,
        locale,
        maximumFractionDigits,
      })}
    </Text>
  );
};

export default CurrencyCell;
