import React, { useEffect, useState } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { isNil } from 'lodash';
import { useExchangeCurrency } from '@vertice/core/src/modules/currency/useExchangeCurrency';
import DetailPanelCell from './DetailPanelCell';
import { useExchangeToAccountCurrency } from '@vertice/core/src/modules/currency/useExchangeToAccountCurrency';
import { GridEventListener, useGridApiContext } from '@mui/x-data-grid-pro';

export type PreviousContractDetailRowProps = {
  contract: Contract;
};

const PreviousContractDetailRow = ({ contract }: PreviousContractDetailRowProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'SAAS.SAVINGS.COMPLETED_CONTRACTS_CARD.PREVIOUS_CONTRACT_DETAIL_ROW',
  });
  const { palette } = useTheme();
  const formatCurrency = useFormatCurrency();
  const { exchangeCurrency } = useExchangeCurrency();
  const { accountCurrency } = useExchangeToAccountCurrency();
  const apiRef = useGridApiContext();
  const [firstColumnWidth, setFirstColumnWidth] = useState(apiRef.current.getColumn('vendor').computedWidth);

  useEffect(() => {
    const handleRowResize: GridEventListener<'columnResize'> = (params) => {
      if (params.colDef.field === 'vendor') {
        setFirstColumnWidth(params.width);
      }
    };
    return apiRef.current.subscribeEvent('columnResize', handleRowResize);
  }, [apiRef]);

  const previousContract = contract.materialized?.prev;

  if (!previousContract) {
    return null;
  }

  const negotiatedCost = contract.parts.overview?.annualCostWithVertice ?? 0;
  const contractCurrency = contract?.parts.contractual?.financial?.baseCurrency ?? accountCurrency;

  const prevContractCost = previousContract.overview?.effectiveAnnualCost ?? 0;
  const prevContractCurrency = previousContract.baseCurrency ?? accountCurrency;

  const prevContractCostInCurrentContractCurrency =
    contractCurrency === prevContractCurrency
      ? prevContractCost
      : exchangeCurrency({
          value: prevContractCost,
          sourceCurrency: prevContractCurrency,
          targetCurrency: contractCurrency,
        });

  if (isNil(prevContractCostInCurrentContractCurrency)) {
    return null;
  }

  const savings = Math.max(prevContractCostInCurrentContractCurrency - negotiatedCost, 0);

  return (
    <TableContainer noBorder noBorderRadius>
      <Table size="XL" color="primary">
        <TableBody>
          <TableRow>
            <TableCell
              width={50}
              sx={{
                borderRight: `1px solid ${palette.core.color2} !important`,
                backgroundColor: palette.input.color1,
              }}
            ></TableCell>
            <DetailPanelCell
              testId="previous-contract"
              label={t('LABELS.PREVIOUS_CONTRACT')}
              tooltip={{
                title: t('LABELS.PREVIOUS_CONTRACT'),
                content: t('TOOLTIPS.PREVIOUS_CONTRACT'),
              }}
              width={firstColumnWidth}
              sx={{
                backgroundColor: palette.input.color1,
              }}
            >
              {formatCurrency(prevContractCostInCurrentContractCurrency, {
                currency: contractCurrency,
                maximumFractionDigits: 0,
              })}
            </DetailPanelCell>
            <DetailPanelCell
              testId="savings-vs-previous-contract"
              label={t('LABELS.SAVINGS_VS_PREVIOUS_CONTRACT')}
              tooltip={{
                title: t('LABELS.SAVINGS_VS_PREVIOUS_CONTRACT'),
                content: t('TOOLTIPS.SAVINGS_VS_PREVIOUS_CONTRACT'),
              }}
              sx={{
                backgroundColor: palette.input.color1,
              }}
            >
              {formatCurrency(savings, {
                currency: contractCurrency,
                maximumFractionDigits: 0,
              })}
            </DetailPanelCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PreviousContractDetailRow;
