import { WorkflowNodeFixedDimensions } from '../../model/types';
import assertExhausted from '@vertice/utils/src/assertExhausted';
import { AVERAGE_CHAR_WIDTH } from '../../model/processDefinitionToModel';

/*
 Node dimensions used for the auto-layouting,
 They don't need to match the real dimensions of the node components
 */
export const getNodeDimensions = ({
  kind,
  name,
}: {
  kind: WorkflowNodeFixedDimensions['kind'];
  name?: string;
}): { width: number; height: number } => {
  switch (kind) {
    case 'start':
    case 'end':
      return { width: Math.max((name?.length ?? 0) * AVERAGE_CHAR_WIDTH, 80), height: 24 };
    case 'task':
      return { width: 256, height: 160 };
    case 'gateway':
      return { width: 34, height: 34 };
    default:
      assertExhausted(kind);
      throw new Error('Unknown node kind');
  }
};
