import { Stack } from '@mui/material';
import React, { useId } from 'react';
import { TextFieldCaption } from '@verticeone/design-system/src';
import FormControl from '@mui/material/FormControl';
import { useIsContractFieldVisible } from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import LargeValue from '../LargeValue';
import useContractFormDataSelector from '../../hooks/useContractFormDataSelector';
import { getScopeReductionSavings, getAnnualScopeReductionSavings } from '../../../savings/computed';

const ScopeReductionSavingsContractFormEntry = () => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();
  const id = useId();

  const annualScopeReductionSavings = useContractFormDataSelector(getAnnualScopeReductionSavings);
  const scopeReductionSavings = useContractFormDataSelector(getScopeReductionSavings);
  const isScopeReductionSavingsVisible = useIsContractFieldVisible('parts.overview.scopeReductionSavings');

  if (!isScopeReductionSavingsVisible) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor={id}
          label={t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION')}
          size="XS"
          tooltip={{
            title: t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION'),
            content: t('ENTITIES.CONTRACT.TOOLTIPS.SCOPE_REDUCTION'),
          }}
        />
        <LargeValue
          id={id}
          size="S"
          primaryValue={formatContractCurrency(annualScopeReductionSavings)}
          primaryTestId="annualScopeReductionSavingsValue"
          secondaryValue={t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(scopeReductionSavings) })}
          secondaryTestId="scopeReductionSavingsValue"
        />
      </Stack>
    </FormControl>
  );
};

export default ScopeReductionSavingsContractFormEntry;
