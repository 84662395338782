import { addDays, format } from 'date-fns';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import {
  cssObjectToString,
  dayInMonthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import Highcharts, { type SeriesOptionsType, type TooltipFormatterContextObject } from 'highcharts';
import type { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { ifExpression } from '@vertice/utils/src/ifExpression';
import { DATE_FORMAT } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

import { useTooltipExtractor } from './hooks';
import { ReservationsData } from '../../../types';
import { useInstanceData } from '../providers/InstanceProvider';
import { useOfferings } from '../providers/OfferingsProvider';
import { getColorPattern } from '../BuyInstanceDrawer/BuyInstanceDrawerGraph/utils';

export type TooltipItem = {
  borderColor?: string;
  color: string;
  name: string;
  value: string;
};

type UseRiStatsChartDataProps = {
  options: Highcharts.Options;
  legendItems: LegendItemData[];
  tooltipExtractor: ({ points }: TooltipFormatterContextObject) => {
    items?: TooltipItem[];
    date?: string;
    total: string;
  };
};

const FUTURE_PERIOD = 5;

const useRiStatsChartData = (): UseRiStatsChartDataProps => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.CHART' });
  const tooltipExtractor = useTooltipExtractor();
  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const getColoredItems = useGetColoredItems();
  const { palette, typography } = useTheme();
  const { reservations, chartSeries } = useInstanceData();
  const riReservationsData: ReservationsData[] | undefined = reservations.data;
  const { offerings: recommendedOfferings } = useOfferings({ filterByShoppingCart: false });
  const recommendedOffering = recommendedOfferings.data?.[0];
  const totalNormalizedUnits = recommendedOffering?.normalizedUnits || 0;

  const extendDataToFuture = (data: any[]) => {
    return data.concat(Array.from({ length: FUTURE_PERIOD }, () => null));
  };

  const values = chartSeries.map((item) => ({
    id: item.id,
    type: item.type,
    name: t(`SERIES_NAME.${item.id.toUpperCase()}`),
    data: extendDataToFuture(riReservationsData?.map(item.valueGetter) ?? []),
    zIndex: item.zIndex || 0,
  })) as SeriesOptionsWithData[];

  const pastDays = riReservationsData?.map((item) => format(new Date(item.day), DATE_FORMAT)) ?? [];

  // Extend the timeline to show future periods
  const futureDays = Array.from({ length: FUTURE_PERIOD }, (_, i) => {
    const today = new Date();
    return format(addDays(today, +i), DATE_FORMAT);
  });

  const days = [...pastDays, ...futureDays];

  const lastReservedCapacity = riReservationsData
    ? Number(riReservationsData[riReservationsData.length - 1]?.reservedCapacity)
    : 0;

  // Show Target RI units in Pink with shading
  if (totalNormalizedUnits) {
    values.push({
      id: 'target_ris',
      name: t('SERIES_NAME.TARGET_RIS'),
      data: [
        ...Array(pastDays.length).fill(null),
        ...Array(FUTURE_PERIOD).fill(totalNormalizedUnits + lastReservedCapacity),
      ],
      type: 'spline',
    });
  }

  // Extend current RI volume owned as a blue line
  if (lastReservedCapacity) {
    values.push({
      id: 'current_ri_volume',
      name: t('SERIES_NAME.CURRENT_RIS'),
      data: [...Array(pastDays.length).fill(null), ...Array(FUTURE_PERIOD).fill(lastReservedCapacity)],
      type: 'column',
      zIndex: 2,
    });
  }

  const usedCategories = values?.map(({ id }) => id);
  const legendCategories = usedCategories?.filter((category) => category !== 'on_demand_usage');

  const coloredValues = () => {
    const coloredItems = getColoredItems(values) as unknown as SeriesOptionsWithData[];

    return coloredItems.map((item) => ({
      ...item,
      ...ifExpression(item.id === 'on_demand_usage', {
        opacity: 0,
        states: {
          hover: { opacity: 0 },
          inactive: { opacity: 0 },
        },
      }),
      ...ifExpression(item.id === 'total_usage', {
        color: palette.core.color6,
        lineWidth: 1,
        clip: false,
        states: {
          hover: { color: palette.core.color6 },
          inactive: { color: palette.core.color6 },
        },
      }),
      ...ifExpression(item.id === 'target_ris', {
        lineWidth: 2,
        color: palette.tertiary.color2,
        states: {
          hover: { color: palette.tertiary.color2, lineWidth: 2 },
          inactive: { color: palette.tertiary.color2, lineWidth: 2 },
        },
        zIndex: 1,
      }),
      ...ifExpression(item.id === 'current_ri_volume', {
        color: getColorPattern(palette.info.color2),
        opacity: 1,
        states: {
          hover: { color: getColorPattern(palette.info.color2), opacity: 1 },
          inactive: { opacity: 0.5 },
        },
      }),
    }));
  };

  const options = buildOptions([
    applyStyledHighcharts,
    applyColumnHover,
    applyXAxisOffset,
    (opts) =>
      mergeOptions(
        {
          chart: { type: 'column', spacingTop: 12 },
          plotOptions: {
            column: {
              groupPadding: 0.1,
            },
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          yAxis: {
            labels: {
              formatter: ({ value }: { value: number | string }) => {
                return `<span style="${cssObjectToString(yLabelStyles(palette))}">${value}</span>`;
              },
              x: 50,
            },
            title: {
              offset: -36,
              text: t('Y_AXIS_TITLE'),
              style: {
                ...(typography['button-s'] as Highcharts.CSSObject),
                color: palette.text.color2,
              },
            },
          },
          xAxis: {
            type: 'datetime',
            minPadding: 0,
            maxPadding: 0,
            categories: days,
            labels: { formatter: dayInMonthFormatter },
            // forecast data
            plotBands: futureDays.length
              ? [
                  {
                    color: 'transparent',
                    from: days.indexOf(futureDays[0]),
                    to: days.indexOf(futureDays[futureDays.length - 1]),
                  },
                ]
              : undefined,
            // separator line between past and future data
            plotLines: futureDays.length
              ? [
                  {
                    color: palette.core.color6,
                    value: days.indexOf(futureDays[0]),
                    width: 1,
                    zIndex: 3,
                  },
                ]
              : undefined,
          },
          tooltip: {
            shared: true,
            positioner: function (labelWidth, _, point) {
              const { chartWidth } = this.chart;
              const xOffset = 100;
              const rowWidth = (this.chart.tooltip as any).label?.text.element.offsetWidth || labelWidth;
              const tooltipX = Math.max(0, Math.min(point.plotX, chartWidth - (rowWidth + xOffset)));
              const tooltipY = 0;

              return { x: tooltipX, y: tooltipY };
            },
          },
          series: [...(getSeriesWithBorderRadius(coloredValues(), 0) as SeriesOptionsType[])],
        },
        opts
      ),
  ]);

  return {
    options,
    legendItems: extractLegendItemsFromSeries(options, legendCategories),
    tooltipExtractor: (contextObject: TooltipFormatterContextObject) => tooltipExtractor(contextObject, usedCategories),
  };
};

export default useRiStatsChartData;
