import sleep from '@vertice/utils/src/async/sleep';
import { useLazyListRequestTasksQuery, Task } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useMemo } from 'react';

export const useWaitForRequestActiveTask = (delay = 2000, maxAttempts = 10) => {
  const [getRequestTask] = useLazyListRequestTasksQuery();

  return useMemo(
    () =>
      async (accountId: string, requestId: string): Promise<Task> => {
        let attempts = 0;
        do {
          await sleep(delay);
          const requestTasksResponse = await getRequestTask({ accountId, requestId });
          const firstActiveTask = requestTasksResponse.data?.items.find((task) => task.status === 'ACTIVE');
          if (firstActiveTask) {
            return firstActiveTask;
          }
          attempts++;
        } while (attempts < maxAttempts);
        throw new Error('Request active task not found');
      },
    [delay, getRequestTask, maxAttempts]
  );
};
