import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';

import { Text } from '@verticeone/design-system/src';
import { CardWrapper } from '../components/CardWrapper';
import { JiraSetupCard } from './components/Jira';
import { JIRA_PROVIDER_ID } from './components/Jira/constants';

export const Workflows = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={6}>
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.WORKFLOWS')}
      </Text>
      <Grid container direction="row" spacing={3}>
        <CardWrapper>
          <JiraSetupCard providerId={JIRA_PROVIDER_ID} />
        </CardWrapper>
      </Grid>
    </Stack>
  );
};
