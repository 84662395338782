import { AnnualCostApproximationCostModel, ContractModel2 } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ScopeReductionType } from './types';
import { ConcessionItem } from '../components/ConcessionsList/types';
import { v1 as uuid } from 'uuid';
import nullifyEmptyValues from '@vertice/utils/src/objects/nullifyEmptyValues';
import omitEmptyValues from '@vertice/utils/src/objects/omitEmptyValues';
import { ConcessionStatus, ConcessionType } from '../components/ConcessionsList/constants';
import { CostModelMaterialized } from '../types';

export const COST_MODEL_CLASS = 'AnnualCostApproximation';
export const COST_MODEL_VERSION = '2023-09-14';

/**
 * Generates empty model base.
 */
export const getCostModelBase = () => ({
  id: uuid(),
  class: COST_MODEL_CLASS,
  version: COST_MODEL_VERSION,
  model: {},
});

export const isAnnualCostApproximationModel = (model?: CostModelMaterialized) => {
  return model?.class === COST_MODEL_CLASS;
};

export type AnnualCostApproximationCostModelFormType = {
  id: string;
  class: string;
  version: string;
  model: {
    annualCost?: number;
    concessions?: ConcessionItem[];
    scopeReductionSavings?: ScopeReductionType;
  };
};

export const transformationsForAnnualCostApproximation = {
  apiToForm: (costModel?: ContractModel2) => {
    const model = costModel?.model as AnnualCostApproximationCostModel;
    return {
      id: costModel?.id ?? uuid(),
      class: costModel?.class ?? COST_MODEL_CLASS,
      version: costModel?.version ?? COST_MODEL_VERSION,
      model: {
        annualCost: model?.annual_cost,
        concessions: model?.concessions?.map((concession) => ({
          id: concession.id ?? uuid(),
          type: (concession.type as ConcessionType) ?? null,
          status: (concession.status as ConcessionStatus) ?? null,
          amount: concession.amount ?? null,
          description: concession.description ?? null,
        })),
        scopeReductionSavings: model?.scope_reduction_savings
          ? {
              amount: model?.scope_reduction_savings.amount ?? undefined,
              description: model?.scope_reduction_savings.description ?? undefined,
            }
          : undefined,
      },
    };
  },
  formToApi: (
    formData?: AnnualCostApproximationCostModelFormType,
    mode: 'create' | 'update' = 'create'
  ): ContractModel2 | undefined => {
    if (!formData) {
      return undefined;
    }

    const preprocess = mode === 'update' ? nullifyEmptyValues : omitEmptyValues;

    return {
      id: formData.id ?? uuid(),
      class: formData.class ?? COST_MODEL_CLASS,
      version: formData.version ?? COST_MODEL_VERSION,
      model: formData.model
        ? {
            annual_cost: formData.model.annualCost ?? (mode === 'update' ? null : undefined),
            scope_reduction_savings: formData.model.scopeReductionSavings
              ? preprocess(formData.model.scopeReductionSavings)
              : undefined,
            concessions: formData.model.concessions?.map((concession) => ({
              id: concession.id ?? uuid(),
              type: concession.type ?? undefined,
              status: concession.status ?? undefined,
              amount: concession.amount ?? undefined,
              description: concession.description ?? undefined,
            })),
          }
        : {},
    };
  },
};
