import { conversationsAPI as api } from '../../api/conversationsAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
    getCursor: build.query<GetCursorApiResponse, GetCursorApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/cursor/${queryArg.conversationUrn}` }),
    }),
    postCursor: build.mutation<PostCursorApiResponse, PostCursorApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/cursor/${queryArg.conversationUrn}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getThreadCursor: build.query<GetThreadCursorApiResponse, GetThreadCursorApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/cursor/${queryArg.conversationUrn}/${queryArg.threadUrn}`,
      }),
    }),
    postThreadCursor: build.mutation<PostThreadCursorApiResponse, PostThreadCursorApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/cursor/${queryArg.conversationUrn}/${queryArg.threadUrn}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    listConversations: build.query<ListConversationsApiResponse, ListConversationsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/conversations`,
        params: { maxResults: queryArg.maxResults, nextToken: queryArg.nextToken },
      }),
    }),
    listContributors: build.query<ListContributorsApiResponse, ListContributorsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/conversations/${queryArg.conversationUrn}/contributors`,
      }),
    }),
    subscribeToConversation: build.mutation<SubscribeToConversationApiResponse, SubscribeToConversationApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/conversations/subscribe/${queryArg.conversationUrn}`,
        method: 'POST',
      }),
    }),
    subscribeToConversationThread: build.mutation<
      SubscribeToConversationThreadApiResponse,
      SubscribeToConversationThreadApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/conversations/subscribe/${queryArg.conversationUrn}/${queryArg.threadUrn}`,
        method: 'POST',
      }),
    }),
    listMessages: build.query<ListMessagesApiResponse, ListMessagesApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/messages/${queryArg.conversationUrn}`,
        params: {
          startTimestamp: queryArg.startTimestamp,
          endTimestamp: queryArg.endTimestamp,
          maxResults: queryArg.maxResults,
          nextToken: queryArg.nextToken,
        },
      }),
    }),
    createMessage: build.mutation<CreateMessageApiResponse, CreateMessageApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/messages/${queryArg.conversationUrn}`,
        method: 'POST',
        body: queryArg.createMessage,
      }),
    }),
    listThreadMessages: build.query<ListThreadMessagesApiResponse, ListThreadMessagesApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/messages/${queryArg.conversationUrn}/${queryArg.threadUrn}`,
        params: {
          startTimestamp: queryArg.startTimestamp,
          endTimestamp: queryArg.endTimestamp,
          maxResults: queryArg.maxResults,
          nextToken: queryArg.nextToken,
        },
      }),
    }),
    createThreadMessage: build.mutation<CreateThreadMessageApiResponse, CreateThreadMessageApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/messages/${queryArg.conversationUrn}/${queryArg.threadUrn}`,
        method: 'POST',
        body: queryArg.createMessage,
      }),
    }),
    updateMessage: build.mutation<UpdateMessageApiResponse, UpdateMessageApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/conversations/${queryArg.conversationUrn}/messages/${queryArg.messageId}`,
        method: 'PATCH',
        body: queryArg.updateMessage,
      }),
    }),
    deleteMessage: build.mutation<DeleteMessageApiResponse, DeleteMessageApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/conversations/${queryArg.conversationUrn}/messages/${queryArg.messageId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as conversationsAPICodegen };
export type GetApiVersionApiResponse = unknown;
export type GetApiVersionApiArg = void;
export type GetCursorApiResponse = /** status 200 Cursor response */ {
  timestamp: string;
};
export type GetCursorApiArg = {
  accountId: string;
  conversationUrn: string;
};
export type PostCursorApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type PostCursorApiArg = {
  accountId: string;
  conversationUrn: string;
  /** Cursor request */
  body: {
    timestamp: string;
  };
};
export type GetThreadCursorApiResponse = /** status 200 Cursor response */ {
  timestamp: string;
};
export type GetThreadCursorApiArg = {
  accountId: string;
  conversationUrn: string;
  threadUrn: string;
};
export type PostThreadCursorApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type PostThreadCursorApiArg = {
  accountId: string;
  conversationUrn: string;
  threadUrn: string;
  /** Cursor request */
  body: {
    timestamp: string;
  };
};
export type ListConversationsApiResponse = /** status 200 Conversation list response */ {
  items: Conversation[];
  nextToken?: PaginationToken;
};
export type ListConversationsApiArg = {
  accountId: string;
  maxResults?: number;
  nextToken?: string | null;
};
export type ListContributorsApiResponse = /** status 200 Contributors response */ {
  contributors: Contributor[];
};
export type ListContributorsApiArg = {
  accountId: string;
  conversationUrn: string;
  threadUrn?: string;
};
export type SubscribeToConversationApiResponse = /** status 200 Empty Response */ object;
export type SubscribeToConversationApiArg = {
  accountId: string;
  conversationUrn: string;
};
export type SubscribeToConversationThreadApiResponse = /** status 200 Empty Response */ object;
export type SubscribeToConversationThreadApiArg = {
  accountId: string;
  conversationUrn: string;
  threadUrn: string;
};
export type ListMessagesApiResponse = /** status 200 Message list response */ {
  items: Message[];
  nextToken?: PaginationToken;
};
export type ListMessagesApiArg = {
  accountId: string;
  conversationUrn: string;
  startTimestamp?: string;
  endTimestamp?: string;
  maxResults?: number;
  nextToken?: string | null;
};
export type CreateMessageApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type CreateMessageApiArg = {
  accountId: string;
  conversationUrn: string;
  /** Create message */
  createMessage: CreateMessage;
};
export type ListThreadMessagesApiResponse = /** status 200 Message list response */ {
  items: Message[];
  nextToken?: PaginationToken;
};
export type ListThreadMessagesApiArg = {
  accountId: string;
  conversationUrn: string;
  threadUrn: string;
  startTimestamp?: string;
  endTimestamp?: string;
  maxResults?: number;
  nextToken?: string | null;
};
export type CreateThreadMessageApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type CreateThreadMessageApiArg = {
  accountId: string;
  conversationUrn: string;
  threadUrn: string;
  /** Create message */
  createMessage: CreateMessage;
};
export type UpdateMessageApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type UpdateMessageApiArg = {
  accountId: string;
  conversationUrn: string;
  messageId: string;
  /** Update message */
  updateMessage: UpdateMessage;
};
export type DeleteMessageApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type DeleteMessageApiArg = {
  accountId: string;
  conversationUrn: string;
  messageId: string;
};
export type Conversation = {
  conversationRef: string;
  status: 'ACTIVE';
  messageCounter?: {
    messageCount?: number;
    threadCount?: number;
  };
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  changedBy?: string;
};
export type PaginationToken = string | null;
export type Contributor = {
  userId: string;
  isAccountManager?: boolean;
  firstName?: string;
  middleName?: string;
  lastName?: string;
};
export type Document = {
  id: string;
  ref: string;
  document: {
    [key: string]: any;
  };
};
export type Reference = {
  id: string;
  ref: string;
};
export type Message = {
  messageId?: string;
  content: string;
  contentType: 'text/plain';
  attachments?: {
    documents: Document[];
    references: Reference[];
  };
  conversationRef: string;
  threadRef?: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  edited?: boolean;
  deleted?: boolean;
  changedBy?: string;
  sentByAccountManager?: boolean;
};
export type CreateMessage = {
  content: string;
  contentType: 'text/plain';
  attachments?: {
    documents: Document[];
    references: Reference[];
  };
  moveCursor?: boolean;
};
export type UpdateMessage = {
  content: string;
  contentType: 'text/plain';
};
export const {
  useGetApiVersionQuery,
  useLazyGetApiVersionQuery,
  useGetCursorQuery,
  useLazyGetCursorQuery,
  usePostCursorMutation,
  useGetThreadCursorQuery,
  useLazyGetThreadCursorQuery,
  usePostThreadCursorMutation,
  useListConversationsQuery,
  useLazyListConversationsQuery,
  useListContributorsQuery,
  useLazyListContributorsQuery,
  useSubscribeToConversationMutation,
  useSubscribeToConversationThreadMutation,
  useListMessagesQuery,
  useLazyListMessagesQuery,
  useCreateMessageMutation,
  useListThreadMessagesQuery,
  useLazyListThreadMessagesQuery,
  useCreateThreadMessageMutation,
  useUpdateMessageMutation,
  useDeleteMessageMutation,
} = injectedRtkApi;
