import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../translate';

// WARNING:
// This file is copied from @vertice/core/src/utils/formatting.
// Don't change this file without updating source file as well.
// This file is going to be deleted as soon as we move the formatters into the shared @vertice/utils package.

const getDateParts = (date: Date) => {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const seconds = diffInSeconds;
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  return { years, months, weeks, days, hours, minutes };
};

/** @deprecated use useFormattedTimeAgo from @verticeone/utils package */
const useFormattedTimeAgo = () => {
  const { t } = useTranslation(undefined, { i18n });

  const getFormattedTimeAgo = useCallback(
    (date: Date) => {
      const { years, months, days, hours, minutes } = getDateParts(date);

      if (years > 0) {
        return `${years}${t('TIME.YEAR_AGO')}`;
      } else if (months > 0) {
        return `${months}${t('TIME.MONTH_AGO')}`;
      } else if (days > 0) {
        return `${days}${t('TIME.DAY_AGO')}`;
      } else if (hours > 0) {
        return `${hours}${t('TIME.HOUR_AGO')}`;
      } else if (minutes > 0) {
        return `${minutes}${t('TIME.MINUTE_AGO')}`;
      } else {
        return `${t('TIME.NOW')}`;
      }
    },
    [t]
  );

  const getFullFormattedTimeAgo = useCallback(
    (date: Date) => {
      const { years, months, weeks, days } = getDateParts(date);

      if (years > 0) {
        return t('TIME.YEARS_AGO', { years });
      } else if (months > 0) {
        return t('TIME.MONTHS_AGO', { months });
      } else if (weeks > 0) {
        return t('TIME.WEEKS_AGO', { weeks });
      } else if (days > 0) {
        return t('TIME.DAYS_AGO', { days });
      } else {
        return t('TIME.TODAY');
      }
    },
    [t]
  );

  return { getFormattedTimeAgo, getFullFormattedTimeAgo };
};

export default useFormattedTimeAgo;
