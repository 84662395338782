import React, { useEffect, useState } from 'react';
import { addHours } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

import { IconButton } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { formatCurrency } from '@vertice/utils/src/formatting/currency';
import { Button } from '@verticeone/design-system/src';
import { Tooltip } from '@verticeone/design-system/src';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import {
  RiAction,
  useRiPurchasePermissionQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { LoadableTextComponent } from '@vertice/core/src/components/LoadableTextComponent';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

import MissingPermissionTooltip from './MissingPermissionTooltip';
import EditExecutionDateModal from './ExecutionDateModal';
import useAwsRioSubFeatures from './useAwsRioSubFeatures';
import BuyRIModal from './BuyRIModal';
import { useInstanceList } from '../../../../providers/InstanceListProvider';
import { useInstanceData } from '../../../../providers/InstanceProvider';
import { useOfferings } from '../../../../providers/OfferingsProvider';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type RIOfferingsTableFooterProps = {
  totalCost: number;
  isEmpty: boolean;
};

declare module '@mui/x-data-grid-pro' {
  interface FooterPropsOverrides {
    isEmpty: boolean;
    totalCost: number;
  }
}

const RIOfferingsTableFooter = ({ totalCost, isEmpty }: RIOfferingsTableFooterProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.FOOTER' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const { product } = useInstanceList();
  const { item } = useInstanceData();
  const { offerings } = useOfferings();
  const { accountId } = useAccountContext();
  const awsRioSubFeatures = useAwsRioSubFeatures();

  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [isExecutionDateModalOpen, setIsExecutionDateModalOpen] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState<Date | undefined>();
  const [executeImmediately, setExecuteImmediately] = useState(false);

  const { data: purchasePermissionData, isLoading: isLoadingPurchasePermission } = useRiPurchasePermissionQuery(
    {
      service: product,
      accountId: accountId!,
      region: item.region,
      action: RiAction.Purchase,
    },
    { skip: isEmpty }
  );

  useEffect(() => {
    offerings.fetch();
  }, [offerings]);

  useEffect(() => {
    // @todo we need to use this also for `sp` and not only `ri`
    if (!effectiveDate && !awsRioSubFeatures.isFetching && awsRioSubFeatures.data?.ri[product]?.purchaseDelay) {
      setEffectiveDate(addHours(new Date(), awsRioSubFeatures.data.ri[product].purchaseDelay));
    }
  }, [awsRioSubFeatures, effectiveDate, product]);

  const isLoading = isLoadingPurchasePermission || awsRioSubFeatures.isFetching;

  const permissionQueryData = purchasePermissionData?.riPurchasePermissionQuery;
  const purchaseButtonEnabled =
    permissionQueryData?.__typename === 'RIPurchasePermissionResult' && permissionQueryData?.allowed;

  const handleEditExecutionDate = () => {
    setIsExecutionDateModalOpen(true);
  };

  return (
    <Stack
      sx={{
        borderTop: `1px solid ${palette.core.color2}`,
      }}
      direction="row"
      py={3}
      px={6}
      justifyContent="end"
      alignItems="center"
      gap={4}
    >
      <Stack direction="row" gap={2} alignItems="center">
        <Text variant="body-regular" color="text3">
          {t('EXECUTION_DATE')}
        </Text>
        <Stack direction="row" alignItems="center">
          <Text variant="body-bold">
            <LoadableTextComponent isLoading={awsRioSubFeatures.isFetching}>
              {executeImmediately ? t('VALUES.IMMEDIATELY') : effectiveDate?.toLocaleDateString()}
            </LoadableTextComponent>
          </Text>
          <Tooltip size="S" content={t('CHANGE_PURCHASE_DATE')}>
            <IconButton
              disabled={awsRioSubFeatures.isFetching}
              size="M"
              variant="plain"
              onClick={handleEditExecutionDate}
              icon={EditOutlined}
            />
          </Tooltip>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2}>
        <Text variant="body-regular" color="text3">
          {t('COST')}
        </Text>
        <Text variant="body-bold">
          {formatCurrency(totalCost, {
            currency: AWS_DEFAULT_CURRENCY,
            locale,
            maximumFractionDigits: 2,
          })}
        </Text>
      </Stack>
      <Button
        testId="buy"
        variant="solid"
        color="tertiary"
        disabled={isEmpty || !purchaseButtonEnabled}
        isLoading={isLoading}
        onClick={() => setIsBuyModalOpen(true)}
      >
        {t('BUY')}
      </Button>
      {!isLoadingPurchasePermission && !purchaseButtonEnabled && <MissingPermissionTooltip />}
      {effectiveDate && (
        <>
          <EditExecutionDateModal
            open={isExecutionDateModalOpen}
            setOpen={setIsExecutionDateModalOpen}
            effectiveDate={effectiveDate}
            executeImmediately={executeImmediately}
            onChange={(data) => {
              setEffectiveDate(data.effectiveDate);
              setExecuteImmediately(data.executeImmediately);
            }}
          />
          <BuyRIModal
            open={isBuyModalOpen}
            setOpen={setIsBuyModalOpen}
            effectiveDate={effectiveDate}
            executeImmediately={executeImmediately}
          />
        </>
      )}
    </Stack>
  );
};

export default RIOfferingsTableFooter;
