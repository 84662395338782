import React, { FC } from 'react';

import { PropsWithContract } from '../types';
import { Field } from './Field';
import { useFormatDate } from '@vertice/utils/src/formatting/date';

export const StartDate: FC<PropsWithContract> = ({ contract }) => {
  const formatDate = useFormatDate();
  const startDate = contract.parts.contractual?.lifecycle?.startDate;
  return (
    <Field
      labelTranslationKey="ENTITIES.CONTRACT.LABELS.START_DATE"
      value={startDate ? formatDate(startDate) : undefined}
    />
  );
};
