import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart as HighchartsChart, type SeriesOptionsType } from 'highcharts';
import { Stack, styled, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import {
  dayInMonthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { Button } from '@verticeone/design-system/src';
import ColorSquare from '@vertice/core/src/components/charts/components/ColorSquare';
import { IconWrapper } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { LoadableComponent } from '@verticeone/design-system/src';
import { testProps } from '@verticeone/design-system/src';

import NoDataWrapper from './NoDataWrapper';
import { RecommendationStatus } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import { ToggleSwitch } from '@verticeone/design-system/src';
import LegendControlsBox from '@vertice/core/src/components/charts/components/Legend/LegendControlsBox';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';

type ChartDataType = { x: number; y: number } | number | null;

type SavingsOverTimeGraphProps = {
  code: `VR-${string}` | 'ALL';
  dates: string[];
  values: {
    status: RecommendationStatus;
    data: ChartDataType[];
  }[];
  isLoading: boolean;
};

const ChartWrapper = styled(Stack)<{ $visible: boolean }>(({ theme, $visible }) => ({
  display: $visible ? 'flex' : 'none',
  border: `1px solid ${theme.palette.core.color4}`,
  backgroundColor: theme.palette.core.color1,
  borderRadius: '16px',
  padding: `${theme.spacing(6)} 0`,
  margin: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(6)}`,
}));

const SavingsOverTimeGraph = ({ code, dates, values, isLoading }: SavingsOverTimeGraphProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_RECOMMENDATIONS' });
  const [showChart, setShowChart] = useState(false);
  const isAllCodeUsed = code === 'ALL';
  const [includeCancelledRecommendations, setIncludeCancelledRecommendations] = useState(!isAllCodeUsed);
  const includeCancelledRecommendationsSwitchId = 'include-cancelled-recommendations-switch';
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const formatCurrency = useFormatCurrency();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();

  const statusColor = useMemo(
    () => ({
      [RecommendationStatus.Cancelled]: palette.neutral.color2,
      [RecommendationStatus.Discovered]: palette.success.color2,
      [RecommendationStatus.Fulfilled]: palette.warning.color2,
      [RecommendationStatus.Inprogress]: palette.error.color2,
      [RecommendationStatus.EmptyResults]: palette.inactive.color2,
    }),
    [palette]
  );

  const series = values.map(
    (item, index) =>
      ({
        id: item.status + index,
        name: t(`TOOLTIP.${item.status}`),
        type: 'spline',
        dashStyle: item.status === RecommendationStatus.EmptyResults ? 'Dash' : 'Solid',
        visible: includeCancelledRecommendations || item.status !== RecommendationStatus.Cancelled,
        color: statusColor[item.status],
        states: {
          hover: { color: statusColor[item.status] },
        },
        data: item.data,
        linkedTo: ':previous',
      } as SeriesOptionsType)
  );

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: { type: 'spline', height: 280, backgroundColor: 'transparent' },
          plotOptions: {
            series: {
              stacking: isAllCodeUsed ? 'normal' : undefined,
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
              threshold: 0,
            },
          },
          yAxis: {
            labels: { formatter: yLabelCurrencyFormatter(palette, locale, AWS_DEFAULT_CURRENCY) },
          },
          xAxis: {
            categories: dates,
            labels: { formatter: dayInMonthFormatter },
          },
          tooltip: { shared: true },
          series,
        }),
      ]),
    [applyStyledHighcharts, applyXAxisOffset, isAllCodeUsed, palette, locale, dates, series]
  );

  const usedCategories = useMemo(
    () =>
      series
        .filter(({ visible }) => visible)
        // remove duplicates
        .filter((v, i, a) => a.map((item) => item.name).indexOf(v.name) === i)
        .map((s) => s.id || ''),
    [series]
  );
  const legendItems = useMemo<LegendItemData[]>(
    () => extractLegendItemsFromSeries(options, usedCategories),
    [options, usedCategories]
  );

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  return (
    <>
      <Button
        variant="plain"
        size="S"
        color="neutral"
        sx={{
          display: 'block',
          marginLeft: 3,
        }}
        onClick={() => setShowChart((prev) => !prev)}
      >
        {t('VIEW_ESTIMATED_SAVINGS', { legend: t('ESTIMATED_MONTHLY_SAVINGS') })}
        <IconWrapper icon={showChart ? ExpandLess : ExpandMore} />
      </Button>
      <ChartWrapper $visible={showChart} {...testProps('estimated-savings', 'chart')}>
        <LoadableComponent isLoading={isLoading}>
          <NoDataWrapper hasData={!!series.length}>
            <Stack direction="row" alignSelf="flex-end" px={6} spacing={4}>
              <Legend items={legendItems} />
              {isAllCodeUsed && (
                <LegendControlsBox>
                  <Stack direction="row" spacing="7px" alignItems="center">
                    <ToggleSwitch
                      checked={includeCancelledRecommendations}
                      size="S"
                      color={AWS_BRAND_COLOR}
                      onChange={(e, checked) => {
                        setIncludeCancelledRecommendations(checked);
                      }}
                      id={includeCancelledRecommendationsSwitchId}
                    />
                    <label htmlFor={includeCancelledRecommendationsSwitchId}>
                      <Text variant="body-regular" size="S">
                        {t('WONT_DO_SWITCH')}
                      </Text>
                    </label>
                  </Stack>
                </LegendControlsBox>
              )}
            </Stack>
            <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
            <HighchartTooltip chart={chart}>
              {({ points }) => {
                const items = isAllCodeUsed ? points : points?.slice(-1);
                const total = isAllCodeUsed
                  ? formatCurrency(items?.reduce((acc, point) => acc + (point.y || 0), 0) || 0, {
                      currency: AWS_DEFAULT_CURRENCY,
                      maximumFractionDigits: 2,
                    })
                  : null;

                return (
                  <TooltipWrapper>
                    {items?.map((point, index) => (
                      <Stack key={index} direction="row" gap="7px" alignItems="center">
                        <ColorSquare color={point.series.color as string} />
                        <Stack direction="column">
                          <Text variant="body-regular" size="S" color="text5" flex={1}>
                            {point.series.name}
                          </Text>
                          <Text variant="body-bold" size="S" color="text5" flex={1}>
                            {formatCurrency(point.y!, {
                              currency: AWS_DEFAULT_CURRENCY,
                              maximumFractionDigits: 2,
                            })}
                          </Text>
                        </Stack>
                      </Stack>
                    ))}
                    {total ? <TooltipTotal label={t('TOOLTIP.TOTAL')} value={total} /> : null}
                  </TooltipWrapper>
                );
              }}
            </HighchartTooltip>
          </NoDataWrapper>
        </LoadableComponent>
      </ChartWrapper>
    </>
  );
};

export default SavingsOverTimeGraph;
