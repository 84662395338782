import { Grow, Paper, Popper } from '@mui/material';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Suggestion, UserSuggestion, Segment } from '../types';
import { SuggestionList } from './SuggestionList';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { mapPlainTextIndex } from '../utils';
import { useSuggestionSelection } from '../hooks';

const normalize = (v: string) =>
  v
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();

type SuggestionsOverlayProps = {
  cursorRef: React.RefObject<HTMLSpanElement>;
  inputRef: HTMLTextAreaElement | null;

  suggestions: UserSuggestion[];
  selectionStart: number | null;
  selectionEnd: number | null;

  onSelect: (d: UserSuggestion, s: number, e: number) => void;

  plainText: string;
  segments: Segment[];
  rawLength: number;
  trigger: string;
  allowSpaceInQuery?: boolean;
};
export const SuggestionsOverlay: FC<SuggestionsOverlayProps> = ({
  suggestions: allSuggestions,
  selectionStart,
  selectionEnd,
  cursorRef,
  inputRef,
  onSelect,
  plainText,
  segments,
  rawLength,
  trigger,
  allowSpaceInQuery,
}) => {
  const listRef = useRef<HTMLUListElement>(null);

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const clearSuggestions = useCallback(() => setSuggestions([]), []);
  const isHidden = !suggestions.length || !cursorRef.current;

  const { focusIndex, onKeyDown, onKeyUp, clear } = useSuggestionSelection(
    listRef.current,
    suggestions,
    clearSuggestions,
    onSelect
  );

  useEffect(() => {
    if (!inputRef || isHidden) {
      return;
    }

    inputRef.addEventListener('keydown', onKeyDown);
    inputRef.addEventListener('keyup', onKeyUp);
    return () => {
      inputRef.removeEventListener('keydown', onKeyDown);
      inputRef.removeEventListener('keyup', onKeyUp);
    };
  }, [isHidden, inputRef, onKeyDown, onKeyUp]);

  const updateSuggestions = useCallback(
    (text: string, start: number | null, end: number | null) =>
      setSuggestions(() => {
        if (!start || start !== end) {
          return [];
        }

        const positionInValue = mapPlainTextIndex(segments, start, 'NULL', rawLength);
        if (!positionInValue) {
          return [];
        }

        const substringStartIndex = 0;
        const substring = text.substring(substringStartIndex, start);

        const match = substring.match(makeTriggerRegex(trigger, allowSpaceInQuery));
        if (!match) {
          return [];
        }

        const query = normalize(match[2]);
        const querySequenceStart = substringStartIndex + substring.indexOf(match[1], match.index);
        const querySequenceEnd = querySequenceStart + match[1].length;

        return allSuggestions
          .filter(({ id, label }) => normalize(label || id).indexOf(query) >= 0)
          .map((result) => ({
            result,
            querySequenceStart,
            querySequenceEnd,
          }));
      }),
    [allSuggestions, segments, rawLength, trigger, allowSpaceInQuery]
  );

  useEffect(
    () => updateSuggestions(plainText, selectionStart, selectionEnd),
    [plainText, selectionStart, selectionEnd, updateSuggestions]
  );

  if (isHidden) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={clear}>
      <Popper
        open={true}
        anchorEl={cursorRef.current}
        placement="top-start"
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <SuggestionList
                ref={listRef}
                suggestions={suggestions}
                onBlur={clear}
                onSelect={onSelect}
                focusIndex={focusIndex}
              />
            </Paper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

function makeTriggerRegex(trigger: string, allowSpaceInQuery?: boolean) {
  const escapedTriggerChar = trigger.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

  // first capture group is the part to be replaced on completion
  // second capture group is for extracting the search query
  return new RegExp(`(?:^|\\s)(${escapedTriggerChar}([^${allowSpaceInQuery ? '' : '\\s'}${escapedTriggerChar}]*))$`);
}
