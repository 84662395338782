import React, { useMemo } from 'react';
import { Grid, Stack } from '@mui/material';

import PageHeader from './components/PageHeader';
import { useParams } from 'react-router-dom';
import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { ContractLocator, FetchedContract } from '@vertice/core/src/modules/saas/contract/types';
import { useNextContractFieldsConfig } from '@vertice/dashboard/src/modules/saas/contract/schemas/fieldsConfig';
import CheckUserPermissionToViewContractWrapper from './CheckUserPermissionToViewContractWrapper';
import { useContractRenewalCard } from '../../modules/saas/contract/cards/SidebarCards/ContractRenewalCard';
import { useContractNotYetRenewedCard } from '../../modules/saas/contract/cards/SidebarCards/ContractNotYetRenewedCard';
import { useContractRenewedCard } from '../../modules/saas/contract/cards/SidebarCards/ContractRenewedCard';
import { useContractRenewedOutsideCard } from '../../modules/saas/contract/cards/SidebarCards/ContractRenewedOutsideCard';
import { useLinkedPagesCard } from '../../modules/saas/contract/cards/SidebarCards/LinkedPagesCard';
import { some } from 'lodash';
import useContract from '@vertice/core/src/modules/saas/contract/useContract';
import useFetchContract from '@vertice/core/src/modules/saas/contract/hooks/useFetchContract';
import { ContractSummaryCardSkeleton } from '@vertice/dashboard/src/modules/saas/contract/cards/ContractSummaryCard';
import useMostAppropriateConfigForContract from '@vertice/dashboard/src/modules/saas/contract/useMostAppropriateConfigForContract';
import { ContractContextProvider } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { useDiligenceInsightsCard } from '@vertice/core/src/modules/vendor/VendorDiligenceInsights/VendorDiligenceInsightsCard';
import { ContractTabNavigationContextProvider } from '@vertice/core/src/modules/saas/contract/ContractTabNavigationContext';

const ContractV2Content = ({ fetchedContract }: { fetchedContract: FetchedContract }) => {
  const { component: MostAppropriateContractCard, fieldsConfig } = useMostAppropriateConfigForContract(
    fetchedContract.contract
  );

  const contractContextData = useContract(
    'REQUIRE_FETCHED',
    fetchedContract.accessedVia,
    fetchedContract,
    fieldsConfig
  );

  const nextContractFieldsConfig = useNextContractFieldsConfig();

  // These hooks decide themselves whether they want to be rendered or not. They return null if they don't want to be
  // rendered.
  const renderContractRenewalCard = useContractRenewalCard(contractContextData);
  const renderContractNotYetRenewedCard = useContractNotYetRenewedCard(contractContextData);
  const renderContractRenewedCard = useContractRenewedCard(contractContextData, nextContractFieldsConfig);
  const renderContractRenewedOutsideCard = useContractRenewedOutsideCard(contractContextData);
  const renderDiligenceInsightsCard = useDiligenceInsightsCard(contractContextData);
  const renderLinkedPagesCard = useLinkedPagesCard(contractContextData);

  const isAtLeastOneRightColumnCardVisible = some([
    renderContractRenewalCard,
    renderContractNotYetRenewedCard,
    renderContractRenewedCard,
    renderContractRenewedOutsideCard,
    renderLinkedPagesCard,
  ]);

  return (
    <ContractContextProvider value={contractContextData}>
      <Grid item xs={12} md={isAtLeastOneRightColumnCardVisible ? 8 : 12}>
        <Stack spacing={4}>
          <MostAppropriateContractCard />
          {!isAtLeastOneRightColumnCardVisible && renderDiligenceInsightsCard?.()}
        </Stack>
      </Grid>
      {isAtLeastOneRightColumnCardVisible && (
        <Grid item xs={12} md={4}>
          <Stack spacing={4} maxWidth={480}>
            {renderContractRenewalCard?.()}
            {renderContractNotYetRenewedCard?.()}
            {renderContractRenewedCard?.()}
            {renderContractRenewedOutsideCard?.()}
            {renderLinkedPagesCard?.()}
            {renderDiligenceInsightsCard?.()}
          </Stack>
        </Grid>
      )}
    </ContractContextProvider>
  );
};

const Contract = () => {
  const { accountId } = useAccountContext();
  const { contractId } = useParams();

  const primaryContractLocator: ContractLocator | undefined = useMemo(
    () => (contractId ? ({ contractId, accessVia: { type: 'account', accountId } } as ContractLocator) : undefined),
    [contractId, accountId]
  );

  const fetchedContractQuery = useFetchContract(primaryContractLocator);
  const fetchingContract =
    fetchedContractQuery.isLoading || fetchedContractQuery.data.contract.record.contractId !== contractId;

  if (!primaryContractLocator) {
    return <PageNotFound />;
  }

  return (
    <CheckUserPermissionToViewContractWrapper>
      <ContractTabNavigationContextProvider>
        <Stack gap={8} py={10}>
          <PageHeader contractLocator={primaryContractLocator} />
          <Grid container spacing={4} px={10}>
            {fetchingContract ? (
              <Grid item xs={12} md={12}>
                <ContractSummaryCardSkeleton />
              </Grid>
            ) : (
              <ContractV2Content fetchedContract={fetchedContractQuery.data} />
            )}
          </Grid>
        </Stack>
      </ContractTabNavigationContextProvider>
    </CheckUserPermissionToViewContractWrapper>
  );
};

export default Contract;
