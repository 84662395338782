import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, gridClasses, GridColDef } from '@mui/x-data-grid-pro';
import { DataGrid } from '@verticeone/design-system/src';
import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system/src';
import { Button } from '@verticeone/design-system/src';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@verticeone/design-system/src';
import { Stack, styled, useTheme } from '@mui/material';
import { ChipButton } from '@verticeone/design-system/src';
import { IconWrapper } from '@verticeone/design-system/src';
import { LoadableComponent } from '@verticeone/design-system/src';
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIos';
import { isNil } from 'lodash';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { RecommendationStatus } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { STATUS_CHIP } from '../../constants';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import testCodeComparator from '../../../../../modules/cloud/utils/testCodeComparator';
import useOptimizationTestsSearchParamsStateStore from './useOptimizationTestsTableSearchParamsStateStore';
import { useCloudOptimization } from '../../CloudOptimizationContext';
import useOptimizationTestsData, { OptimizationTestRow } from './useOptimizationTestsData';
import { useDataVisibility } from './utils';
import ObfuscatedColumn from './columns/ObfuscatedColumn';
import CategoryColumn from './columns/CategoryColumn';
import EffortChip from './Components/EffortChip';
import SavingColumn from './columns/SavingColumn';
import ExpandIcon from './Components/ExpandIcon';
import ResultTooltip from './Components/ResultTooltip';

const SpacerCell = styled(TableCell)(({ theme: { palette } }) => ({
  width: 43,
  '&&&': {
    borderRightColor: palette.core.color2,
  },
}));

const CellCaption = (props: { children: ReactNode }) => {
  const { palette } = useTheme();
  return <Text variant="caption" size="S" tag="div" color={palette.text.color3} {...props} />;
};

const LAST_COLUMN_WIDTH = 200;
const RECOMMENDATIONS_COUNT_WIDTH = 180;

type TestDetailPanelProps = {
  row: OptimizationTestRow;
  isAnnualPeriod?: boolean;
};

const TestDetailPanel = ({ row, isAnnualPeriod }: TestDetailPanelProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.COLUMNS' });
  const formatCurrency = useFormatCurrency();

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.input.color1,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
      }}
    >
      <Table size="M" color="neutral">
        <TableBody>
          <TableRow>
            <SpacerCell />
            <TableCell sx={{ padding: '16px 12px', height: 'auto' }} colSpan={2}>
              <CellCaption>{t('DESCRIPTION')}</CellCaption>
              <Text variant="body-regular" size="M" tag="div" whiteSpace="pre-line">
                {row.description}
              </Text>
            </TableCell>
            <TableCell sx={{ textAlign: 'right', width: `${LAST_COLUMN_WIDTH}px` }}>
              <Stack spacing={1} alignItems="end">
                <CellCaption>{t('TEST_RESULT')}</CellCaption>
                <ResultTooltip tooltip={row.result.tooltip}>
                  <ChipButton color={row.result.color} isActive={false} variant="ghost">
                    {row.result.value}
                  </ChipButton>
                </ResultTooltip>
              </Stack>
            </TableCell>
          </TableRow>
          {row.recommendations.map(({ code, name, saving, status, effort }) => (
            <TableRow key={code}>
              <SpacerCell />
              <TableCell sx={{ padding: '16px', height: 'auto' }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack>
                    <CellCaption>{t('RECOMMENDATION')}</CellCaption>
                    <Text variant="body-regular" size="M" tag="div">
                      {name}
                    </Text>
                  </Stack>
                  <Stack>
                    <EffortChip effort={effort} />
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell
                sx={{ padding: '16px', height: 'auto', width: RECOMMENDATIONS_COUNT_WIDTH, textAlign: 'right' }}
              >
                {status && status !== RecommendationStatus.EmptyResults && (
                  <Stack gap={1}>
                    <CellCaption>{t('PLANS_TO_IMPLEMENT')}</CellCaption>
                    <div>
                      <ChipButton isActive={false} size="S" variant="ghost" color={STATUS_CHIP[status].color}>
                        <Trans key={code} i18nKey={STATUS_CHIP[status].label} />
                      </ChipButton>
                    </div>
                  </Stack>
                )}
              </TableCell>
              <TableCell sx={{ padding: '16px', height: 'auto', width: `${LAST_COLUMN_WIDTH}px`, textAlign: 'right' }}>
                {!isNil(saving) && (
                  <Text variant="body-regular" size="M" tag="div" testId="currency-data">
                    {formatCurrency(saving * (isAnnualPeriod ? 12 : 1), {
                      currency: AWS_DEFAULT_CURRENCY,
                      maximumFractionDigits: 0,
                    })}
                  </Text>
                )}
                <Button
                  component={Link}
                  to={generatePath(ROUTES.CLOUD_OPTIMIZATION_RECOMMENDATION_CODE, { code })}
                  variant="plain"
                  color="tertiary"
                  sx={{ padding: '0', '&:hover': { padding: 0 } }}
                >
                  {t('VIEW')}
                  <IconWrapper icon={ChevronRightIcon}></IconWrapper>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const OptimizationTestsTable = () => {
  const { data, isFetching } = useOptimizationTestsData();
  const { state, toggleItem, onStateChange } = useOptimizationTestsSearchParamsStateStore();
  const { isAnnualPeriod } = useCloudOptimization();
  const { spendAuditVisibleTests, canViewFullVersion } = useDataVisibility();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.COLUMNS' });

  const columns: GridColDef<OptimizationTestRow>[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      minWidth: 43,
      renderCell: ExpandIcon,
    },
    {
      field: 'code',
      headerName: '',
      editable: false,
      renderCell: ObfuscatedColumn,
      sortComparator: testCodeComparator,
    },
    {
      field: 'name',
      headerName: t('NAME'),
      renderCell: ObfuscatedColumn,
      flex: 1,
    },
    {
      field: 'category',
      headerName: t('CATEGORY'),
      flex: 1,
      renderCell: CategoryColumn,
    },
    {
      field: 'saving',
      headerName: t('RECOMMENDATIONS_VALUE'),
      align: 'right',
      headerAlign: 'right',
      width: LAST_COLUMN_WIDTH,
      renderCell: SavingColumn,
    },
  ];

  return (
    <LoadableComponent isLoading={isFetching} color={AWS_BRAND_COLOR}>
      <DataGrid<OptimizationTestRow>
        sx={{
          border: 'none',
          borderRadius: 0,
          marginRight: '-1px',
          [`.${gridClasses.row}`]: {
            '&:hover': {
              cursor: 'pointer',
            },
          },
          [`[data-field="${GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field}"]`]: {
            padding: 0,
          },
        }}
        rows={data ?? []}
        columns={columns}
        getRowId={({ code }) => code}
        sortingMode="client"
        hideFooter
        noBorderRadius
        rowHeight={60}
        showCellVerticalBorder
        showColumnVerticalBorder
        autoHeight
        detailPanelExpandedRowIds={state.expandedTests.filter(
          (test) => canViewFullVersion || spendAuditVisibleTests.includes(test)
        )}
        onRowClick={({ row }) => toggleItem(row.code)}
        onDetailPanelExpandedRowIdsChange={(codes) => onStateChange({ expandedTests: codes as string[] })}
        getDetailPanelContent={({ row }) => {
          if (canViewFullVersion || spendAuditVisibleTests.includes(row.code)) {
            return <TestDetailPanel row={row} isAnnualPeriod={isAnnualPeriod} />;
          }
        }}
        getDetailPanelHeight={() => 'auto'} // Height based on the content.
        disableRowSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [
              { field: 'saving', sort: 'desc' },
              { field: canViewFullVersion ? 'name' : 'code', sort: 'asc' },
            ],
          },
        }}
        color={AWS_BRAND_COLOR}
      />
    </LoadableComponent>
  );
};

export default OptimizationTestsTable;
