import type { TooltipFormatterContextObject } from 'highcharts';
import { sortTooltipPointsFunction } from '@vertice/core/src/components/charts/components/Tooltip/sortTooltipPointsUtils';
import extractTooltipContent from '@vertice/core/src/components/charts/components/Tooltip/extractTooltipContent';
import { ifExpression } from '@vertice/utils/src/ifExpression';
import { useFormatDate, useFormatNumber } from '../utils';

export const useTooltipExtractor = () => {
  const formatNumber = useFormatNumber();
  const formatDate = useFormatDate();

  return (contextObject: TooltipFormatterContextObject, usedCategories: string[]) => {
    const tooltipSort = sortTooltipPointsFunction(usedCategories, true);
    const tooltip = extractTooltipContent(tooltipSort, (value) => formatNumber(value ?? 0))(contextObject);

    return {
      ...tooltip,
      ...ifExpression(!!contextObject?.x, {
        date: formatDate(contextObject.x as string),
      }),
    };
  };
};

export const getColorPattern = (color: string) => {
  return {
    pattern: {
      path: {
        d: 'M 5 0 L 0 5 M -1 1 L 1 -1 M 6 4 L 4 6',
        strokeWidth: 1,
      },
      width: 5,
      height: 5,
      color,
    },
  };
};
