import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { getTextVariantStyle } from '@verticeone/design-system/src';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import { useMonitoringLatestQuery } from '../../../../../dataSources/useMonitoringLatestQuery';
import { Graph, GraphBreakdownHeader, GraphSeries } from '../../../components/Graph';

const useOptimizationSeries = () => {
  const { palette } = useTheme();
  const { data, isFetching } = useMonitoringLatestQuery('Compute');
  const { t } = useTranslation(undefined, {
    keyPrefix: `CLOUD.RIO_NEW.OVERVIEW.WIDGETS.GRAPH`,
  });

  return useMemo(
    () => ({
      isFetching,
      resourceCost: data?.fargate_total_cost ?? 0,
      series: [
        {
          id: 'SAVINGS_PLANS_COVERAGE',
          type: 'bar' as const,
          isVisible: !!data?.fargate_sp_usage_cost_percentage,
          data: [data ? Math.round(data.fargate_sp_usage_cost_percentage * 10000) / 100 : 0],
          color: palette.visualization.monochromatic.primary['80'],
          name: t('SAVINGS_PLANS_COVERAGE'),
          dataLabels: [
            {
              style: {
                ...(getTextVariantStyle({ variant: 'body-bold', size: 'S' }) as any),
                color: palette.visualization.monochromatic.primary['30'],
              },
            },
          ],
          states: {
            hover: {
              enabled: true,
              color: palette.visualization.monochromatic.primary['50'],
            },
          },
        },
        {
          id: 'ON_DEMAND_USAGE',
          type: 'bar' as const,
          isVisible: !!data?.fargate_on_demand_cost_percentage,
          data: [data ? Math.round(data.fargate_on_demand_cost_percentage * 10000) / 100 : 0],
          color: getColorPattern(palette.text.color3),
          name: t('ON_DEMAND_USAGE'),
          dataLabels: [
            {
              style: {
                ...(getTextVariantStyle({ variant: 'body-bold', size: 'S' }) as any),
                color: palette.text.color1,
              },
            },
          ],
          states: {
            hover: {
              color: getColorPattern(palette.text.color4),
            },
          },
        },
      ] as GraphSeries,
    }),
    [data, isFetching, palette, t]
  );
};

const GraphContainer = () => {
  const { series, isFetching } = useOptimizationSeries();

  if (isFetching) {
    return null;
  }

  return (
    <>
      <GraphBreakdownHeader serviceName="Fargate" resourceCost={12} />
      <Graph series={series} />
    </>
  );
};

export default GraphContainer;
