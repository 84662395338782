import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { FieldPath, FieldValues, useWatch, useController } from 'react-hook-form';
import { Card } from '@verticeone/design-system/src';
import { MoneyFormEntry } from './MoneyFormEntry';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { Button } from '@verticeone/design-system/src';
import { EditOutlined as EditIcon, Clear as ClearIcon } from '@mui/icons-material';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskFormContext } from '../TaskFormContext';
import { Loader } from '@verticeone/design-system/src';

type ServiceCostPanelProps<FormDataType extends FieldValues> = {
  costsName: FieldPath<FormDataType>;
  defaultCostsName: FieldPath<FormDataType>;
  overrideCostsName: FieldPath<FormDataType>;
  currency: string;
  loadingComputedCosts?: boolean;
};

export const ServiceCostPanel = <T extends FieldValues>({
  costsName,
  defaultCostsName,
  overrideCostsName,
  currency,
  loadingComputedCosts,
}: ServiceCostPanelProps<T>) => {
  const { readOnly } = useTaskFormContext();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  const costs = useWatch({ name: costsName });
  const defaultCosts = useWatch({ name: defaultCostsName });
  const {
    field: { value: overrideCosts, onChange },
  } = useController({ name: overrideCostsName });

  return (
    <>
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <CostCard label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.TOTAL_COST')}>
          {loadingComputedCosts ? (
            <Loader color="neutral" size={24} />
          ) : (
            <Text variant="body-bold" size="L">
              {formatCurrency(defaultCosts || 0, { currency, maximumFractionDigits: 2 })}
            </Text>
          )}
        </CostCard>

        {!readOnly && <OverrideCostButton setOverrideCosts={onChange} overrideCosts={overrideCosts} />}
      </Stack>

      {overrideCosts && (
        <CostCard label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.TOTAL_ANNUAL_COST')}>
          {!readOnly ? (
            <MoneyFormEntry<T> name={costsName} currency={currency} />
          ) : (
            <Text variant="body-bold" size="L">
              {formatCurrency(costs || 0, { currency, maximumFractionDigits: 2 })}
            </Text>
          )}
        </CostCard>
      )}
    </>
  );
};

type CostCardProps = {
  children: React.ReactNode;
  label: string;
};
const CostCard: FC<CostCardProps> = ({ children, label }) => {
  return (
    <Card
      paddingRight={6}
      paddingLeft={6}
      paddingBottom={2}
      paddingTop={2}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      minWidth={380}
      maxWidth={640}
      minHeight={60}
      gap={10}
    >
      <Text variant="body-bold" size="L">
        {label}
      </Text>

      <Stack maxWidth={150}>{children}</Stack>
    </Card>
  );
};

type OverrideCostButtonProps = {
  overrideCosts?: boolean;
  setOverrideCosts: (overrideCosts?: boolean) => void;
};
const OverrideCostButton: FC<OverrideCostButtonProps> = ({ overrideCosts, setOverrideCosts }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="plain"
      onClick={() => setOverrideCosts(!overrideCosts)}
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      size="S"
    >
      {overrideCosts ? (
        <>
          <ClearIcon />
          {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.ACTIONS.CLEAR_OVERRIDE_COSTS')}
        </>
      ) : (
        <>
          <EditIcon />
          {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.ACTIONS.OVERRIDE_COSTS')}
        </>
      )}
    </Button>
  );
};
