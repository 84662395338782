import { ElementType, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DesktopMacTwoTone,
  InsertDriveFileTwoTone,
  MonetizationOnTwoTone,
  StorefrontTwoTone,
} from '@mui/icons-material';
import { TFunction } from 'i18next';
import { Offer } from '../../../../../task/TaskDrawer/TaskDrawerForm/predefinedForms/shared/schemas';
import { useFormatDate } from '@vertice/utils/src/formatting/date';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { OfferSubSectionProps } from '../types';
import { useOfferProductsAnnualCost } from '../../../../../hooks/useOfferProductsAnnualCost';

type OfferSectionPropsMapReturn = {
  [x in OfferSubSectionProps['variant']]: { title: string; icon: ElementType };
};

export const offerSectionPropsMap = (t: TFunction): OfferSectionPropsMapReturn => ({
  vendor: {
    title: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR'),
    icon: StorefrontTwoTone,
  },
  product: {
    title: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT'),
    icon: DesktopMacTwoTone,
  },
  concession: {
    title: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.CONCESSION'),
    icon: MonetizationOnTwoTone,
  },
  attachments: {
    title: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.ATTACHMENT'),
    icon: InsertDriveFileTwoTone,
  },
});

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const useOfferItemMapping = (offer: Offer) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const formatDate = useFormatDate();

  const offerProductsAnnualCosts = useOfferProductsAnnualCost(offer);

  const vendorPropertyMapping = useCallback(() => {
    return {
      name: offer.vendor.name,
      type: 'vendor',
      items: [
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.RECURRENCE'),
          value: t(`ENTITIES.CONTRACT.LABELS.FREQUENCY_${offer.rollingFrequency}`),
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.RECURRENCE_INFO'),
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.START_DATE'),
          value: formatDate(offer.startDate),
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.START_DATE_INFO'),
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.END_DATE'),
          value: formatDate(offer.endDate!),
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.END_DATE_INFO'),
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.PAYMENT_TERMS'),
          value: offer.paymentTerms ? capitalize(offer.paymentTerms).replace('_', ' ') : undefined,
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.PAYMENT_TERMS_INFO'),
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.RESELLER'),
          value: offer.purchasedFromReseller ? offer.resellerName : undefined,
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.VENDOR_ITEMS.RESELLER_INFO'),
        },
      ],
    };
  }, [offer, formatDate, t]);

  const productsPropertyMapping = useCallback(() => {
    return offer.products.map((product, idx) => ({
      name: product.name,
      type: 'product',
      items: [
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.COST_PER_LICENSE'),
          value: product.licenseCost
            ? formatCurrency(product.licenseCost, { currency: offer.baseCurrency })
            : undefined,
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.COST_PER_LICENSE_INFO'),
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.NUMBER_OF_LICENSES'),
          value: product.numberOfLicences?.toString(),
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.NUMBER_OF_LICENSES_INFO'),
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.PRODUCT_COST'),
          value: product.annualCost ? formatCurrency(product.annualCost, { currency: offer.baseCurrency }) : undefined,
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.PRODUCT_COST_INFO'),
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.PRODUCT_ANNUAL_COST'),
          value: offerProductsAnnualCosts?.[idx]
            ? formatCurrency(offerProductsAnnualCosts?.[idx], { currency: offer.baseCurrency })
            : undefined,
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.PRODUCT_ANNUAL_COST_INFO'),
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.CONTRACT_START_DATE'),
          value: formatDate(product.allocationSpan?.start_date),
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.CONTRACT_START_DATE_INFO'),
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.CONTRACT_END_DATE'),
          value: product.allocationSpan?.end_date ? formatDate(product.allocationSpan?.end_date) : undefined,
          info: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.PRODUCT_ITEMS.CONTRACT_END_DATE_INFO'),
        },
      ],
    }));
  }, [formatCurrency, formatDate, offer.baseCurrency, offer.products, offerProductsAnnualCosts, t]);

  const concessionsPropertyMapping = useCallback(() => {
    return offer.concessions.map((concession) => ({
      name: t(`CONCESSIONS_LIST.TYPE.${concession.type}`),
      type: 'concession',
      items: [
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.CONCESSION_ITEMS.DESCRIPTION'),
          value: concession.description,
        },
        {
          name: t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.CONCESSION_ITEMS.VALUE'),
          value: formatCurrency(concession.amount, { currency: offer.baseCurrency }),
        },
      ],
    }));
  }, [offer, formatCurrency, t]);

  const vendor = vendorPropertyMapping();
  const products = productsPropertyMapping();
  const concessions = concessionsPropertyMapping();

  return { vendor, products, concessions };
};
