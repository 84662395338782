import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@verticeone/design-system/src';
import {
  useRequestVendorBenchmarkingInsightMutation,
  useRequestProductBenchmarkingInsightMutation,
} from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useSnackbar } from 'notistack';
import { DesignSystemSize } from '@verticeone/design-system/src';
import PricingDataRequestDialog from './PricingDataRequestDialog';

export type TriggerBenchmarkingRequestButtonProps = {
  vendorId?: string;
  productId?: string;
  buttonSize?: DesignSystemSize;
};

export const RequestBenchmarkingDataButton = ({
  vendorId,
  productId,
  buttonSize = 'S',
}: TriggerBenchmarkingRequestButtonProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const [openedDialog, setOpenedDialog] = useState<boolean>(false);
  const [requestBenchmarkingInsightVendor, { isLoading: isVendorRequestLoading }] =
    useRequestVendorBenchmarkingInsightMutation();
  const [requestBenchmarkingInsightProduct, { isLoading: isProductRequestLoading }] =
    useRequestProductBenchmarkingInsightMutation();
  const openDialog = () => {
    setOpenedDialog(true);
  };
  const closeDialog = () => {
    setOpenedDialog(false);
  };
  const triggerRequest = async (note: string) => {
    let response;
    if (productId) {
      response = await requestBenchmarkingInsightProduct({
        accountId: accountId,
        productId: productId!,
        vendorId: vendorId!,
        body: {
          requestNote: note,
        },
      });
    } else {
      response = await requestBenchmarkingInsightVendor({
        accountId: accountId,
        vendorId: vendorId!,
        body: {
          requestNote: note,
        },
      });
    }
    closeDialog();
    if ('data' in response)
      enqueueSnackbar(t('VENDOR.PRODUCTS.BENCHMARK_REQUEST_SUCCESS'), {
        variant: 'success',
      });
  };

  return (
    <div>
      <Button size={buttonSize} variant={productId ? 'solid' : 'ghost'} color="primary" onClick={openDialog}>
        {t('VENDOR.PRODUCTS.REQUEST_BENCHMARKING_BUTTON')}
      </Button>
      <PricingDataRequestDialog
        open={openedDialog}
        onClose={closeDialog}
        onSuccess={triggerRequest}
        disabled={isVendorRequestLoading || isProductRequestLoading}
      />
    </div>
  );
};
