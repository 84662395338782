import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  useOptimizationCheckQuery,
  useLatestOptimizationTestsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import format from 'date-fns/format';
import { DATE_FORMAT, getTableData, getTestTableData } from '../../utils/graphDataUtils';
import { addMonths, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { chain, sumBy, min } from 'lodash';
import useDeferredQuery from '@vertice/utils/src/api/useDeferredQuery';
import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { RegionData, RegionItem } from '../shared/ReservedInstancesTable/types';

const TARGET = 0.8;

const startDate = addMonths(startOfMonth(new Date()), -3);

export type ReservedInstancesData = {
  groupedByRegion?: RegionData[];
  target?: number;
  values?: [number, number][];
};

type ExtractedCheckData = {
  region: string;
  instanceFamily: string;
  cost: number;
  coverage: number;
  usage: number;
};

const PROPOSED_COST_PREFIX = 'proposed_cost';

const useRDSReservedInstancesData = (): LoadableAdvanced<ReservedInstancesData> => {
  const { accountId } = useSelector(getAccount);

  const {
    data: rawCoverageDailyData,
    error: coverageError,
    isLoading: isCoverageLoading,
  } = useDeferredQuery(
    useOptimizationCheckQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      checkCode: 'RDS_AVG_DAILY_RI_COVERAGE',
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ checkQuery }) => checkQuery
  );

  /**
   * We need the test result to match check result items with the proposed costs (provided by recommendation).
   * The number of items in the check is greater that the number of items in the associated test and recommendation.
   * The items with coverage above the target are filtered out in tests and recommendations.
   */
  const {
    data: rawTestData,
    error: testError,
    isFetching: isTestFetching,
  } = useLatestOptimizationTestsQuery({
    accountId: accountId!,
    testCode: 'VT-55',
  });

  const computedChartData = useMemo(() => {
    if (!rawCoverageDailyData || rawCoverageDailyData.length === 0) return undefined;

    const allMonthsData = chain(rawCoverageDailyData)
      .flatMap(({ values }) => values)
      .flatMap(
        ({ checkResult }) =>
          getTableData(checkResult, {
            time: 'timestamp',
            ri_coverage: 'coverage',
          }) as { time: number; coverage: number }[]
      )
      .uniq()
      .orderBy('timestamp', 'asc')
      .map((item) => Object.values(item) as [number, number])
      .value();

    return {
      target: TARGET,
      values: allMonthsData,
    };
  }, [rawCoverageDailyData]);

  const computedTableData = useMemo(() => {
    if (!rawTestData || rawTestData.monitoringLatestQuery?.__typename !== 'MonitoringResult') return undefined;

    const testItems = rawTestData.monitoringLatestQuery?.items;
    if (!testItems || testItems.length === 0) return undefined;

    const testResult = testItems[0].results;
    if (testResult?.__typename !== 'MulticlassBinnedTestResult') return undefined;

    const getMinProposedCost = (item: ExtractedCheckData) => {
      const proposedCostValues = Object.entries(item)
        .filter(([key]) => key.startsWith(PROPOSED_COST_PREFIX))
        .map(([key, value]) => Number(value));

      return min(proposedCostValues) ?? item.cost;
    };

    const proposedCostCategories = testResult?.labelCategories
      ?.filter((value) => value.startsWith(PROPOSED_COST_PREFIX))
      .reduce((obj, key: string) => {
        obj[key] = key;
        return obj;
      }, {} as { [key: string]: string });

    const data = getTestTableData(testResult, {
      region: 'region',
      instance_family: 'instanceFamily',
      on_demand_cost: 'cost',
      ri_coverage: 'coverage',
      on_demand_normalized_usage_quantity: 'usage',
      ...proposedCostCategories,
    }) as ExtractedCheckData[];

    return {
      groupedByRegion: chain(data)
        .groupBy('region')
        .map((allInRegion, region) => {
          const totalCost = sumBy(allInRegion, ({ cost }) => Number(cost));
          const totalProposedCost = sumBy(allInRegion, getMinProposedCost);
          return {
            id: region,
            region: region,
            cost: totalCost,
            saving: totalCost - totalProposedCost,
            items: chain(allInRegion)
              .groupBy((item) => item.instanceFamily)
              .map((groupedItems, group) => {
                const groupCost = sumBy(groupedItems, ({ cost }) => Number(cost));
                const groupProposedCost = sumBy(groupedItems, getMinProposedCost);

                const getCoverage = () => {
                  const totalUsage = sumBy(groupedItems, ({ usage }) => usage);
                  const groupUsage = sumBy(groupedItems, ({ coverage, usage }) => coverage * usage);

                  if (totalUsage !== 0) {
                    return groupUsage / totalUsage;
                  }
                  return groupUsage === 0 ? 0 : null;
                };

                return {
                  id: `${region}_${group}`,
                  region: region,
                  instanceFamily: groupedItems[0].instanceFamily,
                  cost: groupCost,
                  saving: groupCost - groupProposedCost,
                  coverage: getCoverage(),
                } as RegionItem;
              })
              .orderBy('saving', 'desc')
              .value(),
          };
        })
        .orderBy('saving', 'desc')
        .value(),
    };
  }, [rawTestData]);

  return {
    error: coverageError ?? testError,
    isEmpty: Boolean(
      rawCoverageDailyData && (rawCoverageDailyData.length === 0 || rawCoverageDailyData[0]?.values?.length === 0)
    ),
    isLoading: isCoverageLoading || isTestFetching,
    data: { ...computedChartData, ...computedTableData },
  };
};

export default useRDSReservedInstancesData;
