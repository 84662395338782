import React, { useMemo } from 'react';
import { Box, Divider, styled, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@verticeone/design-system/src';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { Text } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useCloudAnalytics } from '../../CloudAnalyticsContext';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { sumBy } from 'lodash';

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.core.bg,
}));

type ComparisonDrawerTableProps<T extends GridValidRowModel> = {
  data: T[];
  columnsMapping: Record<string, string | number>;
  isLoading?: boolean;
};

const useDateFormatterWithGranularity = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.COMPARISON_DRAWER.TABLE.VALUE' });

  const { locale } = useLocaleContext();
  const { granularity } = useCloudAnalytics();

  return useMemo(() => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: granularity === 'month' ? undefined : 'numeric',
    };
    const dateFormatter = new Intl.DateTimeFormat(locale, options);

    return (value: number) => {
      const date = dateFormatter.format(value);
      return granularity === 'week' ? t('WEEK_OF_DATE', { date }) : date;
    };
  }, [granularity, locale, t]);
};

const ComparisonDrawerTable = <T extends GridValidRowModel>({
  data,
  columnsMapping,
  isLoading,
}: ComparisonDrawerTableProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.COMPARISON_DRAWER.TABLE.COLUMN' });
  const formatDate = useDateFormatterWithGranularity();
  const formatCurrency = useFormatCurrency();
  const { palette } = useTheme();

  // Extract dynamic columns from the data
  const dynamicColumns = Object.keys(data[0] || {}).filter((key) => key !== 'startDate' && key !== 'totalCost');

  // Create column definitions for dynamic columns
  const dynamicColumnDefs: GridColDef<T>[] = dynamicColumns.map((field) => ({
    field,
    headerName: columnsMapping[field] as string,
    renderCell: ({ value }) => (
      <Text variant="body-regular" size="M">
        {formatCurrency(value, {
          currency: AWS_DEFAULT_CURRENCY,
          maximumFractionDigits: 2,
        })}
      </Text>
    ),
    disableColumnMenu: true,
    align: 'right',
    headerAlign: 'right',
    editable: false,
    flex: 1,
  }));

  const columns: GridColDef<T>[] = [
    {
      field: 'startDate',
      headerName: t('DATE'),
      renderCell: ({ value, row: { isFooterRow } }) => (
        <Text variant="label" size="XS">
          {isFooterRow ? t('TOTAL') : formatDate(value)}
        </Text>
      ),
      disableColumnMenu: true,
      editable: false,
      flex: 1,
      cellClassName: 'date-cell',
    },
    ...dynamicColumnDefs,
    {
      field: 'totalCost',
      headerName: t('TOTAL'),
      renderCell: ({ value }) => (
        <Text variant="body-bold" size="M">
          {formatCurrency(value, {
            currency: AWS_DEFAULT_CURRENCY,
            maximumFractionDigits: 2,
          })}
        </Text>
      ),
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      editable: false,
      flex: 1,
    },
  ];

  const footerRow: any = {
    id: 'footer',
    isFooterRow: true,
    ...dynamicColumns.reduce(
      (acc1, field) => ({
        ...acc1,
        [field]: sumBy(data, (item) => item[field]),
      }),
      {}
    ),
    totalCost: sumBy(data, (item) => item.totalCost),
  };

  return (
    <StyledBox height="100%">
      <Divider />
      <DataGrid<T>
        noBorder
        noBorderRadius
        rows={data}
        size="M"
        loading={isLoading}
        loadingRowCount={10}
        loadingStyle="skeleton"
        columns={columns}
        getRowId={(row) => (row.isFooterRow ? row.id : row.startDate?.toString() || '')}
        initialState={{
          sorting: {
            sortModel: [{ field: 'cost', sort: 'desc' }],
          },
        }}
        pinnedRows={
          footerRow && {
            bottom: [footerRow],
          }
        }
        sortingMode="client"
        color={AWS_BRAND_COLOR}
        sx={{
          '& .date-cell': {
            backgroundColor: palette.input.color1,
          },
        }}
      />
    </StyledBox>
  );
};

export default ComparisonDrawerTable;
