import React, { useCallback, useId } from 'react';
import { Card } from '@verticeone/design-system/src';
import { StatsBody, Title, Value } from '@vertice/core/src/components/StatsBar';
import { CardProps, Stack } from '@mui/material';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { useTranslation } from 'react-i18next';
import { useGetContractsStatisticsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { FullHeightStatsTile } from './shared/FullHeightStatsTile';
import { GrayStatsBody } from './shared/GrayStatsBody';
import { useDashboardContext } from '@vertice/dashboard/src/pages/Dashboard/DashboardContextProvider';
import { ContractCategory } from '@vertice/dashboard/src/pages/Dashboard/types';
import { isNil } from 'lodash';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

type SpendCardProps = CardProps;

export const SpendCard = (props: SpendCardProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const formatCurrency = useFormatCurrency();
  const { allowCategoryChange, contractCategory } = useDashboardContext();
  const { isLoading, data } = useGetContractsStatisticsQuery({ accountId });
  const statsForCategory = data?.stats.categories[contractCategory];
  const showSpendExpected = !allowCategoryChange || contractCategory === ContractCategory.SAAS;

  const getSpendUploadedTooltipText = useCallback(() => {
    if (!allowCategoryChange) {
      return t('DASHBOARD.SPEND_UPLOADED.DESCRIPTION');
    }
    switch (contractCategory) {
      case ContractCategory.SAAS:
        return t('DASHBOARD.SPEND_UPLOADED.DESCRIPTION_SAAS');
      case ContractCategory.NON_SAAS:
        return t('DASHBOARD.SPEND_UPLOADED.DESCRIPTION_NON_SAAS');
      case ContractCategory.ALL_SPEND:
        return t('DASHBOARD.SPEND_UPLOADED.DESCRIPTION_ALL');
    }
  }, [t, allowCategoryChange, contractCategory]);

  const firstLabelId = useId();
  const secondLabelId = useId();

  const spendUploadedAmout = statsForCategory?.spendUploaded.amount;
  const spendExpectedAmount = statsForCategory?.spendExpected?.amount;

  return (
    <Card {...props}>
      <FullHeightStatsTile>
        <Stack spacing={4} height="100%" p={2}>
          <StatsBody
            sx={{ flexGrow: 1, alignItems: 'flex-start', padding: 4 }}
            isLoading={isLoading}
            title={
              <Title
                id={firstLabelId}
                tooltip={{ title: t('DASHBOARD.SPEND_UPLOADED.TITLE'), content: getSpendUploadedTooltipText() }}
              >
                {t('DASHBOARD.SPEND_UPLOADED.TITLE')}
              </Title>
            }
            value={
              <Value aria-labelledby={firstLabelId}>
                {isNil(spendUploadedAmout)
                  ? '-'
                  : formatCurrency(spendUploadedAmout, {
                      currency: statsForCategory?.spendUploaded.currency ?? DEFAULT_CURRENCY,
                      maximumFractionDigits: 0,
                    })}
              </Value>
            }
          />
          {showSpendExpected && (
            <GrayStatsBody
              isLoading={isLoading}
              title={
                <Title
                  id={secondLabelId}
                  tooltip={{
                    title: t('DASHBOARD.SPEND_EXPECTED.TITLE'),
                    content: t('DASHBOARD.SPEND_EXPECTED.DESCRIPTION'),
                  }}
                >
                  {t('DASHBOARD.SPEND_EXPECTED.TITLE')}
                </Title>
              }
              value={
                <Value aria-labelledby={secondLabelId}>
                  {isNil(spendExpectedAmount)
                    ? '-'
                    : formatCurrency(spendExpectedAmount, {
                        currency: statsForCategory?.spendExpected?.currency ?? DEFAULT_CURRENCY,
                        maximumFractionDigits: 0,
                      })}
                </Value>
              }
            />
          )}
        </Stack>
      </FullHeightStatsTile>
    </Card>
  );
};
