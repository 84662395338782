import * as z from 'zod';
import { nonEmptyString } from '@vertice/utils/src/validation/sharedSchemas';
import { allRollFrequencies } from '../../../../../types';
import { RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { unifiedContractStageSchema } from '../../../types';

export const viewSettingsFormSchema = z.object({
  id: z.string().optional(),
  label: nonEmptyString,
  filters: z.object({
    contractStage: z.array(unifiedContractStageSchema).nullish(),
    contractRollingFrequency: z
      .array(z.custom<RollFrequency>((val) => allRollFrequencies.includes(val as RollFrequency)))
      .nullish(),
  }),
  columns: z.array(z.string()),
});

export type ViewSettingsFormData = z.infer<typeof viewSettingsFormSchema>;
