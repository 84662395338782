import {
  TableType,
  TestResultDataView,
  useTestResultsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { DATE_FORMAT, getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { EBSStorageData, EBSStorageGeneration } from './types';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import useDeferredQuery from '@vertice/utils/src/api/useDeferredQuery';
import { chain, sumBy } from 'lodash';
import { addMonths, format, startOfMonth } from 'date-fns';

const startDate = addMonths(startOfMonth(new Date()), -1);

export const useEBSStorageData = (): LoadableAdvanced<EBSStorageData> => {
  const { accountId } = useAccountContext();

  const { data, error, isFetching } = useDeferredQuery(
    useTestResultsQuery,
    {
      accountId,
      startDate: format(startDate, DATE_FORMAT),
      testCode: 'VT-31',
    },
    { skip: !accountId, pollingInterval: 5000, document: 'useEBSStorageData' },
    (response) =>
      response.testResultsQuery?.__typename === 'DeferredQueryResult' ? undefined : response.testResultsQuery
  );

  const computedTestResults = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const testResultsDataViews = data.reduce((acc, rec) => {
      if (rec.resultDetails.__typename === 'MulticlassBinnedTestResult' && rec.resultDetails.dataViews) {
        const dataView = rec.resultDetails.dataViews.find(({ name }) => name === 'type_to_cost');
        if (dataView) {
          acc.push(dataView);
        }
      }
      return acc;
    }, [] as TestResultDataView[]);

    const lastTestResultsDataView = testResultsDataViews[testResultsDataViews.length - 1];

    if (!lastTestResultsDataView) {
      return;
    }

    const tableData = getTableData(
      {
        columns: lastTestResultsDataView.columns || [],
        data: lastTestResultsDataView.data || [],
        dataTypes: lastTestResultsDataView.types || [],
      } as TableType,
      {
        usage_type: 'usageType',
        is_legacy: 'isLegacy',
        sum_cost: 'cost',
      }
    ) as {
      usageType: string;
      cost: number;
      isLegacy: boolean;
    }[];

    const tableDataWithGroup = tableData.map((row) => ({ ...row, generation: row.isLegacy ? 'legacy' : 'current' }));

    return chain(tableDataWithGroup)
      .groupBy(({ generation }) => generation)
      .map((allInGeneration, generation) => {
        return {
          generation: generation as EBSStorageGeneration,
          cost: sumBy(allInGeneration, ({ cost }) => cost),
        };
      })
      .value();
  }, [data]);

  return {
    error: error,
    isEmpty: Boolean(!computedTestResults || computedTestResults.length === 0),
    isLoading: isFetching,
    data: computedTestResults,
  };
};
