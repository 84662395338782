import { snakeCase } from 'lodash';

/**
 * @example
 * toConstantCase('fooBar') -> 'FOO_BAR'
 * toConstantCase('foo_bar') -> 'FOO_BAR'
 * toConstantCase('foo bar') -> 'FOO_BAR'
 * @param value
 */
const toConstantCase = (value: string) => snakeCase(value).toLocaleUpperCase();

export default toConstantCase;
