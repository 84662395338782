import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { getFullName } from '@vertice/utils/src/formatting/user';
import {
  useCreateMessageMutation,
  useGetCursorQuery,
  useListContributorsQuery,
  useListMessagesQuery,
  usePostCursorMutation,
  useDeleteMessageMutation,
} from '@vertice/slices/src/openapi/codegen/conversationsAPI';
import { useLoggedUser } from '@verticeone/auth/src';
import { CommentMetadata, RemoveCommentCallback } from '@verticeone/design-system/src';
import { SendCommentCallback } from '@verticeone/design-system/src';
import { useRequestDetails } from './useRequestDetails';

const getConversationRef = (accountId: string, requestId: string) => {
  const ref = `urn:verticeone:vertice:${accountId}:services:request/${requestId}`;
  return encodeURIComponent(ref);
};

export const useConversations = (requestId: string) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const { request } = useRequestDetails(requestId);

  const { data: contributors, refetch: refetchContributors } = useListContributorsQuery({
    accountId,
    conversationUrn: getConversationRef(accountId, requestId),
  });
  const {
    data: cursor,
    isLoading: isCursorLoading,
    isError: isCursorError,
    refetch: refetchCursor,
  } = useGetCursorQuery({ accountId, conversationUrn: getConversationRef(accountId, requestId) });

  const {
    data: messagesRaw,
    isLoading: isMessagesLoading,
    isError: isMessagesError,
    refetch: refetchMessages,
  } = useListMessagesQuery({
    accountId,
    conversationUrn: getConversationRef(accountId, requestId),
  });

  const [messageSendingInProgress, setMessageSendingInProgress] = useState(false);
  const [createMessage] = useCreateMessageMutation();
  const [postCursor] = usePostCursorMutation();
  const [deleteMessage] = useDeleteMessageMutation();

  const updateCursor = useCallback(async () => {
    await postCursor({
      accountId,
      conversationUrn: getConversationRef(accountId, requestId),
      body: { timestamp: new Date().toISOString() },
    }).then(async () => {
      await refetchCursor();
    });
  }, [postCursor, refetchCursor, accountId, requestId]);

  const sendMessage: SendCommentCallback = useCallback(
    async (message, onSuccess) => {
      if (messageSendingInProgress) return;
      if (message.trim() === '') return;

      setMessageSendingInProgress(true);

      const newMessage = await createMessage({
        accountId,
        conversationUrn: getConversationRef(accountId, requestId),
        createMessage: {
          // Trim message to avoid whitespace around characters
          content: message.trim(),
          contentType: 'text/plain',
        },
      });

      if ('data' in newMessage) {
        onSuccess?.();

        await refetchContributors();
        await refetchMessages();
        setMessageSendingInProgress(false);
        await updateCursor();
      }

      setMessageSendingInProgress(false);
    },
    [refetchMessages, refetchContributors, messageSendingInProgress, updateCursor, createMessage, accountId, requestId]
  );

  const removeMessage: RemoveCommentCallback = useCallback(
    async (messageId: string) => {
      await deleteMessage({
        accountId,
        conversationUrn: getConversationRef(accountId, requestId),
        messageId,
      });
    },
    [accountId, requestId, deleteMessage]
  );

  const deletedUserSkeleton = useMemo(
    () => ({
      firstName: t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.DELETED'),
      lastName: t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.USER'),
    }),
    [t]
  );

  const messages: CommentMetadata[] = useMemo(
    () =>
      messagesRaw && contributors
        ? (
            messagesRaw.items
              .map((message, index) => {
                const contributor = contributors.contributors.find(
                  (user) => user.userId === parseUserRef(message.createdBy).userId
                );
                const user = contributor ?? { ...deletedUserSkeleton, userId: parseUserRef(message.createdBy).userId };

                const isRequestOwner = request?.owner ? parseUserRef(request?.owner).userId === user.userId : false;

                return {
                  messageId: message.messageId,
                  commentId: [message.content, index].join(),
                  timestamp: message.createdAt,
                  personId: message.createdBy,
                  personName: getFullName(user),
                  personRole: isRequestOwner
                    ? t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.FORMS.CHANGE_OWNER.LABELS.OWNER')
                    : undefined,
                  content: message.content,
                  isCommentAuthor: user.userId === userId,
                  isVerticeUser: user?.isAccountManager,
                  isDeleted: message.deleted,
                  isDeletedByAuthor:
                    message.deleted && message.changedBy
                      ? parseUserRef(message.changedBy).userId === user.userId
                      : undefined,
                  // Wait for API to include this information, for now nobody is vertice user
                };
              })
              .filter((predicate) => !!predicate) as CommentMetadata[]
          ).sort((a, b) => (new Date(b.timestamp) < new Date(a.timestamp) ? 1 : -1))
        : [],
    [messagesRaw, contributors, userId, request, t, deletedUserSkeleton]
  );

  return {
    cursor: cursor?.timestamp ?? null,
    messages,
    sendMessage,
    removeMessage,
    updateCursor,
    isMessagePending: messageSendingInProgress,
    isLoading: isCursorLoading || isMessagesLoading,
    isError: isCursorError || isMessagesError,
  };
};
