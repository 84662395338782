/**
 * Created manually according to https://github.com/VerticeOne/vertice-contract-workflows/blob/main/config/services/default/default_saas_customer_negotiation.bundle.yaml
 */
import { RecurrenceOption } from '../shared/formFields/RecurrenceFormEntry';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import keepOnlyLocalDatePart from '@vertice/utils/src/dates/keepOnlyLocalDatePart';

type AnnualCostApproximationModel = {
  contract_length: string;
  annual_cost: string;
};

type CostAllocationSpan = {
  name: string;
  start_date: string;
  end_date?: string;
  amount: string;
  rolling_frequency?: 'monthly' | 'annually' | 'quarterly' | 'bi-annually';
};

type LegacyLinearApproximationModel = {
  start_date: string;
  end_date: string;
  cost_allocation_spans: CostAllocationSpan[];
};

type ModelClass = 'LegacyLinearApproximation' | 'AnnualCostApproximation';

export type CostModelInstance = {
  id: string;
  model_class: ModelClass;
  model_class_version: string;
  model: LegacyLinearApproximationModel | AnnualCostApproximationModel;
};

type CostModelConfiguration = {
  baseline?: string;
  negotiated?: string;
};

type CostModelDefinition = {
  configuration: CostModelConfiguration;
  instances: CostModelInstance[];
};

export type CustomerLedNegotiationOutput = {
  products: any[];
  costModel: CostModelDefinition;
  contractCurrency: string;
  recurrence: RecurrenceOption;
  vendorProposedCost?: number;
  negotiatedCost: number;
  startDate: string;
  endDate?: string;
};

/**
 * create cost model
 */

const COST_MODEL_VERSION = '2023-09-14';

export const getCostModel = (
  recurrence: RecurrenceOption,
  startDate: string,
  endDate?: string | null,
  cost?: number | null
): CostModelInstance | undefined => {
  if (!cost) return;

  if (recurrence !== 'fixed-term') {
    // for now set the end date of a recurring contract to 5 years from the start date
    const endDateRecurring = keepOnlyLocalDatePart(dayjs(startDate).add(5, 'year'));
    if (!endDateRecurring) return;
    return {
      id: uuid(),
      model_class: 'LegacyLinearApproximation',
      model_class_version: COST_MODEL_VERSION,
      model: {
        start_date: startDate,
        end_date: endDateRecurring,
        cost_allocation_spans: [
          {
            name: 'rolling',
            start_date: startDate,
            amount: cost.toString(),
            rolling_frequency: recurrence,
          },
        ],
      },
    };
  } else {
    // fixed term
    if (!endDate) return;
    return {
      id: uuid(),
      model_class: 'LegacyLinearApproximation',
      model_class_version: COST_MODEL_VERSION,
      model: {
        start_date: startDate,
        end_date: endDate,
        cost_allocation_spans: [
          {
            name: 'fixed-term',
            start_date: startDate,
            end_date: endDate,
            amount: cost.toString(),
          },
        ],
      },
    };
  }
};
