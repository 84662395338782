import { useFormatDateRange } from '@vertice/utils/src/formatting/date';
import { useCallback } from 'react';

export const useDateRangeFormatter = () => {
  const formatDateRange = useFormatDateRange();

  return useCallback(
    (start: string, end: string) =>
      formatDateRange(start, end, {
        formatterOptions: { month: 'short', day: 'numeric', year: 'numeric' },
      }),
    [formatDateRange]
  );
};
