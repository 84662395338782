import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  ForecastPrecisionType,
  useForecastCostQuery,
  useUsageCostPerRecordTypeQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { addMonths, startOfMonth } from 'date-fns';
import useDeferredQuery from '@vertice/utils/src/api/useDeferredQuery';
import { findFirstCurrency, isoDateToTimestamp, sumPositiveDataPointValues } from '../../utils/graphDataUtils';
import { isNil } from 'lodash';
import useRecommendationsData from '../../hooks/useRecommendationsData';
import { AWS_DEFAULT_CURRENCY } from '../../constants';

const HISTORICAL_MONTHS_COUNT = 6;
const FORECAST_MONTHS_COUNT = 6;

const startDate = addMonths(startOfMonth(new Date()), -HISTORICAL_MONTHS_COUNT);

type SimplePoint = [number, number | undefined];

const usePastData = (accountId?: string) => {
  const { data: dataPoints, error } = useDeferredQuery(
    useUsageCostPerRecordTypeQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, 'yyyy-MM-dd'),
      precision: ForecastPrecisionType.Month,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ costUsageQuery }) => costUsageQuery
  );

  return useMemo(
    () => ({
      data: dataPoints
        ? {
            pastData: dataPoints.map(
              (p) => [isoDateToTimestamp(p.time!), sumPositiveDataPointValues(p)] as SimplePoint
            ),
            currency: findFirstCurrency(dataPoints),
          }
        : undefined,
      error: error,
      isEmpty: Boolean(dataPoints && dataPoints.length === 0),
    }),
    [dataPoints, error]
  );
};

const useForecastData = (accountId?: string) => {
  const { data: dataPoints, error } = useDeferredQuery(
    useForecastCostQuery,
    { accountId: accountId!, precision: ForecastPrecisionType.Month },
    { skip: !accountId, pollingInterval: 5000 },
    ({ forecastQuery }) => forecastQuery
  );

  return useMemo(() => {
    const croppedDataPoints = dataPoints?.slice(0, FORECAST_MONTHS_COUNT);
    const unoptimizedForecastData = croppedDataPoints?.map(
      (p) => [isoDateToTimestamp(p.time!), p.values[0]?.mean] as SimplePoint
    );

    return {
      data: unoptimizedForecastData,
      error,
      isEmpty: Boolean(croppedDataPoints && croppedDataPoints.length === 0),
    };
  }, [dataPoints, error]);
};

export type OptimizedVsUnoptimizedData = {
  pastData: SimplePoint[];
  currency: string;
  unoptimizedForecastData: SimplePoint[];
  optimizedForecastData: SimplePoint[];
};

/**
 * If aPoints ends and bPoints starts with a point with the same timestamp, replace both points with the bPoints[0].
 *
 * @example
 * mergeOverlappingPoint(
 *   [[1,1], [2,3]],
 *   [[2,4], [3,1]]
 * )
 * // returns
 * [
 *   [[1,1], [2,4]], // Notice that the first series now ends with the same point as the first point of the second series.
 *   [[2,4], [3,1]]
 * ]
 */
export const mergeOverlappingPoint = (
  aPoints: SimplePoint[],
  bPoints: SimplePoint[]
): [SimplePoint[], SimplePoint[]] => {
  if (aPoints.length === 0 || bPoints.length === 0) {
    return [aPoints, bPoints];
  }

  const lastOfAPoints = aPoints[aPoints.length - 1];
  const [firstOfBPoints, ...restOfBPoints] = bPoints;

  if (lastOfAPoints[0] !== firstOfBPoints[0]) {
    return [[...aPoints, firstOfBPoints], bPoints];
  }

  const mergedPoint = [firstOfBPoints[0], firstOfBPoints[1] ?? lastOfAPoints[1] ?? undefined] as SimplePoint;

  return [
    [...aPoints.slice(0, aPoints.length - 1), mergedPoint],
    [mergedPoint, ...restOfBPoints],
  ];
};

const subtractValueFromSeries = (points: SimplePoint[], valueToSubtract: number) =>
  points.map(
    ([timestamp, origValue]): SimplePoint => [
      timestamp,
      isNil(origValue) ? undefined : Math.max(origValue - valueToSubtract, 0),
    ]
  );

const useOptimizedVsUnoptimizedData = () => {
  const { accountId } = useSelector(getAccount);
  const { data: origPastData, error: pastDataError, isEmpty: pastDataEmpty } = usePastData(accountId);
  const { data: origForecastData, error: forecastDataError, isEmpty: forecastDataEmpty } = useForecastData(accountId);
  const { stats: recommendationsStats, error: recommendationsError } = useRecommendationsData();

  return useMemo(() => {
    const dataLoaded = origPastData && origForecastData && recommendationsStats;

    let data: OptimizedVsUnoptimizedData | undefined = undefined;
    if (dataLoaded) {
      const [pastData, unoptimizedForecastData] = mergeOverlappingPoint(origPastData.pastData, origForecastData);
      const optimizedForecastData = subtractValueFromSeries(
        unoptimizedForecastData,
        recommendationsStats.totalMonthlyRecValue
      );
      data = {
        currency: origPastData.currency ?? AWS_DEFAULT_CURRENCY,
        pastData,
        unoptimizedForecastData,
        optimizedForecastData,
      };
    }

    return {
      error: pastDataError || forecastDataError || recommendationsError,
      isEmpty: pastDataEmpty && forecastDataEmpty,
      isLoading: !dataLoaded,
      data,
    };
  }, [
    forecastDataEmpty,
    forecastDataError,
    origForecastData,
    origPastData,
    pastDataEmpty,
    pastDataError,
    recommendationsError,
    recommendationsStats,
  ]);
};

export default useOptimizedVsUnoptimizedData;
