import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { TextFieldCaption } from '@verticeone/design-system/src';
import { Placeholder } from '@verticeone/design-system/src';

import { RequestStatusChip } from '../../../components/RequestStatusChip';
import { getFullName } from '@vertice/utils/src/formatting/user';
import { dateTimeFormatterOptions, useFormatDate } from '@vertice/utils/src/formatting/date';
import { isResourceRef, parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useRelevantUsersById } from '@vertice/core/src/hooks/useRelevantUsersById';
import { useRequestContext } from '../../RequestContext';

export const DetailsSection = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { request, workflowVersion } = useRequestContext();

  const { usersById, isLoadingUsers } = useRelevantUsersById();
  const createdByUser =
    request?.createdBy && !isResourceRef(request.createdBy)
      ? usersById[parseUserRef(request.createdBy).userId]
      : undefined;
  const ownedByUser = request?.owner ? usersById[parseUserRef(request.owner).userId] : undefined;

  const createdByRef = request?.createdBy;
  const isCreatedBySystem = !!createdByRef && isResourceRef(createdByRef);

  const workflowName = workflowVersion?.workflowName;
  const workflowVersionName = workflowVersion?.status !== 'PENDING' ? workflowVersion?.versionName : undefined;

  return (
    <Card>
      <Stack spacing={6} padding={6}>
        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.SERVICE')} />
          <Text variant="heading" size="XS">
            {request?.serviceName}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.STATUS')} />
          <Text variant="heading" size="XS">
            {request?.status ? <RequestStatusChip status={request?.status} /> : <Placeholder height={19} width={80} />}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.OWNED_BY')} />
          <Text variant="heading" size="XS">
            {ownedByUser ? (
              <>{isLoadingUsers ? <Placeholder height={19} width={80} /> : getFullName(ownedByUser)}</>
            ) : (
              '-'
            )}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.CREATED_BY')} />
          <Text variant="heading" size="XS">
            {isCreatedBySystem ? (
              t('PAGES.CONTRACT_WORKFLOWS.REQUEST_DETAIL.CREATED_BY_SYSTEM')
            ) : (
              <>{isLoadingUsers ? <Placeholder height={19} width={80} /> : getFullName(createdByUser)}</>
            )}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.CREATED_TIME_DATE')} />
          <Text variant="heading" size="XS">
            {formatDate(request?.createdAt, { formatterOptions: dateTimeFormatterOptions })}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.WORKFLOW_VERSION')} />
          <Text variant="heading" size="XS">
            {(workflowName ? workflowName : '') + (workflowVersionName ? ` + ${workflowVersionName}` : '')}
          </Text>
        </Stack>
      </Stack>
    </Card>
  );
};
