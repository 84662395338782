import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { CommonFormFieldProps } from '@vertice/core/src/modules/forms/types';
import { TextField } from '@verticeone/design-system/src';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';
import { email, emailOrEmpty } from '@vertice/utils/src/validation/sharedSchemas';
import { zodToHookFormValidator } from '@vertice/utils/src/validation/zodToHookFormValidator';

type FormTextFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> &
  React.ComponentProps<typeof TextField>;

/**
 * Email field for forms that use inline validation. If you use Zod, please use `FormTextField` directly together with
 * our `email` Zod schema in `sharedSchemas.ts`
 */
export const EmailField = <FormDataType extends FieldValues = never>({
  id,
  required,
  disabled,
  name,
  ...otherProps
}: FormTextFieldProps<FormDataType>) => {
  const { t } = useTranslation();
  return (
    <FormTextField<FormDataType>
      id={id}
      fullWidth
      name={name}
      required={required}
      validate={zodToHookFormValidator(required ? email(t) : emailOrEmpty(t))}
      hiddenLabel
      disabled={disabled}
      {...otherProps}
    />
  );
};
