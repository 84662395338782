import { useEffect, useMemo, useState } from 'react';
import { DepartmentItem } from './types';
import { useAccountContext } from '../../contexts/AccountContext';
import { mapDepartmentToDepartmentItem, overrideDepartmentItem } from './utils';
import {
  Department,
  ListDepartmentsApiArg,
  ListDepartmentsApiResponse,
  UpdateDepartmentApiResponse,
  UpdateDepartmentOwnershipApiResponse,
  useCreateDepartmentMutation,
  useDeleteDepartmentMutation,
  useLazyListDepartmentsQuery,
  useUpdateDepartmentMutation,
  useUpdateDepartmentOwnershipMutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useFetchPaginated } from '../../hooks/useFetchPaginated';
import { AccountUser, useListAccountUsersQuery } from '@vertice/slices';
import { getFullName } from '@vertice/utils/src/formatting/user';
import { keyBy } from 'lodash';

const useDepartmentListData = () => {
  const { accountId } = useAccountContext();
  const [departmentsWithWorkflow, setDepartmentsWithWorkflow] = useState<DepartmentItem[]>();

  const [listDepartments] = useLazyListDepartmentsQuery();
  const { items: departments } = useFetchPaginated<ListDepartmentsApiResponse, ListDepartmentsApiArg, Department>({
    fetchFn: listDepartments,
    getItemsFn: (data) => data.departments,
    getNextTokenFn: (data) => data.nextToken,
    fetchArgs: {
      accountId: accountId!,
    },
    // We need fresh data everytime the table is loaded
    // assignedContractCount can change if any contract-department changes were done in the meantime
    preferCache: false,
  });

  const { data: userData } = useListAccountUsersQuery({ accountId });

  const userMap = useMemo(() => {
    const allUsers =
      userData?.users?.map((user: AccountUser) => ({
        userId: user.userId,
        name: getFullName(user),
      })) ?? [];
    return keyBy(allUsers, 'userId');
  }, [userData?.users]);

  const [createDepartmentRequest] = useCreateDepartmentMutation();
  const [updateDepartmentRequest] = useUpdateDepartmentMutation();
  const [deleteDepartmentRequest] = useDeleteDepartmentMutation();
  const [updateDepartmentOwnership] = useUpdateDepartmentOwnershipMutation();

  useEffect(() => {
    if (!departments || !userMap) {
      return;
    }

    const mappedDepartments = departments.map((department) => mapDepartmentToDepartmentItem(department, userMap));
    setDepartmentsWithWorkflow(mappedDepartments);
  }, [departments, userMap]);

  const updateDepartmentItemWithResponse = (
    id: string,
    departmentResponse: UpdateDepartmentApiResponse,
    ownershipResponse: UpdateDepartmentOwnershipApiResponse
  ) => {
    const departmentFromResponse = mapDepartmentToDepartmentItem(
      {
        ...departmentResponse.department,
        ...ownershipResponse,
      },
      userMap
    );
    // Update item with result returned by BE
    setDepartmentsWithWorkflow((oldDepartments) => overrideDepartmentItem(departmentFromResponse, oldDepartments, id));
  };

  const updateDepartment = async (updatedDepartment: DepartmentItem) => {
    // Let's be optimistic and update the item in the list
    // We set isBeingProcessed flag to prevent any other update of the item until the given update is finished
    setDepartmentsWithWorkflow((oldDepartments) =>
      overrideDepartmentItem({ ...updatedDepartment, isBeingProcessed: true }, oldDepartments)
    );

    // Update department on BE
    const departmentQuery = updateDepartmentRequest({
      accountId,
      departmentId: updatedDepartment.id,
      updateDepartmentRequest: {
        department: {
          label: updatedDepartment.label,
        },
      },
    }).unwrap();

    // Update owners and watchers on BE
    const ownershipQuery = updateDepartmentOwnership({
      accountId,
      departmentId: updatedDepartment.id,
      updateDepartmentOwnershipRequest: {
        owner: updatedDepartment.owner ? { userId: updatedDepartment.owner.userId } : null,
        watchers: updatedDepartment.watchers?.map((user) => ({ userId: user.userId })) ?? [],
      },
    }).unwrap();

    const [departmentResponse, ownershipResponse] = await Promise.all([departmentQuery, ownershipQuery]);

    // Update changed item with result returned by BE - this also removes isBeingProcessed flag
    updateDepartmentItemWithResponse(updatedDepartment.id, departmentResponse, ownershipResponse);
  };

  const addDepartment = async (newDepartment: DepartmentItem) => {
    if (!departmentsWithWorkflow) {
      return; // Prevent adding new departments if we don't have fetched data
    }

    // Let's be optimistic and add the item into the list
    // We set isBeingProcessed flag to avoid other actions being triggered with temporary department ID
    setDepartmentsWithWorkflow((oldDepartments) => [
      ...(oldDepartments ?? []),
      { ...newDepartment, isBeingProcessed: true },
    ]);

    // Add item on BE
    const departmentResponse = await createDepartmentRequest({
      accountId,
      createDepartmentRequest: {
        department: {
          label: newDepartment.label,
        },
      },
    }).unwrap();

    // Update owners and watchers on BE once new department ID is assigned
    if (departmentResponse.department.id) {
      const ownershipResponse = await updateDepartmentOwnership({
        accountId,
        departmentId: departmentResponse.department.id,
        updateDepartmentOwnershipRequest: {
          owner: newDepartment.owner ? { userId: newDepartment.owner.userId } : null,
          watchers: newDepartment.watchers?.map((user) => ({ userId: user.userId })) ?? [],
        },
      }).unwrap();

      // Update item with result returned by BE (with correct departmentId) - this also removes isBeingProcessed flag
      updateDepartmentItemWithResponse(newDepartment.id, departmentResponse, ownershipResponse);
    }
  };

  const removeDepartment = async (departmentId: string) => {
    // Let's be optimistic and assume the department will be deleted successfully
    setDepartmentsWithWorkflow(
      departmentsWithWorkflow?.reduce((acc: DepartmentItem[], item) => {
        if (item.id !== departmentId) {
          acc.push(item);
        }
        return acc;
      }, [])
    );

    // Delete department on BE
    void deleteDepartmentRequest({ accountId, departmentId });
  };

  return {
    data: departmentsWithWorkflow,
    userMap,
    isLoading: !departmentsWithWorkflow || !userMap,
    updateDepartment,
    addDepartment,
    removeDepartment,
  };
};

export default useDepartmentListData;
