import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { addDays, format } from 'date-fns';

import type { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import Highcharts, { type SeriesOptionsType, type TooltipFormatterContextObject } from 'highcharts';
import type { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { DATE_FORMAT } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import {
  cssObjectToString,
  dayInMonthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';

import { getColorPattern, useTooltipExtractor } from './utils';
import type { ReservationsData } from '../../../../types';
import { useInstanceData } from '../../providers/InstanceProvider';
import { ifExpression } from '@vertice/utils/src/ifExpression';

export type TooltipItem = {
  color: string | Highcharts.GradientColorObject | Highcharts.PatternObject;
  name: string;
  value: string;
};

export type UseRiStatsChartDataProps = {
  options: Highcharts.Options;
  legendItems: LegendItemData[];
  tooltipExtractor: ({ points }: TooltipFormatterContextObject) => {
    items?: TooltipItem[];
    date?: string;
    total: string;
  };
};

const FUTURE_PERIOD = 15;

type UseRecommendedRiPurchaseChartDataProps = {
  riReservationsData?: Array<ReservationsData>;
  totalNormalizedUnits?: number;
  shoppingCartUnits?: number;
};

const useRiPurchaseChartData = ({
  riReservationsData,
  totalNormalizedUnits,
  shoppingCartUnits,
}: UseRecommendedRiPurchaseChartDataProps): UseRiStatsChartDataProps => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.CHART' });
  const getColoredItems = useGetColoredItems();
  const { palette, typography } = useTheme();
  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();
  const tooltipExtractor = useTooltipExtractor();
  const { chartSeries } = useInstanceData();

  const recentRiReservationsData = riReservationsData ?? [];

  const pastDays = recentRiReservationsData?.map((item) => format(new Date(item.day), DATE_FORMAT));

  const pastAndPresentDaysCount = pastDays.length;

  const extendDataToFuture = (data: any[]) => {
    return data.concat(Array.from({ length: FUTURE_PERIOD }, () => null));
  };

  const values = chartSeries.map((item) => {
    const data = recentRiReservationsData.map(item.valueGetter);
    return {
      id: item.id,
      type: item.type,
      name: t(`SERIES_NAME.${item.id.toUpperCase()}`),
      data: data ? extendDataToFuture(data) : [],
      zIndex: item.zIndex || 0,
    };
  }) as SeriesOptionsWithData[];

  // Extend the timeline to show future periods
  const futureDays = Array.from({ length: FUTURE_PERIOD }, (_, i) => {
    const today = new Date();
    return format(addDays(today, +i), DATE_FORMAT);
  });

  const days = useMemo(() => [...pastDays, ...futureDays], [pastDays, futureDays]);

  const lastReservedCapacity = recentRiReservationsData
    ? Number(recentRiReservationsData[recentRiReservationsData.length - 1]?.reservedCapacity)
    : 0;

  // Show Target RI units in Pink with shading
  if (totalNormalizedUnits) {
    values.push({
      id: 'target_ris',
      name: t('SERIES_NAME.TARGET_RIS'),
      data: [
        ...Array(pastAndPresentDaysCount).fill(null),
        ...Array(FUTURE_PERIOD).fill(totalNormalizedUnits + lastReservedCapacity),
      ],
      type: 'spline',
    });
  }

  // Extend current RI volume owned as a blue line
  if (lastReservedCapacity) {
    values.push({
      id: 'current_ri_volume',
      name: t('SERIES_NAME.CURRENT_RIS'),
      data: [...Array(pastAndPresentDaysCount).fill(null), ...Array(FUTURE_PERIOD).fill(lastReservedCapacity)],
      type: 'column',
      zIndex: 2,
    });
  }

  if (shoppingCartUnits) {
    values.push({
      id: 'shopping_cart_units',
      name: t('SERIES_NAME.SHOPPING_CART'),
      data: [...Array(pastAndPresentDaysCount).fill(null), ...Array(FUTURE_PERIOD).fill(shoppingCartUnits)],
      type: 'column',
      zIndex: 2,
      index: 1,
    });
  }

  const usedCategories = values.map((value: SeriesOptionsWithData) => value.id);
  let legendCategories = usedCategories?.filter((category) => category !== 'on_demand_usage');

  // move shopping_cart_units to the start of the array
  let shoppingCartItemIndex = legendCategories.indexOf('shopping_cart_units');
  if (shoppingCartItemIndex !== -1) {
    legendCategories.splice(shoppingCartItemIndex, 1);
    legendCategories.unshift('shopping_cart_units');
  }

  const coloredValues = useMemo(() => {
    const coloredItems = getColoredItems(values) as unknown as SeriesOptionsWithData[];

    return coloredItems.map((item) => ({
      ...item,
      ...ifExpression(item.id === 'on_demand_usage', {
        opacity: 0,
        states: {
          hover: { opacity: 0 },
          inactive: { opacity: 0 },
        },
      }),
      ...ifExpression(item.id === 'total_usage', {
        color: palette.core.color6,
        lineWidth: 1,
        clip: false,
        states: {
          hover: { color: palette.core.color6 },
          inactive: { color: palette.core.color6 },
        },
      }),
      ...ifExpression(item.id === 'target_ris', {
        lineWidth: 2,
        color: palette.tertiary.color2,
        states: {
          hover: { color: palette.tertiary.color2, lineWidth: 2 },
          inactive: { color: palette.tertiary.color2, lineWidth: 2 },
        },
        zIndex: 1,
      }),
      ...ifExpression(item.id === 'shopping_cart_units', {
        color: getColorPattern(palette.warning.color2),
        opacity: 1,
        states: {
          hover: { color: getColorPattern(palette.warning.color2), opacity: 1 },
          inactive: { opacity: 0.5 },
        },
      }),
      ...ifExpression(item.id === 'current_ri_volume', {
        color: getColorPattern(palette.info.color2),
        opacity: 1,
        states: {
          hover: { color: getColorPattern(palette.info.color2), opacity: 1 },
          inactive: { opacity: 0.5 },
        },
      }),
      ...ifExpression(item.id === 'total_usage_extended', {
        lineWidth: 1,
        color: palette.core.color6,
        dashStyle: 'dash',
        states: {
          hover: { color: palette.core.color6 },
          inactive: { color: palette.core.color6 },
        },
      }),
    }));
  }, [getColoredItems, palette, values]);

  const applyStyledHighcharts = useStyledHighcharts();
  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyColumnHover,
        applyXAxisOffset,
        (opts) =>
          mergeOptions(
            {
              chart: { type: 'column', spacingTop: 12 },
              plotOptions: {
                column: {
                  groupPadding: 0.1,
                },
                series: {
                  marker: {
                    enabled: false,
                    states: {
                      hover: { fillColor: palette.core.color6 },
                    },
                  },
                },
              },
              yAxis: {
                labels: {
                  formatter: ({ value }: { value: number | string }) => {
                    return `<span style="${cssObjectToString(yLabelStyles(palette))}">${value}</span>`;
                  },
                  x: 50,
                },
                title: {
                  offset: -36,
                  text: t('Y_AXIS_TITLE'),
                  style: {
                    ...(typography['button-s'] as Highcharts.CSSObject),
                    color: palette.text.color2,
                  },
                },
              },
              xAxis: {
                type: 'datetime',
                minPadding: 0,
                maxPadding: 0,
                categories: days,
                labels: { formatter: dayInMonthFormatter },
                // forecast data
                plotBands: futureDays.length
                  ? [
                      {
                        color: 'transparent',
                        from: days.indexOf(futureDays[0]),
                        to: days.indexOf(futureDays[futureDays.length - 1]),
                      },
                    ]
                  : undefined,
                // separator line between past and future data
                plotLines: futureDays.length
                  ? [
                      {
                        color: palette.core.color6,
                        value: days.indexOf(futureDays[0]),
                        width: 1,
                        zIndex: 3,
                      },
                    ]
                  : undefined,
              },
              tooltip: {
                shared: true,
                positioner: function (labelWidth, _, point) {
                  const { chartWidth } = this.chart;
                  const xOffset = 100;
                  const rowWidth = (this.chart.tooltip as any).label?.text.element.offsetWidth || labelWidth;
                  const tooltipX = Math.max(0, Math.min(point.plotX, chartWidth - (rowWidth + xOffset)));
                  const tooltipY = 0;

                  return { x: tooltipX, y: tooltipY };
                },
              },
              series: [...(getSeriesWithBorderRadius(coloredValues, 0) as SeriesOptionsType[])],
            },
            opts
          ),
      ]),
    [applyStyledHighcharts, applyColumnHover, applyXAxisOffset, palette, t, typography, days, futureDays, coloredValues]
  );

  const legendItems = useMemo<LegendItemData[]>(
    () => extractLegendItemsFromSeries(options, legendCategories),
    [options, legendCategories]
  );

  return {
    options,
    legendItems,
    tooltipExtractor: (contextObject: TooltipFormatterContextObject) => tooltipExtractor(contextObject, usedCategories),
  };
};

export default useRiPurchaseChartData;
