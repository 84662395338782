import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@verticeone/design-system/src';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { useConcessionsContext } from '../ConcessionsContextProvider';
import { isNil } from 'lodash';

const AmountCellReadOnly = ({ value }: GridCellParams<any, number | null>) => {
  const { currency } = useConcessionsContext();
  const formatCurrency = useFormatCurrency();

  return <Text variant="caption">{isNil(value) ? '' : formatCurrency(value, { currency })}</Text>;
};

export default AmountCellReadOnly;
