import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import RouteIcon from '@mui/icons-material/Route';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import dayjs from 'dayjs';

import { SlackIconBW, VerticeVIcon } from '@vertice/assets';
import {
  useGetSlackIntegrationConfigQuery,
  useListAccountUsersQuery,
  useUpdateSlackIntegrationConfigMutation,
} from '@vertice/slices';
import SearchInput from '@vertice/core/src/components/SearchInput';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import { Text } from '@verticeone/design-system/src';
import { Tooltip } from '@verticeone/design-system/src';
import { IconWrapper } from '@verticeone/design-system/src';
import { ToggleSwitch } from '@verticeone/design-system/src';
import { Accordion, AccordionDetails, AccordionSummary } from '@verticeone/design-system/src';
import { Alert, AlertButton } from '@verticeone/design-system/src';
import { AUTOCONNECT_KEY, EMPTY_FILTER, storeAutoconnectStartDate, WizardV2StepProps } from '../../../common';
import { SlackUsersGrid } from './SlackUsersGrid';
import { FilterButton } from './FilterButton';
import { ChannelSetupWrapper } from './ChannelSetupWrapper';

const ALERT_DISPLAY_INTERVAL = 15;

const getAutoconnectStartDate = (): string | null => {
  try {
    return JSON.parse(localStorage.getItem(AUTOCONNECT_KEY) ?? '');
  } catch (error) {
    return null;
  }
};

export const MessageWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.core.color3}`,
  borderRadius: 12,
  backgroundColor: theme.palette.core.bg,
  padding: theme.spacing(4),
}));

export const AppBadge = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.core.color3,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  textTransform: 'uppercase',
  lineHeight: 1,
}));

const ActionsBar = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(8),
  padding: '8px 24px',
  borderBottom: `1px solid ${theme.palette.core.color3}`,
  backgroundColor: theme.palette.core.color1,
}));

export const ConnectionStep: FC<WizardV2StepProps> = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { data: configData, isLoading: isLoadingConfigData } = useGetSlackIntegrationConfigQuery({
    accountId,
  });
  const { data: verticeUsersData, isLoading: isLoadingVerticeUsersData } = useListAccountUsersQuery({ accountId });
  const [toggleAutoConnection, { isLoading: isLoadingToggleAutoConnection }] =
    useUpdateSlackIntegrationConfigMutation();

  const [isAutoConnectionEnabled, setIsAutoConnectionEnabled] = useState(true);
  const [search, setSearch] = useState('');
  const [slackConnectionFilter, setSlackConnectionFilter] = useState({ ...EMPTY_FILTER, all: true });

  const handleOnAutoConnectChange = async () => {
    await toggleAutoConnection({
      accountId,
      body: { automaticallyConnectMatchedUsers: !isAutoConnectionEnabled },
    });

    if (!isAutoConnectionEnabled) {
      storeAutoconnectStartDate();
    }
    setIsAutoConnectionEnabled((prevState) => !prevState);
  };

  useEffect(() => {
    if (configData?.automaticallyConnectMatchedUsers !== undefined)
      setIsAutoConnectionEnabled(configData.automaticallyConnectMatchedUsers);
  }, [configData?.automaticallyConnectMatchedUsers]);

  const workspaceUrl = configData?.workspaceInfo?.url ? new URL(configData?.workspaceInfo?.url).host : '';
  const autoconnectStartDate = getAutoconnectStartDate();

  return (
    <Stack gap={3}>
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle
            icon={SlackIconBW}
            text={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_HEADER_TITLE')}
          />
          <CardHeaderDescription>
            <Text variant="body-regular" size="S" color="text2">
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_HEADER_DESCRIPTION')}
            </Text>
          </CardHeaderDescription>
        </CardHeader>
        <Stack direction="row" gap={6} p={6}>
          <Stack flex={1} gap={8}>
            <Stack gap={2}>
              <Text variant="caption" size="S" color="success1">
                {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.WORKSPACE_TITLE')}
              </Text>
              <Text variant="heading" size="M" color="success1">
                {workspaceUrl}
              </Text>
            </Stack>
          </Stack>
          <Stack flex={1}>
            <Accordion variant="outlined" size="S">
              <AccordionSummary
                title={
                  <Text variant="button-bold" size="S" color="text1">
                    {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_SUMMARY')}
                  </Text>
                }
              />
              <AccordionDetails>
                <MessageWrapper>
                  <IconWrapper icon={VerticeVIcon} />
                  <Stack gap={4}>
                    <Box>
                      <Stack direction="row" gap={1} alignItems="center">
                        <Text variant="body-bold" size="XS" color="text1">
                          {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_VERTICE')}
                        </Text>
                        <AppBadge>
                          <Text variant="body-regular" size="XXS" color="text2">
                            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_APP')}
                          </Text>
                        </AppBadge>
                        <Text variant="body-regular" size="XS" color="text2">
                          {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_TIME')}
                        </Text>
                      </Stack>
                      <Text variant="body-regular" size="XS" color="text2" sx={{ fontWeight: 500 }}>
                        {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_TITLE')}
                      </Text>
                    </Box>
                    <Stack>
                      <Text variant="body-bold" size="XS" color="text2">
                        {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_P1_TITLE')}
                      </Text>
                      <Text variant="body-regular" size="XS" color="text2" sx={{ fontWeight: 500 }}>
                        {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_P1_CONTENT')}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text variant="body-bold" size="XS" color="text2">
                        {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_P2_TITLE')}
                      </Text>
                      <Text variant="body-regular" size="XS" color="text2" sx={{ fontWeight: 500 }}>
                        {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_P2_CONTENT')}
                      </Text>
                    </Stack>
                  </Stack>
                </MessageWrapper>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
      </Card>
      {autoconnectStartDate !== null && dayjs().diff(autoconnectStartDate, 'minute') < ALERT_DISPLAY_INTERVAL && (
        <Alert
          variant="ghost"
          size="S"
          color="warning"
          icon={WarningAmberOutlined}
          title={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.ALERT_TITLE')}
          subtitle={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.ALERT_SUBTITLE')}
        >
          <AlertButton onClick={() => document.location.reload()}>
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.ALERT_BUTTON')}
          </AlertButton>
        </Alert>
      )}
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle
            icon={JoinInnerIcon}
            text={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_CARD_HEADER_TITLE')}
          />
          <CardHeaderDescription>
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.USERS_CARD_HEADER_DESCRIPTION')}
          </CardHeaderDescription>
        </CardHeader>
        <ActionsBar>
          <Stack direction="row" gap={2} alignItems="center">
            <FilterButton
              label={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.FILTER_ALL')}
              onClick={() => {
                setSlackConnectionFilter({ ...EMPTY_FILTER, all: true });
              }}
              active={slackConnectionFilter.all}
            />
            <FilterButton
              label={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.FILTER_NOT_LINKED')}
              onClick={() => {
                setSlackConnectionFilter({ ...EMPTY_FILTER, notLinked: true });
              }}
              active={slackConnectionFilter.notLinked}
            />
          </Stack>
          <Stack direction="row" gap={4} alignItems="center">
            <Stack direction="row" gap={2}>
              <ToggleSwitch
                color="neutral"
                size="S"
                onChange={handleOnAutoConnectChange}
                checked={isAutoConnectionEnabled}
                disabled={isLoadingConfigData || isLoadingToggleAutoConnection}
              />
              <Text variant="body-regular" size="S" color="text1">
                {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.TOGGLE_CHECKBOX')}
              </Text>
              <Tooltip content={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.TOGGLE_INFO')} size="M">
                <IconWrapper icon={InfoIcon} size="M" />
              </Tooltip>
            </Stack>
            <SearchInput
              placeholder={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.SEARCH_USERS')}
              onSearch={setSearch}
              value={search}
              debounce={100}
              disabled={verticeUsersData?.users?.length === 0}
              size="S"
              sx={{ minWidth: '256px' }}
            />
          </Stack>
        </ActionsBar>
        <SlackUsersGrid
          verticeUsersData={verticeUsersData?.users}
          isLoadingVerticeUsersData={isLoadingVerticeUsersData}
          search={search}
          slackConnectionFilter={slackConnectionFilter}
        />
      </Card>
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle
            icon={RouteIcon}
            text={
              <>
                <Text variant="heading" size="S" color="text1">
                  {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS_CARD_HEADER_TITLE')}
                </Text>
                <Text variant="heading" size="S" color="text1" sx={{ opacity: 0.6 }}>
                  {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS_CARD_HEADER_OPTIONAL')}
                </Text>
              </>
            }
          />
          <CardHeaderDescription>
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS_CARD_HEADER_DESCRIPTION')}
          </CardHeaderDescription>
        </CardHeader>
        <ChannelSetupWrapper notificationSettings={configData?.notificationSettings} />
      </Card>
    </Stack>
  );
};
