import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Link, Stack } from '@mui/material';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import { GoodFormProps, useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { email, nonEmptyString, url } from '@vertice/utils/src/validation/sharedSchemas';
import { Button, Text } from '@verticeone/design-system/src';

const connectionFormDataSchema = z.object({
  workspaceUrl: url(t),
  administratorEmail: email(t),
  apiToken: nonEmptyString,
});

export type ConnectionFormData = z.infer<typeof connectionFormDataSchema>;
type ConnectionFormProps = GoodFormProps<ConnectionFormData>;

const ConnectionFormEntry = createTypedFormEntry<ConnectionFormData>();

export const SetupConnectionForm = ({ defaultValues, onSubmit }: ConnectionFormProps) => {
  const formMethods = useForm<ConnectionFormData>({
    defaultValues,
    mode: 'all', // Validate on change, blur, submit
    resolver: zodResolver(connectionFormDataSchema),
  });

  const goodFormMethods = useGoodFormUtils(formMethods);
  const submitButtonProps = getPropsForSubmitButton(formMethods.formState);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={goodFormMethods.handleSubmit(onSubmit)}>
        <Stack gap={10} maxWidth="520px">
          <ConnectionFormEntry
            name="workspaceUrl"
            component={FormTextField}
            label={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.FIELD_1_LABEL')}
            componentProps={{
              placeholder: t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.FIELD_1_PLACEHOLDER'),
              helperText: t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.FIELD_1_HELPER_TEXT'),
            }}
          />
          <ConnectionFormEntry
            name="administratorEmail"
            component={FormTextField}
            label={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.FIELD_2_LABEL')}
            componentProps={{
              placeholder: t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.FIELD_2_PLACEHOLDER'),
              helperText: t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.FIELD_2_HELPER_TEXT'),
            }}
          />
          <ConnectionFormEntry
            name="apiToken"
            component={FormTextField}
            label={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.FIELD_3_LABEL')}
            componentProps={{
              placeholder: t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.FIELD_3_PLACEHOLDER'),
              helperText: (
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.FIELD_3_HELPER_TEXT">
                  Check out our guide to
                  <Link href="https://www.dummyurl.com">
                    <Text variant="button-regular" size="XS" color="text3">
                      connecting to Jira using API key
                    </Text>
                  </Link>
                </Trans>
              ),
            }}
          />
          <Button
            variant="outline"
            color="primary"
            type="submit"
            size="M"
            isLoading={submitButtonProps.isLoading}
            disabled={submitButtonProps.disabled}
            sx={{ alignSelf: 'flex-start' }}
          >
            {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.FORM.SUBMIT_BUTTON')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
