import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { ChipButton } from '@verticeone/design-system/src';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useFormatDate } from '@vertice/utils/src/formatting/date';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { Link as RouterLink } from 'react-router-dom';

export type VendorCellProps = {
  viewOnContract: ViewOnContract;
};

export const vendorCellValueGetter = (viewOnContract: ViewOnContract) =>
  viewOnContract.contract.parts.contractual?.vendor?.vendorName;

export const VendorCell: FC<VendorCellProps> = ({ viewOnContract }: VendorCellProps) => {
  const formatDate = useFormatDate();

  const vendor = viewOnContract.contract.parts.contractual?.vendor;
  const vendorId = vendor?.vendorId || undefined;
  const vendorName = vendor?.vendorName || undefined;
  const products = viewOnContract.contract.parts.contractual?.products;
  const productNames = products
    ?.filter((product) => !!product.productName)
    .map((product) => product.productName as string);

  const showProducts = productNames && productNames.length > 0;

  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  return (
    <Stack
      component={RouterLink}
      to={generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, {
        contractId: viewOnContract.contract.record.contractId,
      })}
      sx={{
        textDecoration: 'none',
        color: 'inherit',
        // Make the click target larger
        width: '100%',
        minWidth: 0,
      }}
      direction="row"
      alignItems="center"
      gap={2}
      justifyContent="space-between"
    >
      <Stack gap={2} direction="row" alignItems="center" minWidth={0}>
        <VendorLogo vendorId={vendorId} />
        <Stack position="relative" minWidth={0}>
          <Text variant="body-regular" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {vendorName}
          </Text>
          {showProducts && (
            <Text
              variant="caption"
              size="S"
              color="text3"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                // Extra lineHeight to avoid cutoff text caused by `overflow: hidden` (visible at letters like 'g' or 'y')
                lineHeight: 18 / 14,
              }}
            >
              {productNames.join(', ')}
            </Text>
          )}
        </Stack>
      </Stack>
      {viewOnContract.contract.parts.contractual?.signatory?.signingDate && (
        <ChipButton variant="outline" size="S" color="neutral" isActive={false} sx={{ flexShrink: 0 }}>
          {formatDate(viewOnContract.contract.parts.contractual?.signatory?.signingDate, {
            formatterOptions: { month: 'short', year: 'numeric' },
          })}
        </ChipButton>
      )}
    </Stack>
  );
};

export default VendorCell;
