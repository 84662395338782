import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { FamilyInstanceItem, RegionItem } from '../types';
import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices';
import { useEffect, useState } from 'react';
import { useCloudInventory } from '../useCloudInventory';
import {
  ResourceCode,
  ResourceItem,
  useReservedInstancesByCodeQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import useRdsPriceList, { enhanceDataWithOnDemandPrice } from './useRdsPriceList';
import { getPriceListFilter, processQueryData, ReservedInstance } from '../useRIUtilizationData';
import { chain, isNil, sumBy } from 'lodash';

export const groupByRegionAndInstanceFamilyAndProduct = (flatData: ReservedInstance[]) => {
  return chain(flatData)
    .groupBy('region')
    .map((allInRegion: ReservedInstance[], region) => ({
      id: region,
      region: region,
      items: chain(allInRegion)
        .groupBy((item) => `${item.instanceFamily}_${item.product}`)
        .map((groupedItems: ReservedInstance[], instanceFamilyProduct) => {
          const totalNormalizedUnits = sumBy(groupedItems, ({ normalizedUnits }) => normalizedUnits);
          const hasAllHourlyRates = groupedItems.every(({ hourlyRate }) => !isNil(hourlyRate));
          const hasAllOnDemandHourlyRates = groupedItems.every(({ hourlyOnDemandRate }) => !isNil(hourlyOnDemandRate));

          return {
            id: `${region}_${instanceFamilyProduct}`,
            region: region,
            instanceFamily: groupedItems[0].instanceFamily,
            product: groupedItems[0].product,
            quantity: sumBy(groupedItems, ({ quantity }) => quantity),
            normalizedUnits: totalNormalizedUnits,
            averageHourlyRate: hasAllHourlyRates
              ? sumBy(groupedItems, ({ hourlyRate, normalizedUnits }) => hourlyRate! * normalizedUnits) /
                totalNormalizedUnits
              : undefined,
            averageHourlyOnDemandRate: hasAllOnDemandHourlyRates
              ? sumBy(
                  groupedItems,
                  ({ hourlyOnDemandRate, normalizedUnits }) => hourlyOnDemandRate! * normalizedUnits
                ) / totalNormalizedUnits
              : undefined,
          } as FamilyInstanceItem;
        })
        .orderBy('normalizedUnits', 'desc')
        .value(),
    }))
    .orderBy('region', 'asc')
    .value();
};

const useRdsRIUtilizationData = (): LoadableAdvanced<RegionItem[] | undefined> => {
  const { accountId } = useSelector(getAccount);
  const [computedReservedInstancesData, setComputedReservedInstancesData] = useState<RegionItem[] | undefined>([]);
  const [preprocessedData, setPreprocessedData] = useState<ReservedInstance[] | undefined>();
  const { showExpired, activeTab } = useCloudInventory();

  const {
    data,
    error,
    isFetching: isRIFetching,
  } = useReservedInstancesByCodeQuery({
    accountId: accountId!,
    code: ResourceCode.RiRds,
  });

  const {
    data: priceListData,
    error: priceListError,
    isLoading: isRdsPriceListLoading,
  } = useRdsPriceList({
    filter: getPriceListFilter(preprocessedData),
  });

  useEffect(() => {
    setComputedReservedInstancesData([]);
  }, [showExpired]);

  useEffect(() => {
    if (!data || data?.resourceQuery?.__typename !== 'ResourceQueryResult') return;

    setPreprocessedData(
      processQueryData(data.resourceQuery?.items as ResourceItem[], showExpired, activeTab.instanceTypePosition)
    );
  }, [activeTab.instanceTypePosition, data, showExpired]);

  useEffect(() => {
    if (!preprocessedData?.length || !priceListData) return;

    const enhancedData = enhanceDataWithOnDemandPrice(preprocessedData, priceListData);

    const groupedData = groupByRegionAndInstanceFamilyAndProduct(enhancedData);
    setComputedReservedInstancesData(groupedData);
  }, [preprocessedData, priceListData]);

  return {
    error: error || priceListError,
    isEmpty: Boolean(computedReservedInstancesData && computedReservedInstancesData.length === 0),
    isLoading: isRIFetching || isRdsPriceListLoading,
    data: computedReservedInstancesData,
  };
};

export default useRdsRIUtilizationData;
