/**
 * @module
 * All computations of savings and convenience-getters related to savings
 */

import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractFormData } from '../contract/types';
import dayjs from 'dayjs';
import { getStartedMonthsBetweenDates, isFixedTermContract } from '../contract/computed';
import { isNil } from 'lodash';

/**
 * Computes special case of contract length where in case of non-terminated rolling contract, it takes the current date
 * as the end date, rounding it up to nearest year.
 */
export const getEffectiveContractLengthInMonths = (contract: Contract | ContractFormData) => {
  if (isFixedTermContract(contract)) {
    return contract.parts.overview?.contractLength === 0 ? undefined : contract.parts.overview?.contractLength;
  }

  // Rolling contract

  const effectiveEndDate = contract.parts.contractual?.lifecycle?.endDate ?? dayjs().format('YYYY-MM-DD');

  if (!contract.parts.contractual?.lifecycle?.startDate) {
    return undefined;
  }

  let contractLengthInMonths = getStartedMonthsBetweenDates(
    contract.parts.contractual?.lifecycle?.startDate,
    effectiveEndDate
  );

  return contractLengthInMonths === undefined || contractLengthInMonths <= 0
    ? 12
    : // Round up
      Math.ceil(contractLengthInMonths / 12) * 12;
};

/** Returns value annualized over the length of passed contract */
export const getAnnualizedValue = (value: number, contract: Contract | ContractFormData) => {
  const lengthInMonth = getEffectiveContractLengthInMonths(contract);

  if (isNil(lengthInMonth) || lengthInMonth === 0) {
    return undefined;
  }

  const lengthInYears = lengthInMonth / 12;

  return value / lengthInYears;
};

/**
 * Computes relative savings between two prices. Clamps the result between 0 and 1.
 *
 * @example
 * getSavingsPercentage({ oldPrice: 4, newPrice: 3 }) // 0.25
 * getSavingsPercentage({ oldPrice: 4, newPrice: -1 }) // 1
 * getSavingsPercentage({ oldPrice: 4, newPrice: 5 }) // 0
 */
export const getSavingsPercentage = ({ newPrice, oldPrice }: { newPrice: number; oldPrice: number }): number => {
  const rawPercentage = oldPrice === 0 ? 0 : (oldPrice - newPrice) / oldPrice;
  const clampedNegative = Math.max(rawPercentage, 0);
  return Math.min(clampedNegative, 1);
};

export const getSavingsVsInitial = (contract: Contract | ContractFormData): number =>
  Math.max(contract.parts.overview?.totalCostValueSavings ?? 0, 0);

export const getAnnualSavingsVsInitial = (contract: Contract | ContractFormData): number =>
  Math.max(contract.parts.overview?.annualSavings ?? 0, 0);

export const getAnnualSavingsVsInitialPercentage = (contract: Contract | ContractFormData): number =>
  getSavingsPercentage({
    oldPrice: contract.parts.overview?.annualCostWithoutVertice ?? 0,
    newPrice: contract.parts.overview?.annualCostWithVertice ?? 0,
  });

export const getAdditionalSavings = (contract: Contract | ContractFormData): number =>
  contract.parts.overview?.realizedConcessions ?? 0;

export const getAnnualAdditionalSavings = (contract: Contract | ContractFormData): number =>
  getAnnualizedValue(getAdditionalSavings(contract), contract) ?? 0;

export const getFutureSavings = (contract: Contract | ContractFormData): number =>
  contract.parts.overview?.unrealizedConcessions ?? 0;

export const getAnnualFutureSavings = (contract: Contract | ContractFormData): number =>
  getAnnualizedValue(getFutureSavings(contract), contract) ?? 0;

export const getScopeReductionSavings = (contract: Contract | ContractFormData): number =>
  contract.parts.overview?.scopeReductionSavings ?? 0;

export const getAnnualScopeReductionSavings = (contract: Contract | ContractFormData): number =>
  getAnnualizedValue(getScopeReductionSavings(contract), contract) ?? 0;

export const getTotalSavings = (contract: Contract | ContractFormData): number =>
  Math.max(contract.parts.overview?.totalSavings ?? 0, 0);

export const getAnnualTotalSavings = (contract: Contract | ContractFormData): number =>
  Math.max(
    // We don't use getSavingsVsInitial because we want to accept negative annualSaving for this computation
    (contract.parts.overview?.annualSavings ?? 0) +
      getAnnualAdditionalSavings(contract) +
      getAnnualFutureSavings(contract) +
      getAnnualScopeReductionSavings(contract),
    0
  );

export const getAnnualTotalSavingsPercentage = (contract: Contract | ContractFormData): number => {
  const annualCostWithoutVertice = contract.parts.overview?.annualCostWithoutVertice ?? 0;
  return getSavingsPercentage({
    oldPrice: annualCostWithoutVertice,
    newPrice: annualCostWithoutVertice - getAnnualTotalSavings(contract),
  });
};
