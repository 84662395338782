import { useMemo } from 'react';
import { Stack } from '@mui/material';
import groupBy from 'lodash/groupBy';

import { Placeholder } from '@verticeone/design-system/src';
import { ChipButton } from '@verticeone/design-system/src';

import { useRequestJournal } from './useRequestJournal';
import { JournalItem } from './JournalItems/JournalItem';
import { UsersContextProvider } from '@vertice/core/src/contexts/UsersContext';
import keepOnlyLocalDatePart from '@vertice/utils/src/dates/keepOnlyLocalDatePart';
import { useFormatDate } from '@vertice/utils/src/formatting/date';
import { useRequestContext } from '../../RequestContext';

export const JournalSection = () => {
  const { requestId } = useRequestContext();
  const { journalItems, isLoadingJournal } = useRequestJournal(requestId);
  const formatDate = useFormatDate();

  const groupedByDay = useMemo(() => {
    return groupBy(journalItems, (item) => keepOnlyLocalDatePart(item.timestamp));
  }, [journalItems]);

  return (
    <UsersContextProvider>
      <Stack gap={10} paddingY={4}>
        {isLoadingJournal && [1, 2, 3].map((i) => <Placeholder width="80%" height={40} key={i} />)}
        {Object.keys(groupedByDay).map((day) => (
          <Stack gap={6} alignItems="flex-start" key={day}>
            <ChipButton color="neutral">{formatDate(day)}</ChipButton>
            <Stack gap={10} width="100%">
              {groupedByDay[day].map((item, index) => (
                <JournalItem item={item} key={index} />
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </UsersContextProvider>
  );
};
