import React, { createContext, useContext, useEffect, useState } from 'react';
import useWindowDimensions from '@vertice/hooks/src/useWindowDimensions';

const VIEW_BREAKPOINT = 1440;

type LayoutWrapperProps = {
  children: string | JSX.Element | JSX.Element[];
};

type LayoutContextType = {
  isSmall: boolean;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  setOpen: (isOpen: boolean) => void;
};

const LayoutContext = createContext<LayoutContextType>({} as LayoutContextType);

export const useMainLayout = () => useContext(LayoutContext) as unknown as LayoutContextType;

const LayoutWrapper = ({ children }: LayoutWrapperProps) => {
  const { width } = useWindowDimensions();
  const isSmall = width < VIEW_BREAKPOINT;
  const [isOpen, setOpen] = useState(!isSmall);
  const layoutContext: LayoutContextType = {
    isSmall,
    isOpen,
    setOpen,
    open: () => setOpen(true),
    close: () => setOpen(false),
    toggle: () => setOpen(!isOpen),
  };

  useEffect(() => {
    setOpen(!isSmall);
  }, [isSmall]);

  return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>;
};

export default LayoutWrapper;
