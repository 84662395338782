import React from 'react';
import { Stack, Typography } from '@mui/material';
import { MORE_THAN_MONTH, useGetLastUsed } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ProgressiveDisclosureStack } from './ProgressiveDisclosureStack';
import { useFormatDate } from '@vertice/utils/src/formatting/date';

type LastUsedCellProps = {
  lastUsed: number | null;
};

const LastUsedCellWithSkeleton = ({ lastUsed }: LastUsedCellProps) => {
  const { t } = useTranslation();
  const { getDaysFromNowInString } = useGetLastUsed();
  const formatDate = useFormatDate();

  if (lastUsed === null) {
    return null;
  }

  const lastUsedDate = dayjs().subtract(lastUsed, 'day').toISOString();

  const dateFormated =
    lastUsed >= MORE_THAN_MONTH
      ? formatDate(lastUsedDate, { formatterOptions: { year: 'numeric', month: 'short' } })
      : formatDate(lastUsedDate);

  return (
    <Stack direction="column">
      {lastUsed != null && <Typography variant="body-regular-m">{getDaysFromNowInString(lastUsed)}</Typography>}
      <ProgressiveDisclosureStack height="22px" direction="row" alignItems="center">
        <Typography variant="caption-s" sx={{ color: 'var(--tokens-color-light-inactive-1)' }}>
          {lastUsed != null ? dateFormated : t('APPLICATIONS.USAGE.LAST_USED.NO_DATA')}
        </Typography>
      </ProgressiveDisclosureStack>
    </Stack>
  );
};

export default LastUsedCellWithSkeleton;
