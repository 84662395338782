import {
  DataTableResult,
  useAnalyticsChartTagsServicesQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import useDeferredQuery from '@vertice/utils/src/api/useDeferredQuery';
import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { getSortedTableData, prepareCostDataForPeriods } from '../../BaseCloudTab/Graph/utils';
import { PeriodCostComparisonData } from '../../BaseCloudTab/Graph/types';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';

const useTagsServicesGraphData = (): LoadableAdvanced<PeriodCostComparisonData> => {
  const { accountId } = useAccountContext();
  const { period, chartDataLimit, filter } = useCloudAnalytics();
  const { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd } = period;

  const tagName = filter.tagName as string;
  const tagValues = ((filter.tagValues as []) || []).join(',');
  const skip = !accountId || !tagName;

  const {
    data: rawData,
    error,
    isLoading,
  } = useDeferredQuery(
    useAnalyticsChartTagsServicesQuery,
    {
      accountId,
      periodOneStart,
      periodOneEnd,
      periodTwoStart,
      periodTwoEnd,
      tagName,
      tagValues,
      limit: chartDataLimit,
    },
    { skip, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  );

  const computedData = useMemo(() => getSortedTableData(rawData as DataTableResult), [rawData]);

  const data = useMemo(
    () => prepareCostDataForPeriods(computedData, periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd),
    [computedData, periodOneEnd, periodOneStart, periodTwoEnd, periodTwoStart]
  );

  return {
    error: error,
    isEmpty: Boolean(computedData && computedData.length === 0),
    isLoading: isLoading && !skip,
    data,
  };
};

export default useTagsServicesGraphData;
