import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractValue, CheckboxFilterValue, ExchangeContractCostFnType, RenewalStatus } from './types';
import { canContractBeRenewed, hasDeadlineWithinDays, isPlannedForExpiration } from '../../../computed';
import { isNil } from 'lodash';
import { getUnifiedStage } from '../functions';
import { UnifiedContractStage } from '../types';

export const contractContainsString = ({ contract }: ViewOnContract, search?: string) => {
  if (!search) {
    return true;
  }

  const sanitizedSearch = search.trim().toLowerCase();
  return (
    contract.parts.contractual?.vendor?.vendorName?.toLowerCase().includes(sanitizedSearch) ||
    contract.parts.contractual?.products?.some((product) =>
      product.productName?.toLowerCase().includes(sanitizedSearch)
    )
  );
};

const RenewalStatusFilterMap = {
  [RenewalStatus.NOT_YET_RENEWED]: ({ contract }: ViewOnContract) =>
    canContractBeRenewed(contract) && !isPlannedForExpiration(contract),
  [RenewalStatus.NOT_YET_RENEWED_DEADLINE_90_DAYS]: ({ contract }: ViewOnContract) =>
    canContractBeRenewed(contract) && !isPlannedForExpiration(contract) && hasDeadlineWithinDays(contract, 90),
  [RenewalStatus.RENEWAL_IN_PROGRESS]: ({ contract }: ViewOnContract) =>
    contract.materialized?.next?.simplifiedStage === 'WORKFLOW_IN_PROGRESS',
  [RenewalStatus.RENEWAL_WITH_VERTICE_COMPLETED]: ({ contract }: ViewOnContract) =>
    contract.materialized?.next?.contractOrigin === 'RENEWAL_WITH_VERTICE' &&
    contract.materialized.next.simplifiedStage !== 'WORKFLOW_IN_PROGRESS',
  [RenewalStatus.RENEWAL_OUTSIDE_VERTICE_COMPLETED]: ({ contract }: ViewOnContract) =>
    contract.materialized?.next?.contractOrigin === 'RENEWAL_WITHOUT_VERTICE' &&
    contract.materialized.next.simplifiedStage !== 'WORKFLOW_IN_PROGRESS',
  [RenewalStatus.PLANNED_FOR_EXPIRATION]: ({ contract }: ViewOnContract) => isPlannedForExpiration(contract),
};

const ContractValueMap = {
  [ContractValue.BELOW_THRESHOLD]: (
    { contract }: ViewOnContract,
    getContractCostInThresholdCurrency: ExchangeContractCostFnType,
    threshold: number
  ) => {
    const contractCostInThresholdCurrency = getContractCostInThresholdCurrency(contract);
    return !isNil(contractCostInThresholdCurrency) && contractCostInThresholdCurrency <= threshold;
  },
  [ContractValue.ABOVE_THRESHOLD]: (
    { contract }: ViewOnContract,
    getContractCostInThresholdCurrency: ExchangeContractCostFnType,
    threshold: number
  ) => {
    const contractCostInThresholdCurrency = getContractCostInThresholdCurrency(contract);
    return !isNil(contractCostInThresholdCurrency) && contractCostInThresholdCurrency > threshold;
  },
};

export const filterRenewalStatus = (view: ViewOnContract, renewalStates?: RenewalStatus[]) =>
  !renewalStates || renewalStates.some((renewalStatus) => RenewalStatusFilterMap[renewalStatus](view));

export const filterDepartment = ({ contract }: ViewOnContract, departmentIds?: string[]): boolean =>
  !departmentIds || !!contract.record.tags?.find(({ tagId }) => tagId && departmentIds.includes(tagId));

export const filterOwner = ({ computed }: ViewOnContract, ownerIds?: string[]): boolean =>
  !ownerIds || !!computed?.owners?.find((owner) => owner.userId && ownerIds.includes(owner.userId));

export const getFilterForContractValue =
  (getContractCostInThresholdCurrency: ExchangeContractCostFnType, threshold?: number | null) =>
  (view: ViewOnContract, contractValues?: ContractValue[]) =>
    !contractValues ||
    isNil(threshold) ||
    contractValues.some((contractValue) =>
      ContractValueMap[contractValue](view, getContractCostInThresholdCurrency, threshold)
    );

export const filterStage = ({ contract }: ViewOnContract, selectedStages?: UnifiedContractStage[]): boolean =>
  !selectedStages || selectedStages.some((selectedStage) => getUnifiedStage(contract) === selectedStage);

export const filterTextField =
  (valueGetter: (view: ViewOnContract) => string | null | undefined) => (view: ViewOnContract, search?: string) => {
    if (!search) {
      return true;
    }

    const contractValue = valueGetter(view)?.toLowerCase();
    if (!contractValue) {
      return false;
    }

    const sanitizedSearch = search.trim().toLowerCase();
    return contractValue.includes(sanitizedSearch);
  };

export const filterDropdownField =
  (valueGetter: (view: ViewOnContract) => string | null | undefined) => (view: ViewOnContract, values?: string[]) => {
    if (!values) {
      return true;
    }

    const contractValue = valueGetter(view);
    return !!contractValue && values.includes(contractValue);
  };

export const filterCheckboxField =
  (valueGetter: (view: ViewOnContract) => boolean | null | undefined) =>
  (view: ViewOnContract, values?: CheckboxFilterValue[]) => {
    if (!values) {
      return true;
    }

    const contractValue = valueGetter(view);
    return (
      (contractValue === true && values.includes(CheckboxFilterValue.TRUE)) ||
      (contractValue === false && values.includes(CheckboxFilterValue.FALSE))
    );
  };
