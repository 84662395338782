import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import { addMonths, startOfMonth } from 'date-fns';
import {
  TimeSeriesDataValue,
  useComputeUsageByProductQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useCallback, useMemo } from 'react';
import {
  CategoryValues,
  DATE_FORMAT,
  fillMissingMonths,
  getLargestNCategories,
  getSeriesByCategory,
} from '../../utils/graphDataUtils';
import useDeferredQuery from '@vertice/utils/src/api/useDeferredQuery';
import format from 'date-fns/format';
import { AWS_DEFAULT_CURRENCY } from '../../constants';
import { useTranslation } from 'react-i18next';
import { KnownPurchaseOptionId, purchaseOptionsTranslationMap } from './translationMaps';
import { chain } from 'lodash';
import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';

const startDate = addMonths(startOfMonth(new Date()), -11);

const NUMBER_OF_SERIES = 5;
const AGGREGATED_CATEGORY = 'others';

export type ComputeUsageData = {
  currency: string;

  /** All months in the X axis in the format of yyyy-MM-dd. */
  months: string[];

  /** The list of transaction type categories with the monetary values. The number of values should correspond to the number of months. */
  values: SeriesOptionsWithData[];

  /** Order of series from top to bottom separately for positive and negative series */
  usedCategories: string[];
};

const useComputeUsageData = ({
  groupBy,
  normalize = false,
}: {
  groupBy: 'product' | 'purchase_option';
  normalize?: boolean;
}): LoadableAdvanced<ComputeUsageData> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.COMPUTE_USAGE.PURCHASE_OPTION' });
  const { accountId } = useSelector(getAccount);
  const { data: rawData, error } = useDeferredQuery(
    useComputeUsageByProductQuery,
    {
      accountId: accountId!,
      // Acceptable timezone inaccuracy: We're sending local timezone date to UTC endpoint
      startDate: format(startDate, DATE_FORMAT),
      groupBy,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ athenaQuery }) => athenaQuery
  );

  const getDataSeries = useCallback(
    (monthlyDataRaw: TimeSeriesDataValue[][], usedCategories: string[]) => {
      const monthlyData = normalize
        ? monthlyDataRaw.map((allInMonth) => {
            const sum = chain(allInMonth)
              .flatMap(({ values }) => values)
              .sum()
              .value();
            return allInMonth.map(
              (dataValue): TimeSeriesDataValue => ({
                ...dataValue,
                values: dataValue.values.map((value) => (sum === 0 ? 0 : value / sum)),
              })
            );
          })
        : monthlyDataRaw;

      const seriesByCategory = getSeriesByCategory(monthlyData, usedCategories, {
        aggregatedCategory: 'Other',
      });

      return seriesByCategory.map((categorySeries: CategoryValues) => {
        const translationKey = purchaseOptionsTranslationMap[categorySeries.id as KnownPurchaseOptionId];
        return {
          ...categorySeries,
          name: translationKey ? t(translationKey) : categorySeries.id,
          type: 'column',
        };
      });
    },
    [normalize, t]
  );

  const computed = useMemo(() => {
    if (!rawData) return undefined;
    const allMonthsData = fillMissingMonths(rawData, startDate, []);
    const allMonthsDataValues = allMonthsData.map(({ values }) => values);
    const usedCategories = getLargestNCategories(allMonthsDataValues, {
      maxNumberOfSeries: NUMBER_OF_SERIES,
      aggregatedCategory: AGGREGATED_CATEGORY,
    });

    return {
      months: allMonthsData.map(({ time }) => time!),
      values: getDataSeries(allMonthsDataValues, usedCategories) as SeriesOptionsWithData[],
      currency: AWS_DEFAULT_CURRENCY,
      usedCategories,
    };
  }, [getDataSeries, rawData]);

  return {
    error: error,
    isEmpty: Boolean(rawData && rawData.length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useComputeUsageData;
