import { SimplifiedStage, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ScheduleDetails, Stage } from '@vertice/core/src/modules/saas/schedule/components/ScheduleView/types';
import { DASHBOARD_FILTERS } from './types';
import { isContractPurchase } from '@vertice/core/src/modules/saas/contract/computed';
import { evaluateFilterView } from '@vertice/core/src/modules/saas/contract/components/ContractList/functions';
import { RequestRow } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/types';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

const isFinished = (stage?: SimplifiedStage) => {
  return stage ? ['NOT_YET_LIVE', 'LIVE'].includes(stage) : false;
};

const isRenewal = (record: ViewOnContract['contract']['record']) =>
  record.contractOrigin === 'RENEWAL_WITHOUT_VERTICE' || record.contractOrigin === 'RENEWAL_WITH_VERTICE';

export const getScheduleStage = (viewOnContract: ViewOnContract) => {
  for (const [stage, filterView] of Object.entries(DASHBOARD_FILTERS)) {
    if (evaluateFilterView(filterView, viewOnContract)) {
      return stage as Stage;
    }
  }
};

export const getScheduleDate = ({ contract }: ViewOnContract, scheduleStage?: Stage): string | undefined => {
  if (scheduleStage === 'RECENTLY_COMPLETED' && isFinished(contract.materialized?.next?.simplifiedStage)) {
    return contract.materialized?.next?.signingDate;
  } else if (
    scheduleStage === 'RECENTLY_COMPLETED' &&
    isContractPurchase(contract) &&
    isFinished(contract.materialized?.simplifiedStage) &&
    (!contract.materialized?.next || !isFinished(contract.materialized?.next?.simplifiedStage))
  ) {
    return contract.parts.contractual?.signatory?.signingDate;
  }

  return contract.materialized?.renewalDeadline?.deadlineDate;
};

const getVendor = (request: RequestRow) => {
  if (request.offersFinal?.finalOffer) {
    return {
      vendorId: request.offersFinal.finalOffer.vendor.id,
      vendorName: request.offersFinal.finalOffer.vendor.name,
      products: request.offersFinal.finalOffer.products.map(({ name }) => name),
    };
  } else if (request.offersBaseline?.baselineOffer) {
    return {
      vendorId: request.offersBaseline.baselineOffer.vendor.id,
      vendorName: request.offersBaseline.baselineOffer.vendor.name,
      products: request.offersBaseline.baselineOffer.products.map(({ name }) => name),
    };
  }
  return {
    vendorId: request.vendor?.id ?? undefined,
    vendorName: request.vendor?.name ?? undefined,
    products: undefined,
  };
};

const getAnnualCost = (keyCosts: RequestRow['keyCosts']) => {
  if (keyCosts?.negotiatedAnnualCost?.amount) {
    return {
      annualCost: keyCosts.negotiatedAnnualCost.amount,
      currency: keyCosts.negotiatedAnnualCost.currency || DEFAULT_CURRENCY,
    };
  } else if (keyCosts?.annualCost?.amount) {
    return {
      annualCost: keyCosts.annualCost.amount,
      currency: keyCosts.annualCost.currency || DEFAULT_CURRENCY,
    };
  } else if (keyCosts?.approvedBudget?.amount) {
    return {
      annualCost: keyCosts.approvedBudget.amount,
      currency: keyCosts.approvedBudget.currency || DEFAULT_CURRENCY,
    };
  }

  return {
    annualCost: undefined,
    currency: DEFAULT_CURRENCY,
  };
};

export const mapContract2Schedule = (
  view: ViewOnContract,
  generateContractPath: (contractId: string) => string
): ScheduleDetails => {
  const { record, parts, workflow, materialized } = view.contract;
  const scheduleStage = getScheduleStage(view);

  return {
    vendor: {
      vendorId: parts.contractual?.vendor?.vendorId,
      vendorName: parts.contractual?.vendor?.vendorName,
      annualCost: parts.overview?.effectiveAnnualCost,
      currency: parts.contractual?.financial?.baseCurrency || DEFAULT_CURRENCY,
      products: parts.contractual?.products?.map(({ productName }) => productName!),
    },
    timeline: {
      targetSignDate: workflow?.externalDocuments?.targetSignDate,
      renewalDate: isRenewal(record) ? materialized?.prev?.renewalDate : undefined,
      autoRenewalWaived: !!materialized?.prev?.autoRenewalWaived,
      autoRenewalDeadline: materialized?.prev?.autoRenewalDeadline,
    },
    schedule: { scheduleStage, scheduleDate: getScheduleDate(view, scheduleStage) },
    navigationPath: generateContractPath(record.contractId),
  };
};

export const mapRequest2Schedule = (
  request: RequestRow,
  contracts: Record<string, ViewOnContract>,
  generateContractPath: (contractId: string) => string,
  generateRequestPath: (requestId: string) => string
): ScheduleDetails | undefined => {
  if (request.status !== 'ACTIVE') {
    return;
  }

  if (request.isLegacy) {
    const contract = contracts[request.requestId];
    if (contract) {
      return mapContract2Schedule(contract, generateContractPath);
    }
  } else {
    const contractId = request.contracts?.contractId;
    const contract = contractId ? contracts[contractId] : undefined;

    return {
      vendor: {
        ...getVendor(request),
        ...getAnnualCost(request.keyCosts),
      },
      timeline: {
        targetSignDate: request.keyDates?.targetSignDate,
        renewalDate: contract?.contract.parts.contractual?.lifecycle?.renewalDate,
        autoRenewalWaived: contract?.contract.parts.contractual?.renewal?.autoRenewalWaived ?? false,
        autoRenewalDeadline: contract?.contract.parts.contractual?.lifecycle?.autoRenewalDeadline,
      },
      schedule: {
        scheduleStage: 'IN_PROGRESS',
        scheduleDate:
          contract?.contract.materialized?.renewalDeadline?.deadlineDate ?? request.keyDates?.targetSignDate,
      },
      navigationPath: generateRequestPath(request.requestId),
    };
  }
};
