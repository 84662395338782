import { createContext, ReactNode, useContext, useState } from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { HookResult } from '@vertice/utils/src/api/useDeferredQuery';
import {
  DataTableResult,
  ErroredQueryResult,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { Columns } from './types';

type MappingFunction = (acc: Record<string, string | number>, item: Columns) => Record<string, string | number>;

type ComparisonDrawerContextType<QueryParams> = {
  selectionIds: GridRowSelectionModel;
  setSelectionIds: (ids: GridRowSelectionModel) => void;
  instanceSettings: {
    query: (params: QueryParams) => HookResult<DataTableResult | ErroredQueryResult>;
    queryParams?: QueryParams;
    mappingFunction: MappingFunction;
    comparisonColumn: string;
  };
  isOpened: boolean;
  open: () => void;
  close: () => void;
};

const ComparisonDrawerContext = createContext<ComparisonDrawerContextType<any> | undefined>(undefined);

type ComparisonDrawerProviderProps<QueryParams> = {
  children: ReactNode;
  instanceSettings: {
    query: (params: QueryParams) => HookResult<DataTableResult | ErroredQueryResult>;
    queryParams?: QueryParams;
    mappingFunction: MappingFunction;
    comparisonColumn: string;
  };
};

const ComparisonDrawerProvider = <QueryParams,>({
  children,
  instanceSettings,
}: ComparisonDrawerProviderProps<QueryParams>) => {
  const [isOpened, setIsOpened] = useState(false);
  const [selectionIds, setSelectionIds] = useState<GridRowSelectionModel>([]);

  const open = () => setIsOpened(true);
  const close = () => setIsOpened(false);

  const contextValue: ComparisonDrawerContextType<QueryParams> = {
    selectionIds,
    setSelectionIds,
    instanceSettings,
    isOpened,
    open,
    close,
  };

  return <ComparisonDrawerContext.Provider value={contextValue}>{children}</ComparisonDrawerContext.Provider>;
};

export const useComparisonDrawer = <QueryParams,>() => {
  const context = useContext(ComparisonDrawerContext);
  if (context === undefined) {
    throw new Error('useComparisonDrawer must be used within a ComparisonDrawerProvider');
  }
  return context as ComparisonDrawerContextType<QueryParams>;
};

export default ComparisonDrawerProvider;
