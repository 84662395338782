import React, { FC, useEffect, useId, useMemo, useState } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';

import { IntelligentWorkflowsLayout } from '../../components/IntelligentWorkflowsLayout';
import { ToggleSwitch } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import { NoData } from '@verticeone/design-system/src';
import { Tabs } from '@verticeone/design-system/src';
import { Tab } from '@verticeone/design-system/src';
import { TabsBottomLineWrapper } from '@verticeone/design-system/src';
import { Card } from '@verticeone/design-system/src';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import { TaskDrawer } from '../TaskDrawer/TaskDrawer';
import { useTasks } from './useTasks';
import { TaskRow } from '../types';
import { useTaskColumns } from './useTaskColumns';
import CardGrid from '../TaskList/CardGrid';
import SearchInput from '@vertice/core/src/components/SearchInput';
import { CardGridSortModel, OnRowClickEvent } from '../TaskList/CardGrid/types';
import { useSearchParams } from 'react-router-dom';

type TaskListTabs = 'active' | 'completed';

export const TasksListPage: FC = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { isUserPowerUser, isUserAdmin, isLoading: isLoadingUserRoles } = useLoggedUserAccountRoles();
  const isAdminOrPowerUser = isUserAdmin || isUserPowerUser;
  const myTasksToggleDisabled = !isAdminOrPowerUser || isLoadingUserRoles;

  const [showMyTasksOnly, setShowMyTasksOnly] = useState(true);
  const showMyTasksOnlyToggleId = useId();

  const [sortModel, setSortModel] = useState<CardGridSortModel>([{ field: 'createdAt', sort: 'asc' }]);

  const [searchParams, setSeachParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState<TaskListTabs>(
    searchParams.get('tab') === 'completed' ? 'completed' : 'active'
  );
  const showCompleted = activeTab === 'completed';

  const [searchValue, setSearchValue] = useState('');

  const { filteredTaskRows, allTasksRows, isLoading } = useTasks(showMyTasksOnly, showCompleted, searchValue);

  const taskCardColumns = useTaskColumns({ showCompleted });

  const handleTabChange = (newTabValue: TaskListTabs) => {
    setActiveTab(newTabValue);
    setSeachParams({ tab: newTabValue });
    // when switching between tabs, we want to reset the sorting to the default if the sorting is on column that is not present in the new tab
    const sortModelFields = map(sortModel, 'field');
    if (newTabValue === 'active' && sortModelFields.includes('closedAt')) {
      setSortModel([{ field: 'createdAt', sort: 'asc' }]);
    }
    if (newTabValue === 'completed' && sortModelFields.includes('createdAt')) {
      setSortModel([{ field: 'closedAt', sort: 'desc' }]);
    }
  };

  const [drawerTaskId, setDrawerTaskId] = useState<string | undefined>(undefined);
  const onOpenTask: OnRowClickEvent<TaskRow> = (params) => {
    setDrawerTaskId(params.row.id);
  };
  const drawerTask = useMemo(() => {
    return allTasksRows.find((row) => row.id === drawerTaskId);
  }, [allTasksRows, drawerTaskId]);

  useEffect(() => {
    const currentTabParam = (searchParams.get('tab') || 'active') as TaskListTabs;
    if (Array<TaskListTabs>('completed', 'active').includes(currentTabParam)) {
      setActiveTab(currentTabParam as TaskListTabs);
    }
  }, [searchParams]);

  return (
    <IntelligentWorkflowsLayout heading={t('INTELLIGENT_WORKFLOWS.NAVBAR.TASKS')}>
      <Stack gap={8}>
        <TabsBottomLineWrapper paddingX={4}>
          <Tabs
            variant="outlined"
            value={activeTab}
            onChange={(_, value) => handleTabChange(value)}
            scrollButtons="auto"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            <Tab value="active" label={t(`INTELLIGENT_WORKFLOWS.TASKS_LIST.ACTIVE_TAB`)} />
            <Tab value="completed" label={t(`INTELLIGENT_WORKFLOWS.TASKS_LIST.COMPLETED_TAB`)} />
          </Tabs>
        </TabsBottomLineWrapper>
        <Card>
          <Stack direction="row" padding={4} bgcolor={palette.core.color1} justifyContent="space-between">
            <Stack>{/*filters*/}</Stack>
            <Stack direction="row" alignItems="center" gap={4}>
              <Stack direction="row" alignItems="center" gap={2}>
                <ToggleSwitch
                  id={showMyTasksOnlyToggleId}
                  checked={showMyTasksOnly}
                  onChange={() => setShowMyTasksOnly(!showMyTasksOnly)}
                  disabled={myTasksToggleDisabled}
                  testId="MyTasksOnly"
                />
                <label htmlFor={showMyTasksOnlyToggleId} style={{ whiteSpace: 'nowrap' }}>
                  <Text variant="button-bold" size="S" color={myTasksToggleDisabled ? 'inactive1' : 'text1'}>
                    {t(`INTELLIGENT_WORKFLOWS.TASKS_LIST.MY_TASKS_ONLY`)}
                  </Text>
                </label>
              </Stack>
              <Stack width={256}>
                <SearchInput
                  size="S"
                  value={searchValue}
                  onSearch={setSearchValue}
                  debounce={100}
                  placeholder={t(
                    showCompleted
                      ? 'INTELLIGENT_WORKFLOWS.TASKS_LIST.SEARCH_N_COMPLETED_TASKS'
                      : 'INTELLIGENT_WORKFLOWS.TASKS_LIST.SEARCH_N_ACTIVE_TASKS',
                    {
                      count: filteredTaskRows.length,
                    }
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Card>

        <CardGrid<TaskRow>
          key={activeTab}
          columns={taskCardColumns}
          rows={filteredTaskRows}
          activeRowId={drawerTaskId}
          loading={isLoading}
          loadingRowCount={5}
          onRowClick={onOpenTask}
          slots={{
            noRowsOverlay: () => (
              <NoData
                header={t(
                  searchValue
                    ? `INTELLIGENT_WORKFLOWS.TASKS_LIST.NO_TASKS_FOUND`
                    : `INTELLIGENT_WORKFLOWS.TASKS_LIST.NO_TASKS_AVAILABLE`
                )}
                content={searchValue ? undefined : t(`INTELLIGENT_WORKFLOWS.TASKS_LIST.NO_TASKS_AVAILABLE_SUBTITLE`)}
              />
            ),
          }}
          noRowsOverlayHeight={300}
          rowHeight={67}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />
      </Stack>
      {drawerTaskId && <TaskDrawer onClose={() => setDrawerTaskId(undefined)} taskRow={drawerTask} />}
    </IntelligentWorkflowsLayout>
  );
};
