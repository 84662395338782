import LargeValue from '../LargeValue';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import { useWatch } from 'react-hook-form';
import { ContractFormData } from '../../types';

export type AnnualCostWithVerticeViewOnlyFieldProps = {
  id: string;
};

export const AnnualCostWithVerticeViewOnlyField = ({ id }: AnnualCostWithVerticeViewOnlyFieldProps) => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();

  const annualCostWithVertice = useWatch<ContractFormData>({ name: 'parts.overview.annualCostWithVertice' });
  const totalCostValueWithVertice = useWatch<ContractFormData>({ name: 'parts.overview.totalCostValueWithVertice' });

  return (
    <LargeValue
      id={id}
      primaryValue={formatContractCurrency(annualCostWithVertice)}
      primaryTestId={'annualCostWithVerticeValue'}
      primaryColor="text1"
      secondaryValue={
        totalCostValueWithVertice
          ? t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(totalCostValueWithVertice) })
          : undefined
      }
      secondaryTestId={'totalCostValueWithVerticeValue'}
    />
  );
};
