import { useMemo } from 'react';
import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { useAnalyticsTagsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import useDeferredQuery from '@vertice/utils/src/api/useDeferredQuery';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

export type TableRow = {
  tag_name: string;
  tag_count: number;
};

type TagsDataProps = {
  periodOneStart: string;
  periodOneEnd: string;
  periodTwoStart: string;
  periodTwoEnd: string;
};

const useTagsData = ({
  periodOneStart,
  periodOneEnd,
  periodTwoStart,
  periodTwoEnd,
}: TagsDataProps): LoadableAdvanced<Array<TableRow>> => {
  const { accountId } = useAccountContext();
  const {
    data: rawData,
    error,
    isLoading,
  } = useDeferredQuery(
    useAnalyticsTagsQuery,
    {
      accountId,
      periodOneStart,
      periodOneEnd,
      periodTwoStart,
      periodTwoEnd,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  );

  const computedTableData = useMemo(() => {
    if (rawData?.__typename !== 'DataTableResult' || !rawData.table) {
      return;
    }

    return getTableData(rawData.table) as TableRow[];
  }, [rawData]);

  return {
    error,
    isEmpty: Boolean(computedTableData && computedTableData?.length === 0),
    isLoading,
    data: computedTableData,
  };
};

export default useTagsData;
