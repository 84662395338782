import {
  ProposedCostsValue,
  RecommendationStatus,
  useOptimizationRecommendationsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import recsByCode from '@vertice/i18n/src/i18n/cloud/recommendationsByCode_en.json';
import groupByMultipleKeys from '@vertice/utils/src/collections/groupByMultipleKeys';
import { isNil, sumBy, chain, maxBy } from 'lodash';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export type RecommendationJsonRecord = {
  CODE: string;
  NAME: string;
  DESCRIPTION?: string;
  INSTRUCTIONS?: string;
  EFFORT?: string;
};

export type Recommendation = {
  code: string;
  name: string;
  description?: string;
  instructions?: string;
  effort?: string;

  breaching: boolean;
  status?: RecommendationStatus;
  categories: string[];
  tags?: string[][];

  resourceCost: number;
  proposedCost?: number | undefined;
  proposedCosts?: ProposedCostsValue;
  saving: number;
  empty?: boolean;
  testResults?: { code: string }[];
};

type HookResult = {
  isLoading: boolean;
  error: unknown | undefined;
  all?: Recommendation[];
  byCategory?: Record<string, Recommendation[]>;
  byCategoryWithoutTagDuplicities?: Record<string, Recommendation[]>;
  byTestCode?: Record<string, Recommendation[]>;
  stats?: {
    totalMonthlyRecValue: number;
    totalIgnoredRecValue: number;
    breachingRecsCount: number;
  };
};

const FALLBACK_CATEGORY = 'Unknown';

const removeTagDuplicities = (recommendations: Recommendation[]): Recommendation[] =>
  chain(recommendations)
    .groupBy(({ tags }) => tags)
    .map((values) => maxBy(values, ({ saving }) => saving))
    .value() as Recommendation[];

const useRecommendationsData = (): HookResult => {
  const { accountId } = useAccountContext();
  const { data: rawData, error } = useOptimizationRecommendationsQuery({ accountId: accountId! }, { skip: !accountId });

  return useMemo(() => {
    if (!rawData || rawData.recommendationsQuery?.__typename !== 'RecommendationsResult') {
      return { isLoading: true, error };
    }
    const recommendations = rawData.recommendationsQuery.items.map((rec): Recommendation => {
      const textationItem: RecommendationJsonRecord | undefined =
        recsByCode[rec.code.toUpperCase() as keyof typeof recsByCode];
      return {
        ...rec,
        saving: isNil(rec.proposedCost) ? 0 : Math.max(0, rec.resourceCost - rec.proposedCost),
        name: textationItem?.NAME ?? rec.code,
        description: textationItem?.DESCRIPTION,
        instructions: textationItem?.INSTRUCTIONS,
        effort: textationItem?.EFFORT,
      };
    });

    const recommendationsWithoutTagDuplicities = removeTagDuplicities(recommendations);

    const recommendationsToCalculateStats = removeTagDuplicities(
      recommendations.filter((rec) => rec.status !== RecommendationStatus.Cancelled)
    );

    const ignoredRecommendations = removeTagDuplicities(
      recommendations.filter((rec) => rec.status === RecommendationStatus.Cancelled)
    );

    return {
      isLoading: false,
      error,
      all: recommendations,
      byCategory: groupByMultipleKeys(recommendations, ({ categories }) =>
        categories.length > 0 ? categories : [FALLBACK_CATEGORY]
      ),
      byCategoryWithoutTagDuplicities: groupByMultipleKeys(recommendationsWithoutTagDuplicities, ({ categories }) =>
        categories.length > 0 ? categories : [FALLBACK_CATEGORY]
      ),
      byTestCode: groupByMultipleKeys(
        recommendations,
        ({ testResults }: Recommendation) => testResults?.map((r) => r.code) ?? []
      ),
      stats: {
        totalMonthlyRecValue: sumBy(recommendationsToCalculateStats, (recommendation) => recommendation?.saving ?? 0),
        totalIgnoredRecValue: sumBy(ignoredRecommendations, (recommendation) => recommendation?.saving ?? 0),
        breachingRecsCount: sumBy(recommendations, ({ breaching }) => (breaching ? 1 : 0)),
      },
    };
  }, [error, rawData]);
};

export default useRecommendationsData;
