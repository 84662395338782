import React, { FC } from 'react';
import { TaskState } from '../../../model/types';
import { Stack } from '@mui/material';
import { IconWrapper } from '@verticeone/design-system/src';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { Text } from '@verticeone/design-system/src';
import { Age } from '../../../../Age';
import { useFormatDate } from '@vertice/utils/src/formatting/date';

type TaskDateProps = {
  state?: TaskState;
  textColor: string;
};

export const TaskDate: FC<TaskDateProps> = ({ state, textColor }) => {
  const formatDate = useFormatDate();

  const showAge = state?.createdAt && state?.status === 'ACTIVE';
  const showDate = state?.closedAt && (state?.status === 'COMPLETED' || state?.status === 'FAILED');

  if (!showAge && !showDate) {
    return null;
  }

  return (
    <Stack gap={1} direction="row" alignItems="center">
      <IconWrapper icon={CalendarTodayOutlined} htmlColor={textColor} size="S" />
      <Text variant="button-bold" size="XS" color={textColor}>
        {showAge && <Age createdAt={state?.createdAt} />}
        {showDate && formatDate(state?.closedAt)}
      </Text>
    </Stack>
  );
};
