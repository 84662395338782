import { createPortableRoutes } from '@verticeone/router/src/routes/utils';

export const preferencesRoutes = createPortableRoutes({
  PREFERENCES: {
    path: '/preferences',
    children: {
      TAB: {
        path: ':activeTab',
        children: {
          JIRA: {
            path: 'jira',
          },
          SLACK: {
            path: 'slack',
          },
        },
      },
    },
  },
} as const);
