// RITransactions.tsx
import React, { FunctionComponent, SVGProps } from 'react';
import { Card, CardHeader, CardHeaderTitle } from '@verticeone/design-system/src';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import { ReservedInstancesResult } from './useRIPurchaseData';
import RIPurchaseTable from './RIPurchaseTable/RIPurchaseTable';
import { SvgIconComponent } from '@mui/icons-material';
import { GridRowId } from '@mui/x-data-grid-pro';
import { LoadingStatus } from '@vertice/utils/src/async/loadableAdvanced';

const DEFAULT_VISIBLE_ROWS = 10;

type RITransactionsProps = {
  isPending: boolean;
  title: string;
  icon: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
  testId: string;
  visibleRows?: number;
  onCancelTransactions?: (rows: Map<GridRowId, any>) => void;
  onLoadMore?: () => void;
  data: ReservedInstancesResult | undefined;
  loadingStatus: LoadingStatus;
};

const RITransactions = ({
  isPending,
  title,
  icon,
  testId,
  visibleRows = DEFAULT_VISIBLE_ROWS,
  onCancelTransactions,
  onLoadMore,
  data,
  loadingStatus,
}: RITransactionsProps) => {
  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle icon={icon} text={title} tag="h2" />
      </CardHeader>
      <LoadableContentWrapper {...loadingStatus}>
        <RIPurchaseTable
          isPending={isPending}
          data={data?.items!}
          visibleRows={visibleRows}
          testId={testId}
          onLoadMore={onLoadMore}
          onCancelTransactions={onCancelTransactions}
        />
      </LoadableContentWrapper>
    </Card>
  );
};

export default RITransactions;
