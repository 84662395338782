const currencies = [
  'AED',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DJF',
  'DKK',
  'DZD',
  'ERN',
  'EUR',
  'GBP',
  'GEL',
  'GTQ',
  'HKD',
  'HNL',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IRR',
  'ISK',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LBP',
  'LKR',
  'LSL',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MOP',
  'MUR',
  'MVR',
  'MXN',
  'MYR',
  'NAD',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SDG',
  'SEK',
  'SGD',
  'SRD',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TRY',
  'TTD',
  'TWD',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'WST',
  'XAF',
  'XCD',
  'XDR',
  'XOF',
  'ZAR',
];

export default currencies;
