import React from 'react';
import { Grid, Stack } from '@mui/material';
import ConcessionsContractFormEntry from './form/ConcessionsContractFormEntry';
import { useTranslation } from 'react-i18next';
import ContractFormEntry from '../ContractFormEntry';
import FormReadOnlyText from '../../../forms/fields/FormReadOnlyText';
import FormNumberField from '../../../forms/fields/FormNumberField';
import FormTextField from '../../../forms/fields/FormTextField';
import { useAllFieldsValid } from '../hooks/useAllFieldsValid';
import { FEATURES } from '../../../features/constants';
import { AdvancedScopeReductionCostFormEntry } from './form/AdvancedScopeReductionCostFormEntry';
import { AdvancedScopeReductionCostOverrideFormEntry } from './form/AdvancedScopeReductionCostOverrideFormEntry';
import { FieldPath } from 'react-hook-form';
import { ContractFormData } from '../types';
import { useAccountContext } from '../../../../contexts/AccountContext';

export const useIsSavingsBreakdownTabValid = () => {
  const { isFeatureEnabled } = useAccountContext();
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);

  const advancedCostModelRules: FieldPath<ContractFormData>[] = [
    'costModel.negotiated.model.scopeReductionSavingsOverride.amount',
    'costModel.negotiated.model.scopeReductionSavingsOverride.description',
  ];

  const annualAproximationCostModelRules: FieldPath<ContractFormData>[] = [
    'costModel.negotiated.model.scopeReductionSavings.amount',
    'costModel.negotiated.model.scopeReductionSavings.description',
  ];

  return useAllFieldsValid([
    ...(advancedCostModels ? advancedCostModelRules : annualAproximationCostModelRules),
    'costModel.negotiated.model.concessions',
  ]);
};

const SavingsBreakdownTab = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAccountContext();
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);

  const descriptionPathName = advancedCostModels
    ? 'costModel.negotiated.model.scopeReductionSavingsOverride.description'
    : 'costModel.negotiated.model.scopeReductionSavings.description';

  return (
    <Stack p={6} gap={6}>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          {advancedCostModels ? (
            <>
              <AdvancedScopeReductionCostFormEntry />
              <AdvancedScopeReductionCostOverrideFormEntry />
            </>
          ) : (
            <ContractFormEntry
              name="costModel.negotiated.model.scopeReductionSavings.amount"
              label={t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION_SAVINGS_VALUE')}
              component={FormReadOnlyText}
              componentProps={{ allowsFloat: true }}
              writeComponent={FormNumberField}
              width={12}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name={descriptionPathName}
            label={t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION_SAVINGS_DESCRIPTION')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>
      <ConcessionsContractFormEntry name="costModel.negotiated.model.concessions" width={12} />
    </Stack>
  );
};

export default SavingsBreakdownTab;
