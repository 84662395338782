import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system/src';

import { TaskRow } from '../../types';
import { Age } from '@vertice/core/src/modules/intelligentWorkflows/Age';
import { OnCardHover } from '../CardGrid';
import { useFormatDate } from '@vertice/utils/src/formatting/date';
import { GenericDateIcon } from '../../../components/icons/Icons';

export const TaskCreatedAt = ({ taskRow, short = true }: { taskRow: TaskRow; short?: boolean }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  return (
    <Stack>
      <Stack minWidth={0} direction="row" alignItems="center" gap={0.5}>
        <GenericDateIcon />
        <Text variant="body-regular" size="S" color="text1">
          {!short && t('ENTITIES.WORKFLOW_TASK.LABELS.ACTIVE_FOR') + ' '}
          <Age createdAt={taskRow.createdAt} />
        </Text>
      </Stack>
      <OnCardHover>
        <Text variant="caption" size="XS" color="text3">
          {formatDate(taskRow.createdAt)}
        </Text>
      </OnCardHover>
    </Stack>
  );
};
