import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, ChipButton } from '@verticeone/design-system/src';
import { Comments } from '@verticeone/design-system/src';
import { useConversations } from '../../useConversations';
import { UsersContextProvider } from '@vertice/core/src/contexts/UsersContext';
import { useConversationUsers } from '../../../../../../hooks/useConversationUsers';
import { useLoggedUser } from '@verticeone/auth/src';
import { getNewCommentsCount } from '@verticeone/design-system/src/components/Comments/utils';

type ConversationProps = {
  requestId: string;
};

export const ConversationSection: FC<ConversationProps> = ({ requestId }) => {
  const { userId, isIatUser, roles } = useLoggedUser();
  const { usersById, isLoadingUsers } = useConversationUsers();
  const { cursor, messages, isMessagePending, isLoading, sendMessage, removeMessage, updateCursor } =
    useConversations(requestId);
  const { t } = useTranslation();
  const newCommentsCount = getNewCommentsCount(messages, cursor!);

  return (
    <UsersContextProvider>
      <Accordion size="XS" variant="label" defaultExpanded>
        <AccordionSummary
          title={
            <Stack gap={2} direction="row" alignItems="center">
              {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.COMMENTS')}
              {newCommentsCount !== undefined && newCommentsCount > 0 && (
                <ChipButton variant="solid" size="S" color="secondary">
                  {t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.NEW', { count: newCommentsCount })}
                </ChipButton>
              )}
            </Stack>
          }
        />
        <AccordionDetails>
          <Stack height={800}>
            <Comments
              comments={messages}
              users={usersById}
              currentUser={userId}
              cursor={cursor}
              canEditComents={isIatUser || !!roles?.includes('admin')}
              onSendComment={sendMessage}
              onRemoveComment={removeMessage}
              onUpdateCursor={updateCursor}
              isLoading={isLoading || isLoadingUsers}
              isSending={isMessagePending}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </UsersContextProvider>
  );
};
