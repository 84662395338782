import React from 'react';
import { Url } from '@vertice/slices';
import { isNil } from 'lodash';
import { Link } from '@mui/material';
import type { SvgIconComponent } from '@mui/icons-material';
import { IconButton } from '@verticeone/design-system/src';
import { removeProtocolFromUrl } from '@vertice/utils/src/strings/removeProtocolFromUrl';

type IconLinkButtonProps = {
  label?: string;
  url?: Url;
  icon: SvgIconComponent;
};

export const IconLinkButton = ({ icon, label, url }: IconLinkButtonProps) => {
  if (isNil(url)) {
    return null;
  }

  return (
    <IconButton
      icon={icon}
      aria-label={label}
      size="S"
      variant="outline"
      color="neutral"
      component={Link}
      target={'_blank'}
      href={'//' + removeProtocolFromUrl(url)}
      rel="noreferrer"
      underline="none"
    />
  );
};
