import { useMemo, useRef } from 'react';
import { chain, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import { addMonths, startOfMonth } from 'date-fns';
import { getAccount } from '@vertice/slices/src/slices/account';
import { useHistoricalSpendByEc2ProcessorQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { DATE_FORMAT, getTableData } from '../../utils/graphDataUtils';
import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { AWS_DEFAULT_CURRENCY } from '../../constants';
import toConstantCase from '@vertice/utils/src/strings/toConstantCase';

const startDate = addMonths(startOfMonth(new Date()), -11);

type TableData = Array<Record<'billing_period_start' | 'processor' | 'sum_cost', string>>;

export type HistoricalSpendByEC2ProcessorData = {
  currency: string;
  series: { id: string; data: (number | null)[] }[];

  /** The list of used series in the required order */
  usedCategories: string[];
  months: string[];
};

const useHistoricalSpendByEC2ProcessorData = (): LoadableAdvanced<HistoricalSpendByEC2ProcessorData> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { accountId } = useSelector(getAccount);
  const pollingInterval = useRef(5000);

  const { data: rawData, error: dataError } = useHistoricalSpendByEc2ProcessorQuery(
    {
      accountId: accountId!,
      billingPeriodStart: format(startDate, DATE_FORMAT),
    },
    {
      skip: !accountId,
      pollingInterval: pollingInterval.current,
      selectFromResult: (result) => {
        if (result?.data?.athenaViewQuery?.__typename === 'DataTableResult' && result.data.athenaViewQuery.table) {
          pollingInterval.current = 0;
          return {
            ...result,
            data: getTableData(result.data.athenaViewQuery.table) as TableData,
          };
        }
        return { ...result, data: undefined };
      },
    }
  );

  const computed = useMemo(() => {
    if (!rawData) {
      return undefined;
    }

    const allMonthsData = chain(rawData)
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.billing_period_start]: {
            ...(acc[item.billing_period_start] ?? {}),
            [item.processor]: parseFloat(item.sum_cost),
          },
        }),
        {} as Record<string, Record<string, number>>
      )
      .value();

    const allProcessorTypes = uniq(rawData.map(({ processor }) => processor));

    return {
      series: allProcessorTypes.map((processor) => ({
        id: processor,
        name: t(`EC2_PROCESSOR_USAGE.PROCESSORS.${toConstantCase(processor)}`) ?? processor,
        data: Object.keys(allMonthsData).map((time) => allMonthsData[time][processor] ?? null),
      })),
      usedCategories: allProcessorTypes,
      months: Object.keys(allMonthsData),
      currency: AWS_DEFAULT_CURRENCY,
    };
  }, [rawData, t]);

  return {
    error: dataError,
    isEmpty: Boolean(rawData && (rawData as any).length === 0),
    isLoading: !computed,
    data: computed,
  };
};

export default useHistoricalSpendByEC2ProcessorData;
