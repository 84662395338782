import React, { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system/src';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Placeholder } from '@verticeone/design-system/src';
import { useRoutes } from '@verticeone/router/src';
import { useAccountContext } from '../../../contexts/AccountContext';
import { Account, useGetAccountQuery } from '@vertice/slices';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { VerticeVIcon as VerticeSmallIcon } from '@vertice/assets';
import { WORKFLOW_ADMIN_ACCOUNT_ID } from '../constants';

type IntelligentWorkflowsBreadcrumbsProps = {
  currentPageName?: string;
  isLoading?: boolean;
  parentPageName?: string;
  parentPagePath?: string;
  parentPageAccountId?: string;
};

export const IntelligentWorkflowsBreadcrumbs: FC<IntelligentWorkflowsBreadcrumbsProps> = (props) => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation();
  const paths = useRoutes();

  const isDifferentAccount = props.parentPageAccountId && props.parentPageAccountId !== accountId;
  const isWfAdminAccount = accountId === WORKFLOW_ADMIN_ACCOUNT_ID;

  const { data: parentAccountData } = useGetAccountQuery(
    { accountId: props.parentPageAccountId! },
    { skip: !props.parentPageAccountId || !isDifferentAccount }
  );

  const currentPath = window.location.pathname;
  const RootBreadcrumbLink = (['WORKFLOWS', 'TASKS', 'REQUESTS'] as const).map((link) => {
    return (
      currentPath.startsWith(paths.INTELLIGENT_WORKFLOWS[link].ABSOLUTE_PATH) && (
        <BreadcrumbLink
          to={props.parentPageName ? '../..' : '..'}
          {...(props.currentPageName ? { component: RouterLink } : {})}
          key={link}
        >
          {t(`INTELLIGENT_WORKFLOWS.NAVBAR.${link}`)}
        </BreadcrumbLink>
      )
    );
  });

  return (
    <Breadcrumbs variant="slash" size="S">
      <BreadcrumbLink>
        {isDifferentAccount ? (parentAccountData as Account)?.name : t('INTELLIGENT_WORKFLOWS.NAVBAR.GROUP_TITLE')}
      </BreadcrumbLink>
      {RootBreadcrumbLink}
      {(props.parentPageName || props.isLoading) && (
        <BreadcrumbLink
          component={RouterLink}
          to={props.parentPagePath || '..'}
          target={isDifferentAccount ? '_blank' : '_self'}
        >
          {props.isLoading ? <Placeholder width={100} /> : props.parentPageName}
        </BreadcrumbLink>
      )}
      {isDifferentAccount && isWfAdminAccount && (
        <BreadcrumbLink>
          <VerticeSmallIcon />
        </BreadcrumbLink>
      )}
      {(props.currentPageName || props.isLoading) && (
        <BreadcrumbLink>{props.isLoading ? <Placeholder width={100} /> : props.currentPageName}</BreadcrumbLink>
      )}
    </Breadcrumbs>
  );
};

type IntelligentWorkflowsLayoutProps = {
  heading?: ReactNode;
  children: ReactNode;
  currentPageName?: string;
  parentPageName?: string;
  parentPagePath?: string;
  parentPageAccountId?: string;
  isLoading?: boolean;
  actions?: ReactNode;
};

export const IntelligentWorkflowsLayout: FC<IntelligentWorkflowsLayoutProps> = ({
  heading,
  children,
  currentPageName,
  parentPageName,
  parentPagePath,
  parentPageAccountId,
  actions,
  isLoading,
}) => {
  return (
    <Stack padding={10} gap={8} width="100%">
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={2}>
          <IntelligentWorkflowsBreadcrumbs
            currentPageName={currentPageName}
            parentPageName={parentPageName}
            parentPagePath={parentPagePath}
            parentPageAccountId={parentPageAccountId}
            isLoading={isLoading}
          />
          {!isLoading ? (
            <Text variant="heading" size="M">
              {heading}
            </Text>
          ) : (
            <Placeholder width={200} height={30} />
          )}
        </Stack>
        {actions}
      </Stack>

      <Stack>{children}</Stack>
    </Stack>
  );
};
