import React, { useMemo } from 'react';
import isNil from 'lodash/isNil';
import { Box, CircularProgress, Grid } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderDescription,
  CardHeaderSubtitle,
  CardHeaderTitle,
  CardInfoBox,
} from '@verticeone/design-system/src/components/Card';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { ChipButton } from '@verticeone/design-system/src';
import { Alert } from '@verticeone/design-system/src';
import { Text } from '@verticeone/design-system/src';
import Stack from '@mui/material/Stack';
import { DesignSystemSize } from '@verticeone/design-system/src';
import { useGetAccountIntegrationsQuery } from '@vertice/slices';
import { useProviderQuery } from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { getContractInvoicesDateBoundaries } from './getContractInvoicesDateBoundaries';
import { StatsBar, StatsBody, StatsContainer, StatsTile, Title, Value } from '../../components/StatsBar';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { Trans, useTranslation } from 'react-i18next';
import { InvoicesTable } from './Components/InvoicesTable';
import { Divider } from '../../components/Dialog/Components';
import { getViewModel } from './getViewModel';
import { sumBy } from 'lodash';
import { useExchangeCurrency } from '@vertice/core/src/modules/currency/useExchangeCurrency';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { useLocaleContext } from '../../contexts/LocaleContext';
import { useAccountContext } from '../../contexts/AccountContext';
import { useTransactionsV2 } from './useTransactionsV2';
import { useCurrentContractProperties } from './useCurrentContractProperties';
import { makePriceValue } from './utils';

export const ContractInvoices: React.FC = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { locale } = useLocaleContext();

  const contractProperties = useCurrentContractProperties();

  const dateBoundaries = getContractInvoicesDateBoundaries(contractProperties, t, locale);
  const formatCurrency = useFormatCurrency();
  const cardComponentsSize: DesignSystemSize = 'S';

  const { data: integrations } = useGetAccountIntegrationsQuery({ accountId });
  const { data: accountingSimulator } = useProviderQuery({ accountId, providerId: 'Provider:AccountingSimulator' });

  const hasActiveAccountingIntegration = useMemo(
    () =>
      integrations?.some(
        (integration) => integration.status === 'ACTIVE' && integration.id.includes('Provider:Merge:Accounting')
      ) || accountingSimulator?.provider?.status === 'ACTIVE',
    [integrations, accountingSimulator]
  );

  const { transactions, isLoadingTransactions } = useTransactionsV2(
    { accountId, ...contractProperties! },
    { skip: !hasActiveAccountingIntegration || !contractProperties }
  );

  const { exchangeCurrency } = useExchangeCurrency();

  const viewModel = useMemo(
    () =>
      transactions !== undefined && transactions.customerTransactionsByContract !== undefined
        ? getViewModel(transactions.customerTransactionsByContract)
        : undefined,
    [transactions]
  );

  const contractCurrency = contractProperties?.currency ?? DEFAULT_CURRENCY;
  const hasOtherThanContractCurrency = useMemo(
    () => viewModel?.invoices.some((invoice) => invoice.currency !== contractCurrency),
    [contractCurrency, viewModel?.invoices]
  );

  const totalInvoicedValue = useMemo(() => {
    return sumBy(viewModel?.invoices, (invoice) => {
      return invoice.netAmount
        ? exchangeCurrency({
            value: invoice.netAmount,
            sourceCurrency: invoice.currency ?? DEFAULT_CURRENCY,
            targetCurrency: contractCurrency,
          }) || 0
        : 0;
    });
  }, [contractCurrency, exchangeCurrency, viewModel?.invoices]);

  const annualCostFormatted =
    !isNil(contractProperties) && !isNil(contractProperties?.annualCost)
      ? formatCurrency(contractProperties.annualCost, {
          currency: contractCurrency,
          maximumFractionDigits: 0,
        })
      : '-';

  const totalContractValue =
    !isNil(contractProperties?.contractLength) && !isNil(contractProperties?.annualCost)
      ? makePriceValue(
          `${contractProperties?.contractLength}`,
          `${contractProperties?.annualCost}`,
          contractProperties?.currency ?? DEFAULT_CURRENCY,
          locale,
          0
        )
      : '-';

  const translateProvider = (provider?: string) => {
    if (provider === 'AccountingSimulator') {
      return 'Xero';
    }

    return provider;
  };

  if (hasActiveAccountingIntegration !== true || viewModel?.invoices.length === 0) {
    return null;
  }

  if (isLoadingTransactions) {
    return (
      <Stack width="100%" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Card>
      <CardHeader size={cardComponentsSize} noDivider>
        <CardHeaderTitle
          text={t('MODULES.CONTRACT_INVOICES.TITLE')}
          after={
            viewModel?.provider && (
              <ChipButton color={'neutral'} variant={'ghost'} size={cardComponentsSize} isActive={false}>
                {t('MODULES.CONTRACT_INVOICES.FROM_PROVIDER', { provider: translateProvider(viewModel?.provider) })}
              </ChipButton>
            )
          }
        />
        <CardHeaderSubtitle
          text={`${dateBoundaries.from.date} - ${dateBoundaries.to.date}`}
          textDimmed={
            contractProperties?.billingFrequency
              ? `${t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}: ${t(
                  `ENTITIES.CONTRACT.LABELS.FREQUENCY_${contractProperties?.billingFrequency}`
                )}`
              : undefined
          }
          icon={CalendarTodayOutlined}
        />
        <CardHeaderDescription>
          {t('MODULES.CONTRACT_INVOICES.DESCRIPTION', {
            fromDate: dateBoundaries.from.name,
            toDate: dateBoundaries.to.name,
          })}
        </CardHeaderDescription>
        {contractProperties?.billingFrequency && (
          <CardHeaderActions>
            <CardInfoBox
              size={cardComponentsSize}
              tooltip={{
                content: t('MODULES.CONTRACT_INVOICES.HEADER_TOOLTIP'),
              }}
            />
          </CardHeaderActions>
        )}
      </CardHeader>

      <Stack padding={3}>
        <StatsBar>
          <StatsContainer flex={1}>
            <StatsTile>
              <Grid container>
                <Grid item xs={6}>
                  {contractProperties?.isRolling ? (
                    <StatsBody
                      isLoading={false}
                      title={
                        <Title tooltip={{ content: t('MODULES.CONTRACT_INVOICES.ANNUAL_COST_TOOLTIP') }}>
                          {t('ENTITIES.CONTRACT.LABELS.ANNUAL_COST')}
                        </Title>
                      }
                      value={<Value>{annualCostFormatted}</Value>}
                    />
                  ) : (
                    <StatsBody
                      isLoading={false}
                      title={
                        <Title tooltip={{ content: t('MODULES.CONTRACT_INVOICES.TOTAL_CONTRACTED_VALUE_TOOLTIP') }}>
                          {t('MODULES.CONTRACT_INVOICES.TOTAL_CONTRACTED_VALUE')}
                        </Title>
                      }
                      value={<Value>{totalContractValue}</Value>}
                    />
                  )}
                </Grid>

                <Grid item xs={6}>
                  <StatsBody
                    isLoading={false}
                    title={
                      <Title
                        tooltip={{ content: <Trans i18nKey="MODULES.CONTRACT_INVOICES.INVOICED_AMOUNT_TOOLTIP" /> }}
                      >
                        {t('MODULES.CONTRACT_INVOICES.INVOICED_AMOUNT')}
                      </Title>
                    }
                    value={
                      <Value>
                        {formatCurrency(totalInvoicedValue, { currency: contractCurrency, maximumFractionDigits: 0 })}
                      </Value>
                    }
                  />
                </Grid>
              </Grid>
            </StatsTile>
          </StatsContainer>
        </StatsBar>
      </Stack>
      {hasOtherThanContractCurrency && (
        <Stack padding={3} paddingTop={0}>
          <Alert
            icon={WarningAmberOutlined}
            color="warning"
            size="S"
            subtitle={t('MODULES.CONTRACT_INVOICES.CURRENCY_CONVERSION_WARNING')}
          ></Alert>
        </Stack>
      )}
      <Divider />
      {viewModel !== undefined ? (
        <Box
          sx={{
            maxHeight: 500,
            overflowY: 'auto',
          }}
        >
          <InvoicesTable rows={viewModel.invoices} />
        </Box>
      ) : null}
      <Divider />
      <Stack direction="row-reverse" paddingX={6} paddingY={3} alignItems="center" gap={4}>
        <Text variant="body-bold" size="M" color="text1">
          {formatCurrency(totalInvoicedValue, { currency: contractCurrency, maximumFractionDigits: 2 })}
        </Text>
        <Text variant="label" size="XS" color="text3">
          {t('MODULES.CONTRACT_INVOICES.TOTAL_AMOUNT')}
        </Text>
      </Stack>
    </Card>
  );
};
