import { useEffect, useState } from 'react';
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export type SuccessResult<T> = {
  result?: T;
};

export type DeferredQueryResult = {
  __typename?: 'DeferredQueryResult';
};

export type ErroredQueryResult = {
  __typename?: 'ErroredQueryResult';
  error: string;
};

export type UniversalQueryResult<T> = DeferredQueryResult | ErroredQueryResult | SuccessResult<T>;

const isSuccessResult = <T>(queryResult?: UniversalQueryResult<T>): queryResult is SuccessResult<T> =>
  Boolean((queryResult as SuccessResult<T> | undefined)?.result);

const isErroredResult = <T>(queryResult?: UniversalQueryResult<T>): queryResult is ErroredQueryResult =>
  Boolean((queryResult as ErroredQueryResult | undefined)?.error);

export type HookResult<T> = {
  data?: T;
  isLoading: boolean;
  isFetching: boolean;
  error?: ErrorResponse | SerializedError | FetchBaseQueryError;
};

/**
 * Wrapper that allows to handle deferred long-running BE tasks with polling.
 *
 * - `type` is automatically internally changed to `deferred` when doing the subsequent poll queries
 * - `pollingInterval` is used when waiting for the result from BE. After the first valid value is returned, it doesn't
 *   poll anymore. This is different to RTK Query behavior which polls forever.
 *
 * See example
 * @example
 * const { data: dataPoints, isLoading } = useDeferredQuery(
 *   useTotalCostQuery,
 *   {
 *     accountId: accountId!,
 *     startDate,
 *     type: 'timeSeries',
 *   },
 *   { skip: !accountId, pollingInterval: 5000 },
 *   ({ timeSeriesQuery }) => timeSeriesQuery
 * );
 */
const useDeferredQuery = <HookParams, HookAdditional, HookResultQueryData, HookResultData>(
  useQueryHook: (props: HookParams, additional: HookAdditional) => HookResult<HookResultData>,
  params: HookParams,
  additional: HookAdditional & { pollingInterval: number },
  getResultQueryData: (resultData: HookResultData) => UniversalQueryResult<HookResultQueryData> | undefined
): HookResult<HookResultQueryData> => {
  const [fetchedData, setFetchedData] = useState<HookResultQueryData>();
  const [error, setError] = useState<ErrorResponse | SerializedError | FetchBaseQueryError>();
  const queryResult = useQueryHook(params, {
    ...additional,
    pollingInterval: fetchedData || error ? undefined : additional.pollingInterval,
  });

  useEffect(() => {
    if (queryResult.error) {
      setError(queryResult.error);
    }

    if (!queryResult.data || queryResult.isFetching) {
      setFetchedData(undefined);
      return;
    }

    const queryData = getResultQueryData(queryResult.data);
    if (isSuccessResult(queryData)) {
      setFetchedData(queryData.result);
    }
    if (isErroredResult(queryData)) {
      setError({ message: queryData.error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.data, queryResult.error, queryResult.isFetching]);

  return {
    isLoading: !fetchedData && !error,
    isFetching: queryResult.isFetching,
    error: error,
    data: fetchedData,
  };
};

export default useDeferredQuery;
