import { Contract, ContractOrigin, SimplifiedStage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractFormData, WorkflowStage } from './types';
import assertExhausted from '@vertice/utils/src/assertExhausted';
import { getFeDeadline } from './components/Deadline/utils';
import dayjs from 'dayjs';

// WORKFLOW STAGES
// TODO: needs to be changed when we implement stages properly
export const isContractInProgress = (contract: Contract) => {
  const inProgressStages: WorkflowStage[] = ['REQUIREMENTS_GATHERING', 'NEGOTIATING', 'APPROVAL', 'CONTRACTING'];
  return contract.workflow?.stage ? inProgressStages.includes(contract.workflow?.stage as WorkflowStage) : false;
};

export const isContractRequirementsGathering = (contract: Contract) =>
  contract.workflow?.stage ? contract.workflow.stage === 'REQUIREMENTS_GATHERING' : false;

export const isContractNegotiatingApprovalContracting = (contract: Contract) =>
  contract.workflow?.stage ? ['NEGOTIATING', 'APPROVAL', 'CONTRACTING'].includes(contract.workflow.stage) : false;

// TODO: needs to be fixed together with RenewalsPanels which take into account only primary contract but not next contract
export const isContractRenewalInProgress = (contract: Contract) => isContractInProgress(contract);

export const isInWorkflowStages = (contract: Contract | ContractFormData, stages: WorkflowStage[]) =>
  contract.workflow?.stage ? stages.includes(contract.workflow?.stage as WorkflowStage) : false;

// LIFECYCLE
export const isRollingContract = (contract: Contract | ContractFormData) => {
  const rollingFrequency = contract.parts?.contractual?.lifecycle?.rollFrequency ?? 'NO';
  return rollingFrequency !== 'NO';
};
export const isFixedTermContract = (contract: Contract | ContractFormData) => !isRollingContract(contract);

export const isPlannedForExpiration = (contract: Contract) =>
  !!contract.parts.contractual?.renewal?.markedForExpiration;

export const getStartedMonthsBetweenDates = (startDate?: string, renewalDate?: string) => {
  if (!startDate || !renewalDate) {
    return undefined;
  }
  return Math.ceil(dayjs(renewalDate).diff(dayjs(startDate), 'month', true));
};

// DEADLINE
export const hasDeadlineWithinDays = (contract: Contract, days: number) => {
  const deadline = getFeDeadline(contract)?.deadlineDate;
  const deadlineDiffDays = deadline ? dayjs(deadline).startOf('day').diff(dayjs().startOf('day'), 'days') : null;

  return deadlineDiffDays ? deadlineDiffDays >= 0 && deadlineDiffDays <= days : false;
};

// FINANCIAL
export const isContractWithOtherBillingFrequency = (contract: Contract | ContractFormData) => {
  const billingFrequency = contract?.parts?.contractual?.financial?.billingFrequency;
  return billingFrequency === 'OTHER';
};

export const isContractWithOtherPaymentTerms = (contract: Contract | ContractFormData) => {
  const paymentTerms = contract?.parts?.contractual?.financial?.paymentTerms;
  return paymentTerms === 'OTHER';
};

// CONTRACT ORIGIN
const contractHasOriginFn = (origin: ContractOrigin[]) => (contract: Contract | ContractFormData) =>
  !!contract.record.contractOrigin && origin.includes(contract.record.contractOrigin);

export const isContractExisting = contractHasOriginFn(['EXISTING']);
export const isContractPurchase = contractHasOriginFn(['PURCHASE_WITH_VERTICE', 'PURCHASE_WITHOUT_VERTICE']);
export const isContractRenewal = contractHasOriginFn(['RENEWAL_WITH_VERTICE', 'RENEWAL_WITHOUT_VERTICE']);
export const isContractRenewalWithoutVertice = contractHasOriginFn(['RENEWAL_WITHOUT_VERTICE']);
export const contractOriginsWithoutVertice: ContractOrigin[] = [
  'EXISTING',
  'PURCHASE_WITHOUT_VERTICE',
  'RENEWAL_WITHOUT_VERTICE',
];
export const isContractWithoutVertice = contractHasOriginFn(contractOriginsWithoutVertice);
export const isContractWithVertice = contractHasOriginFn(['PURCHASE_WITH_VERTICE', 'RENEWAL_WITH_VERTICE']);

export const isContractManagedBy = (contract: Contract) => contract?.record.managedBy !== null;

export const canContractBeRenewed = (contract: Contract) => {
  if (!contract.materialized?.simplifiedStage) {
    return false;
  }

  const isRenewableStage = (() => {
    switch (contract.materialized.simplifiedStage) {
      case 'DRAFT':
      case 'NOT_YET_LIVE':
      case 'LIVE':
      case 'EXPIRED_NATURAL':
      case 'EXPIRED_PLANNED':
      case 'EXPIRED_UNINTENTIONAL':
      case 'TERMINATED':
        return true;
      case 'WORKFLOW_IN_PROGRESS':
      case 'CANCELLED_FIXED_TERM':
      case 'CANCELLED_REQUEST':
      case 'DELETED':
        return false;
      default:
        assertExhausted(contract.materialized.simplifiedStage);
    }
  })();

  return isRenewableStage && !contractHasNext(contract) && !isContractRenewalInProgress(contract);
};

export const canContractBeDeleted = (contract: Contract) =>
  (isContractExisting(contract) || isContractRenewalWithoutVertice(contract)) &&
  !contractHasAnyFollower(contract) &&
  !isDeletedContract(contract);

export const hasCompletedNonCancelledRenewal = (contract: Contract) => {
  const nextContract = contract.materialized?.next;
  return (
    !!nextContract?.simplifiedStage &&
    Array<SimplifiedStage>(
      'LIVE',
      'NOT_YET_LIVE',
      'EXPIRED_NATURAL',
      'EXPIRED_PLANNED',
      'EXPIRED_UNINTENTIONAL',
      'DRAFT',
      'TERMINATED'
    ).includes(nextContract.simplifiedStage)
  );
};

// CONTRACT SIMPLIFIED STAGE
export const isContractInStagesFn = (stages: NonNullable<SimplifiedStage>[]) => (contract: Contract) =>
  !!contract.materialized?.simplifiedStage && stages.includes(contract.materialized?.simplifiedStage);

export const isDraftContract = isContractInStagesFn(['DRAFT']);
export const isLiveContract = isContractInStagesFn(['LIVE']);
export const isContractLiveOrNotYetLive = isContractInStagesFn(['LIVE', 'NOT_YET_LIVE']);
export const isContractWorkflowInProgress = isContractInStagesFn(['WORKFLOW_IN_PROGRESS']);
export const isCancelledFixedTermContract = isContractInStagesFn(['CANCELLED_FIXED_TERM']);
export const isCancelledRequestContract = isContractInStagesFn(['CANCELLED_REQUEST']);
export const isCancelledContract = isContractInStagesFn(['CANCELLED_FIXED_TERM', 'CANCELLED_REQUEST']);
export const isExpiredContract = isContractInStagesFn(['EXPIRED_NATURAL', 'EXPIRED_PLANNED', 'EXPIRED_UNINTENTIONAL']);
export const isDeletedContract = isContractInStagesFn(['DELETED']);
export const isTerminatedContract = isContractInStagesFn(['TERMINATED']);
export const isContractCompleted = isContractInStagesFn([
  'LIVE',
  'NOT_YET_LIVE',
  'EXPIRED_NATURAL',
  'EXPIRED_PLANNED',
  'EXPIRED_UNINTENTIONAL',
  'TERMINATED',
  'CANCELLED_FIXED_TERM',
]);
export const isContractDraftLiveNotLiveOrExpired = isContractInStagesFn([
  'DRAFT',
  'LIVE',
  'NOT_YET_LIVE',
  'EXPIRED_NATURAL',
  'EXPIRED_PLANNED',
  'EXPIRED_UNINTENTIONAL',
]);

// CONTRACT LINEAGE
// Returns true if there is non-cancelled follower
export const contractHasNext = (contract: Contract): boolean => !!contract.materialized?.next;

// Returns true if there is any follower (even cancelled one)
export const contractHasAnyFollower = (contract: Contract): boolean =>
  !!contract.lineage?.find((link) => link.linkType === 'NEXT');
