import { useSelector } from 'react-redux';
import { getAccount } from '@vertice/slices/src/slices/account';
import useDeferredQuery from '@vertice/utils/src/api/useDeferredQuery';
import { usePriceListQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { useMemo } from 'react';
import { getTableData } from '../../../utils/graphDataUtils';
import { termStringifier as ts } from '../../../utils/termStringifier';
import { productToOSSWMap, ProductType } from './products';
import { ReservedInstance } from '../useRIUtilizationData';

export type EC2PriceList = {
  region: string;
  instanceFamily: string;
  size: string;
  operatingSystem: string;
  onDemandPrice: number;
  license: string;
  preInstalledSoftware: string | null;
}[];

type InstanceFamilyFilterItem = {
  name: string;
  sizes: string[];
};

type RegionFilterItem = {
  region: string;
  instanceFamilies: InstanceFamilyFilterItem[];
};

export type Filter = RegionFilterItem[];

type EC2PriceListLazyQueryProps = {
  filter?: Filter;
};

export const stringifyInstanceFamilyFilterItem = ({ name, sizes }: InstanceFamilyFilterItem) => {
  // prettier-ignore
  return ts.and([
    ts.eq('instance_family', name),
    ts.or(sizes.map((size) => ts.eq('instance_size', size)))
  ]);
};

export const stringifyFilterItem = ({ region, instanceFamilies }: RegionFilterItem) => {
  // prettier-ignore
  return ts.and([
    ts.eq('region', region),
    ts.or(instanceFamilies.map(stringifyInstanceFamilyFilterItem))
  ]);
};

export const stringifyFilter = (filter?: Filter) => {
  return filter ? ts.or(filter.map(stringifyFilterItem)) : undefined;
};

const SUPPORTED_LICENSE_MODEL = 'nlr';

const licenseModelCondition = ts.eq('license_model', SUPPORTED_LICENSE_MODEL);

export const enhanceDataWithOnDemandPrice = (preprocessedData: ReservedInstance[], priceList: EC2PriceList) => {
  return preprocessedData.map((item) => {
    const { operatingSystem, preInstalledSW } = productToOSSWMap[item.product as ProductType] ?? {};
    const priceListItem = priceList.find((priceItem) => {
      return (
        item.region === priceItem.region &&
        item.instanceFamily === priceItem.instanceFamily &&
        item.size === priceItem.size &&
        operatingSystem === priceItem.operatingSystem &&
        preInstalledSW === priceItem.preInstalledSoftware
      );
    });

    return {
      ...item,
      hourlyOnDemandRate: priceListItem?.onDemandPrice,
    };
  });
};

const useEC2PriceList = ({ filter }: EC2PriceListLazyQueryProps): LoadableAdvanced<EC2PriceList> => {
  const { accountId } = useSelector(getAccount);
  const stringifiedFilter = useMemo(() => stringifyFilter(filter), [filter]);
  const skip = !accountId || !stringifiedFilter;

  const { data, error, isLoading } = useDeferredQuery(
    usePriceListQuery,
    {
      accountId: accountId!,
      pricelist: 'aws_pricing_ec2',
      filter: ts.and([licenseModelCondition, stringifiedFilter]),
    },
    { skip: skip, pollingInterval: 5000 },
    ({ priceListQuery }) => priceListQuery
  );

  const pricelistData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return getTableData(data, {
      region: 'region',
      instance_family: 'instanceFamily',
      instance_size: 'size',
      operating_system: 'operatingSystem',
      on_demand_hours: 'onDemandPrice',
      license_model: 'license',
      pre_installed_sw: 'preInstalledSoftware',
    }) as EC2PriceList;
  }, [data]);

  return {
    error: error,
    isEmpty: Boolean(pricelistData && pricelistData.length === 0),
    isLoading: isLoading && !skip,
    data: pricelistData,
  };
};

export default useEC2PriceList;
