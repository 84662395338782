import React from 'react';
import { formatCurrency } from '@vertice/utils/src/formatting/currency';
import { Tooltip } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { ChipButton } from '@verticeone/design-system/src';

type ChipProps = {
  value: number;
  previousValue: number;
  currency: string;
};

const Chip = ({ value, previousValue, currency }: ChipProps) => {
  const { locale } = useLocaleContext();

  if (Math.abs(previousValue) < 0.0001) {
    return null;
  }

  const formattedPreviousValue = formatCurrency(previousValue, {
    currency,
    locale,
    maximumFractionDigits: 0,
    withSpace: true,
  });

  const percentageValue = Math.round(((value - previousValue) * 100) / previousValue);
  const percentageString = (percentageValue >= 0 ? '+' : '') + percentageValue + '%';

  // eslint-disable-next-line no-nested-ternary
  const chipVariant = percentageValue === 0 ? 'info' : percentageValue > 0 ? 'error' : 'success';

  return (
    <Tooltip title={formattedPreviousValue}>
      <ChipButton variant="ghost" size="S" color={chipVariant}>
        {percentageString}
      </ChipButton>
    </Tooltip>
  );
};

export default Chip;
