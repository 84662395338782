import { JournalItem as BackendJournalItem } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { isNotNil } from '@vertice/utils/src/validation/isNotNil';
import { isUserRef, parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { RequestJournalItem, RequestJournalItemType } from './types';

type JournalItemPattern = {
  change?: any;
  intent: string;
  resultType: RequestJournalItemType;
};

const INTENT_REQUEST_STATUS_CHANGE = 'Services:Request:StatusChange';
const INTENT_REQUEST_OWNER_CHANGE = 'Services:Request:OwnerChange';
const INTENT_TASK_STATUS_CHANGE = 'Workflows:Task:StatusChange';
const INTENT_SERVICE_STATUS_CHANGE = 'Workflows:Service:StatusChange';
const INTENT_TASK_ASSIGNMENT_CHANGE = 'Workflows:Task:AssignmentChange';

const journalItemPatterns: JournalItemPattern[] = [
  {
    change: ['change', 'Status', ['PENDING', 'ACTIVE']],
    intent: INTENT_REQUEST_STATUS_CHANGE,
    resultType: 'requestCreated',
  },
  {
    change: ['change', 'Status', ['ACTIVE', 'TERMINATED']],
    intent: INTENT_REQUEST_STATUS_CHANGE,
    resultType: 'requestTerminated',
  },
  {
    change: ['change', 'Status', ['ACTIVE', 'COMPLETED']],
    intent: INTENT_REQUEST_STATUS_CHANGE,
    resultType: 'requestCompleted',
  },
  {
    intent: INTENT_REQUEST_OWNER_CHANGE,
    resultType: 'requestOwnerChanged',
  },
  {
    change: ['change', 'Status', ['ACTIVE', 'COMPLETED']],
    intent: INTENT_TASK_STATUS_CHANGE,
    resultType: 'taskCompleted',
  },
  {
    change: ['change', 'Status', ['ACTIVE', 'COMPLETED']],
    intent: INTENT_SERVICE_STATUS_CHANGE,
    resultType: 'serviceCompleted',
  },
  {
    change: ['change', 'Status', ['ACTIVE', 'COMPLETED']],
    intent: INTENT_TASK_STATUS_CHANGE,
    resultType: 'taskCompleted',
  },
  {
    intent: INTENT_TASK_ASSIGNMENT_CHANGE,
    resultType: 'taskAssignmentChanged',
  },
];

export const getRequestJournalItems = (backendItems: BackendJournalItem[]): RequestJournalItem[] => {
  return backendItems
    .map((item) => {
      const matchingPattern = journalItemPatterns.find((pattern) => {
        if (!item.intents.includes(pattern.intent)) {
          return false;
        }

        const deltaString = item.delta.replaceAll(/\s/g, '');
        const patternDeltaString = JSON.stringify(pattern.change);
        return !pattern.change || deltaString.includes(patternDeltaString);
      });

      const userId = item.actor && isUserRef(item.actor) ? parseUserRef(item.actor).userId : undefined;

      if (!matchingPattern) {
        return undefined;
      }

      let delta: any;
      try {
        const deltaTemp = JSON.parse(item.delta);
        if (Array.isArray(deltaTemp)) {
          delta = deltaTemp;
        }
      } catch (_e) {}

      return {
        userId,
        timestamp: item.timestamp,
        taskId: item.taskId,
        type: matchingPattern.resultType,
        delta,
      };
    }, [])
    .filter(isNotNil);
};
