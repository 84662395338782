import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { StatsBar, StatsBody, StatsContainer, StatsTile, Title, Value } from '@vertice/core/src/components/StatsBar';
import { ChipButton } from '@verticeone/design-system/src';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { useFormatPercentage } from '@vertice/utils/src/formatting/percentage';
import { useInstanceData } from './providers/InstanceProvider';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

const Legend = styled((props) => <Text {...props} variant="caption" size="XS" color="text2" />)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  left: '18px',
  top: '-5px',
  background: theme.palette.core.bg,
  padding: '0 8px',
}));

type InstanceStatsProps = {
  customStatsBody?: React.ReactNode;
};

const InstanceStats = ({ customStatsBody }: InstanceStatsProps) => {
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.RI_STATS' });
  const { item, managedByVertice } = useInstanceData();
  const isOptimized = !item.isOverCommitted && !item.isUnderCommitted;

  const formatRange = (from: number, to: number, formatFn: (value: number) => string) => {
    return from === to ? formatFn(from) : `${formatFn(from)} - ${formatFn(to)}`;
  };

  const currencyFormatter = (value: number) =>
    formatCurrency(value, { currency: AWS_DEFAULT_CURRENCY, maximumFractionDigits: 0 });
  const percentageFormatter = (value: number) => formatPercentage(value, { maximumFractionDigits: 0 });

  const isPositiveSavings = item.savingsCostTo > 0;

  return (
    <StatsContainer>
      <Legend>{t('LEGEND')}</Legend>
      <StatsBar>
        <StatsContainer flex={1}>
          <StatsTile>
            <StatsBody
              isLoading={false}
              data-testid="estimated_savings"
              title={
                <Title
                  testId="label"
                  tooltip={{ title: t('SAVINGS.TOOLTIP.TITLE'), content: t('SAVINGS.TOOLTIP.CONTENT') }}
                >
                  {t('SAVINGS.TITLE')}
                </Title>
              }
              value={
                <Stack direction="row" gap={1} alignItems="center">
                  <Value color={isPositiveSavings ? 'success' : 'text'}>
                    {formatRange(item.savingsCostFrom, item.savingsCostTo, currencyFormatter)}
                  </Value>
                  <Text
                    testId="percentage"
                    variant="body-bold"
                    size="M"
                    color={isPositiveSavings ? 'success1' : 'text1'}
                    sx={{ opacity: 0.5 }}
                  >
                    ({formatRange(item.savingsPercentFrom, item.savingsPercentTo, percentageFormatter)})
                  </Text>
                </Stack>
              }
            />
          </StatsTile>
        </StatsContainer>
        <StatsContainer flex={1}>
          <StatsTile>
            <StatsBody
              isLoading={false}
              data-testid="resource_cost"
              title={
                <Title
                  testId="label"
                  tooltip={{
                    title: t('COST.TOOLTIP.TITLE'),
                    content: t('COST.TOOLTIP.CONTENT'),
                  }}
                >
                  {t('COST.TITLE')}
                </Title>
              }
              value={
                <Value>
                  {formatCurrency(item.totalCost, { currency: AWS_DEFAULT_CURRENCY, maximumFractionDigits: 0 })}
                </Value>
              }
            />
          </StatsTile>
        </StatsContainer>
        <StatsContainer flex={1}>
          <StatsTile>
            <StatsBody
              isLoading={false}
              data-testid="ri_coverage"
              title={
                <Title
                  testId="label"
                  tooltip={{
                    title: t('RI_COVERAGE.TOOLTIP.TITLE'),
                    content: t('RI_COVERAGE.TOOLTIP.CONTENT'),
                  }}
                >
                  {t('RI_COVERAGE.TITLE')}
                </Title>
              }
              value={<Value color={isOptimized ? 'success' : 'neutral'}>{formatPercentage(item.riCoverage)}</Value>}
              badge={
                <ChipButton
                  isActive={managedByVertice.isActive}
                  color={isOptimized ? 'success' : 'warning'}
                  variant="ghost"
                  size="S"
                  testId="chip"
                >
                  {item.isOverCommitted && t('RI_COVERAGE.CHIP.OVER_COMMITTED')}
                  {item.isUnderCommitted && t('RI_COVERAGE.CHIP.UNDER_COMMITTED')}
                  {isOptimized && t('RI_COVERAGE.CHIP.ON_TARGET')}
                </ChipButton>
              }
            />
          </StatsTile>
        </StatsContainer>
        {customStatsBody && (
          <StatsContainer flex={1}>
            <StatsTile>{customStatsBody}</StatsTile>
          </StatsContainer>
        )}
      </StatsBar>
    </StatsContainer>
  );
};

export default InstanceStats;
