import { accountAPICodegen } from '@vertice/slices';

const ACCOUNT_USERS_TAG = 'AccountUsers';

export const enhancedAccountAPI = accountAPICodegen.enhanceEndpoints<typeof ACCOUNT_USERS_TAG>({
  addTagTypes: [ACCOUNT_USERS_TAG],
  endpoints: {
    listAccountUsers: {
      providesTags: [ACCOUNT_USERS_TAG],
    },
    createNewAccountUser: {
      invalidatesTags: [ACCOUNT_USERS_TAG],
    },
    migrateUserSso: {
      invalidatesTags: [ACCOUNT_USERS_TAG],
    },
    refreshUserInvitation: {
      invalidatesTags: [ACCOUNT_USERS_TAG],
    },
  },
});
