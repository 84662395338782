import { FC } from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary } from '@verticeone/design-system/src';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useListServiceCatalogQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';
import { createServiceIdentifier } from '@vertice/core/src/hooks/workflows/refUtils';
import { WorkflowsTable } from './WorkflowsTable';
import { ServiceSummary } from './ServiceSummary';
import { WorkflowsTableSkeleton } from './WorkflowsTableSkeleton';
import { useServiceWorkflows } from './useServiceWorkflows';
import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';

export const ServiceDetailPage: FC = () => {
  const { t } = useTranslation();
  const params = useParams() as { serviceId: string };

  const { accountId } = useAccountContext();
  const { data: serviceCatalogData, isLoading } = useListServiceCatalogQuery({
    accountId,
  });
  // find the service by the generated identifier from the URL
  const resources =
    serviceCatalogData?.items?.filter((item) => createServiceIdentifier(item.urn) === params.serviceId) || [];

  const service = resources.find(Boolean);
  const defaultWorkflowRef = service?.definition?.ServiceProvider?.HandlerUrn;

  const { workflows, isLoadingWorkflows } = useServiceWorkflows({ serviceRef: service?.urn, defaultWorkflowRef });

  if (!isLoading && service === undefined) {
    return <PageNotFound />;
  }

  return (
    <IntelligentWorkflowsLayout heading={service?.name} currentPageName={service?.name} isLoading={isLoading}>
      <Stack gap={8}>
        <ServiceSummary workflows={workflows} isLoadingWorkflows={isLoadingWorkflows} />
        <Accordion variant="label" defaultExpanded size="XS">
          <AccordionSummary
            title={t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.TABLE_HEADING', { serviceName: service?.name })}
          />
          <AccordionDetails>
            {isLoadingWorkflows ? (
              <WorkflowsTableSkeleton />
            ) : (
              <WorkflowsTable serviceRef={service?.urn} workflows={workflows} />
            )}
          </AccordionDetails>
        </Accordion>
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
