import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { Card, CardHeader, CardHeaderDescription, CardHeaderTitle, Text } from '@verticeone/design-system/src';
import { GoodFormOnSubmitCallback } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { JiraSetupStep } from './types';
import { ConnectionFormData, SetupConnectionForm } from './ConnectionForm';

type StepContentProps = {
  activeStep: JiraSetupStep;
};

const handleSubmit: GoodFormOnSubmitCallback<ConnectionFormData> = async (formData) => {
  return {
    workspaceUrl: {
      message: 'Submit failed',
    },
  };
};

export const StepContent = ({ activeStep }: StepContentProps) => {
  const { t } = useTranslation();

  if (activeStep === JiraSetupStep.CONNECTION) {
    return (
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle
            icon={SettingsOutlinedIcon}
            text={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.TITLE')}
          />
          <CardHeaderDescription>
            <Text variant="body-regular" size="S" color="text2">
              {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.DESCRIPTION')}
            </Text>
          </CardHeaderDescription>
        </CardHeader>
        <Stack gap={1} p={6}>
          <SetupConnectionForm
            defaultValues={{ workspaceUrl: '', administratorEmail: '', apiToken: '' }}
            onSubmit={handleSubmit}
          />
        </Stack>
      </Card>
    );
  }
};
