import { DataTableResult } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { useCloudAnalytics } from '../../CloudAnalyticsContext';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { sumBy } from 'lodash';
import dayjs from 'dayjs';
import { processTableData } from './utils';
import { Columns, DrawerAccountsData } from './types';
import { useComparisonDrawer } from './ComparisonDrawerContext';

export const getSortedData = (rawData: DataTableResult): Columns[] => {
  if (rawData?.__typename !== 'DataTableResult' || !rawData?.table) {
    return [];
  }

  const tableData = getTableData(rawData.table) as Columns[];

  return tableData.sort((a, b) => a.start_date - b.start_date);
};

const useDrawerData = (): LoadableAdvanced<DrawerAccountsData> => {
  const { period, granularity } = useCloudAnalytics();
  const { periodOneStart: periodStart, periodOneEnd: periodEnd } = period;
  const {
    instanceSettings: { query, queryParams, mappingFunction, comparisonColumn },
  } = useComparisonDrawer();

  const { data: rawData, error, isLoading } = query(queryParams);

  const computedData = useMemo(() => getSortedData(rawData as DataTableResult), [rawData]);

  const data = useMemo(() => {
    if (computedData.length > 0) {
      const tableData = processTableData(computedData, periodStart, periodEnd, granularity, comparisonColumn);

      const columnsMapping = computedData.reduce(mappingFunction, {});

      const statsData = { currentCost: sumBy(computedData, ({ cost }) => cost) || 0 };
      const usedCategories = Object.keys(tableData[0]).filter((item) => item !== 'startDate' && item !== 'totalCost');

      const gridData = {
        dates: tableData.map((item) => dayjs(Number(item.startDate)).format()),
        values: usedCategories.map((category) => ({
          id: category,
          name: columnsMapping[category].toString(),
          type: 'line',
          lineWidth: 1,
          data: tableData.map((item) => item[category as keyof typeof item] as number),
        })),
        usedCategories: usedCategories,
      };

      return {
        columnsMapping,
        statsData,
        tableData,
        gridData,
      };
    }

    return {
      columnsMapping: {},
      statsData: { currentCost: 0 },
      tableData: [],
      gridData: undefined,
    };
  }, [comparisonColumn, computedData, granularity, mappingFunction, periodEnd, periodStart]);

  return {
    error: error,
    isEmpty: Boolean(computedData && computedData.length === 0),
    isLoading,
    data,
  };
};

export default useDrawerData;
