import LoadableAdvanced from '@vertice/utils/src/async/loadableAdvanced';
import { useMemo } from 'react';
import { useSavingsPlanInventoryQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import useDeferredQuery from '@vertice/utils/src/api/useDeferredQuery';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

export type SPInventoryItem = {
  savingsPlanId: string;
  savingsPlanType: string;
  termLengthYears: number;
  accountId: string;
  paymentOption: string;
  ec2InstanceFamily: string;
  ec2Region: string;
  hourlyCommitment: number;
  totalCommitment: number;
  startDate: string;
  endDate: string;
};

type SavingsPlanInventoryDataProps = {
  spType: 'SageMaker' | 'Compute' | 'EC2Instance';
};

const useSavingsPlanInventoryData = ({
  spType,
}: SavingsPlanInventoryDataProps): LoadableAdvanced<SPInventoryItem[] | undefined> => {
  const { accountId } = useAccountContext();

  const skip = !accountId;

  const {
    data: rawData,
    error,
    isLoading,
  } = useDeferredQuery(
    useSavingsPlanInventoryQuery,
    {
      accountId: accountId!,
      spType,
    },
    { skip, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  );

  const computedData = useMemo(() => {
    if (rawData?.__typename !== 'DataTableResult' || !rawData?.table) {
      return [];
    }

    return getTableData(rawData.table, {
      savings_plan_id: 'savingsPlanId',
      savings_plan_type: 'savingsPlanType',
      term_length_years: 'termLengthYears',
      account_id: 'accountId',
      payment_option: 'paymentOption',
      ec2_instance_family: 'ec2InstanceFamily',
      ec2_region: 'ec2Region',
      hourly_commitment: 'hourlyCommitment',
      total_commitment: 'totalCommitment',
      start_date: 'startDate',
      end_date: 'endDate',
    }) as SPInventoryItem[];
  }, [rawData]);

  return {
    error,
    isEmpty: Boolean(computedData && computedData.length === 0),
    isLoading,
    data: computedData,
  };
};

export default useSavingsPlanInventoryData;
