import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system/src';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { AWS_DEFAULT_CURRENCY } from '../../../../constants';
import { useFormatPercentage } from '@vertice/utils/src/formatting/percentage';

type SavingsCellProps = {
  value: number;
  row: any;
};

const SavingsCell: React.FC<SavingsCellProps> = ({ value, row }) => {
  const formatPercentage = useFormatPercentage();
  const formatCurrency = useFormatCurrency();

  const cost = row.cost;
  // We want to prevent very small negative numbers which appear as negative zeros
  const roundedValue = Math.abs(value) < 0.5 ? 0 : value;
  const formattedSavings = formatCurrency(roundedValue, {
    currency: AWS_DEFAULT_CURRENCY,
    maximumFractionDigits: 0,
  });

  const getFormattedPercentage = () => {
    let valueToCost = null;
    if (cost !== 0) {
      valueToCost = value / cost;
    } else if (value === 0) {
      valueToCost = 0;
    }

    if (valueToCost === null) {
      return null;
    }

    // We want to prevent very small negative numbers which appear as negative zero percentage
    const roundedValueToCost = Math.abs(valueToCost) < 0.005 ? 0 : valueToCost;
    return `(${formatPercentage(roundedValueToCost)})`;
  };

  return (
    <Stack direction="row" gap={1}>
      <Text variant={row.group ? 'body-bold' : 'body-regular'} size="M" color="text1">
        {formattedSavings}
      </Text>
      <Text variant={row.group ? 'body-bold' : 'body-regular'} size="M" color="text3">
        {getFormattedPercentage()}
      </Text>
    </Stack>
  );
};

export default SavingsCell;
