import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import Loadable from '@vertice/utils/src/async/loadable';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

const FEATURE_MAIN = FEATURES.AWS_COST_INSIGHT;
const FEATURE_RIO = FEATURES.AWS_RIO;
const FEATURE_ANALYTICS = FEATURES.AWS_ANALYTICS;
const FEATURE_SPRIO = FEATURES.AWS_SPRIO;
const FEATURE_SPRIO_COMPUTE_SP = FEATURES.AWS_SPRIO_COMPUTE_SP;
const FEATURE_SPRIO_EC2_SP = FEATURES.AWS_SPRIO_EC2_SP;
const FEATURE_SPRIO_SAGEMAKER_SP = FEATURES.AWS_SPRIO_SAGEMAKER_SP;
const FEATURE_SPRIO_EC2_RI = FEATURES.AWS_SPRIO_EC2_RI;
const FEATURE_SPRIO_RDS_RI = FEATURES.AWS_SPRIO_RDS_RI;
const FEATURE_SPRIO_OPENSEARCH_RI = FEATURES.AWS_SPRIO_OPENSEARCH_RI;
const FEATURE_SPRIO_ELASTICACHE_RI = FEATURES.AWS_SPRIO_ELASTICACHE_RI;
const FEATURE_SPRIO_REDSHIFT_RI = FEATURES.AWS_SPRIO_REDSHIFT_RI;

export enum CloudSubFeature {
  ACTIVE_INTEGRATION = 'activateIntegration',
  ACTIVE_SPEND_AUDIT = 'activeSpendAudit',
  ACTIVE_EDP = 'activeEDP',
  LIMITED_EDP = 'limitedEDP',
  RIO = 'rio',
  ANALYTICS = 'analytics',
  VISIBLE_TESTS = 'visibleTests',
  SPRIO = 'sprio',
  SPRIO_COMPUTE_SP = 'sprioComputeSP',
  SPRIO_EC2_SP = 'sprioEC2SP',
  SPRIO_SAGEMAKER_SP = 'sprioSagemakerSP',
  SPRIO_EC2_RI = 'sprioEC2RI',
  SPRIO_RDS_RI = 'sprioRDSRI',
  SPRIO_OPENSEARCH_RI = 'sprioOpenSearchRI',
  SPRIO_ELASTICACHE_RI = 'sprioElasticacheRI',
  SPRIO_REDSHIFT_RI = 'sprioRedshiftRI',
}

export type CloudFeature =
  | { isMainFeatureEnabled: false }
  | {
      isMainFeatureEnabled: true;
      subFeatures: Record<CloudSubFeature, boolean>;
    };

const useCloudFeature = (): Loadable<CloudFeature> => {
  const { getFeature, isEnabled, isLoading } = useFeatures();

  return isLoading
    ? { isLoading: true }
    : {
        isLoading: false,
        data: isEnabled(FEATURE_MAIN)
          ? {
              isMainFeatureEnabled: true,
              subFeatures: {
                activateIntegration: getFeature(FEATURE_MAIN)?.properties?.activateIntegration ?? false,
                activeSpendAudit: getFeature(FEATURE_MAIN)?.properties?.activeSpendAudit ?? true,
                activeEDP: getFeature(FEATURES.AWS_EDP_INSIGHT)?.enabled ?? false,
                limitedEDP: getFeature(FEATURES.AWS_EDP_INSIGHT)?.properties?.limited ?? false,
                visibleTests: getFeature(FEATURE_MAIN)?.properties?.visibleTests ?? '',
                rio: getFeature(FEATURE_RIO)?.enabled ?? false,
                analytics: getFeature(FEATURE_ANALYTICS)?.enabled ?? false,
                sprio: getFeature(FEATURE_SPRIO)?.enabled ?? false,
                sprioComputeSP: getFeature(FEATURE_SPRIO_COMPUTE_SP)?.enabled ?? false,
                sprioEC2SP: getFeature(FEATURE_SPRIO_EC2_SP)?.enabled ?? false,
                sprioSagemakerSP: getFeature(FEATURE_SPRIO_SAGEMAKER_SP)?.enabled ?? false,
                sprioEC2RI: getFeature(FEATURE_SPRIO_EC2_RI)?.enabled ?? false,
                sprioRDSRI: getFeature(FEATURE_SPRIO_RDS_RI)?.enabled ?? false,
                sprioOpenSearchRI: getFeature(FEATURE_SPRIO_OPENSEARCH_RI)?.enabled ?? false,
                sprioElasticacheRI: getFeature(FEATURE_SPRIO_ELASTICACHE_RI)?.enabled ?? false,
                sprioRedshiftRI: getFeature(FEATURE_SPRIO_REDSHIFT_RI)?.enabled ?? false,
              },
            }
          : { isMainFeatureEnabled: false },
      };
};

export default useCloudFeature;
