import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled, useTheme } from '@mui/material';
import { PieChart } from 'react-minimal-pie-chart';
import { LoadableComponent } from '@vertice/components';
import { useFormatCurrency } from '@vertice/utils/src/formatting/currency';
import { Text, EllipsisText } from '@verticeone/design-system/src';
import { PieChartItem, useDepartmentChartData } from '../hooks/useDepartmentChartData';

const Block = styled('div')<{ $color: string }>(({ theme, $color }) => ({
  backgroundColor: $color,
  width: '14px',
  height: '14px',
  borderRadius: theme.spacing(1),
}));

const DepartmentDetail = ({ title, color, currency, value }: PieChartItem) => {
  const formatCurrency = useFormatCurrency();

  return (
    <Stack flexDirection="row" justifyContent="space-between" gap={1} minWidth={200}>
      <Stack flexDirection="row" gap={2} alignItems="center">
        <Block $color={color} />
        <EllipsisText size="S" variant="body-regular" color="text2">
          {title}
        </EllipsisText>
      </Stack>
      <Text size="S" variant="body-bold" color="text1">
        {formatCurrency(value, { currency, maximumFractionDigits: 0 })}
      </Text>
    </Stack>
  );
};

const ChartStack = styled(Stack)(({ theme }) => ({
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: theme.spacing(39),
  minHeight: theme.spacing(39),

  '& svg': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));
const ChartText = styled(Text)(({ theme }) => ({
  width: theme.spacing(20),
  textAlign: 'center',
}));
const LegendStack = styled(Stack)({
  flexGrow: 1,
  minWidth: 0,
  overflowY: 'auto',
});
const PlaceholderText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.color3,
  textAlign: 'center',
}));

export const DepartmentCosts = () => {
  const { isLoading, data } = useDepartmentChartData();
  const { t } = useTranslation();
  const theme = useTheme();

  const isEmpty = !data.length;
  const EMPTY_CHART_DATA = [
    {
      color: theme.palette.core.color3,
      value: 1,
      title: '',
      currency: '',
    },
  ];

  return (
    <Stack flexDirection="row" gap={6} height="100%" alignItems="center">
      <LoadableComponent isLoading={isLoading}>
        <ChartStack>
          <PieChart
            data={isEmpty ? EMPTY_CHART_DATA : data}
            lineWidth={18}
            paddingAngle={isEmpty ? 0 : 3}
            startAngle={270}
            radius={50}
          />
          <ChartText size="S" variant="caption">
            {t('DASHBOARD.PIECHART_TITLE_DEPARTMENT')}
          </ChartText>
        </ChartStack>

        <LegendStack gap={2}>
          {isEmpty ? (
            <Stack justifyContent="center" alignItems="center" flex={1}>
              <PlaceholderText size="S" variant="body-regular">
                {t('DASHBOARD.NO_DEPARTMENTS')}
              </PlaceholderText>
            </Stack>
          ) : (
            data.map((department, index) => <DepartmentDetail {...department} key={index} />)
          )}
        </LegendStack>
      </LoadableComponent>
    </Stack>
  );
};
