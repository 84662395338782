import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system/src';
import { JiraSetupStep } from './types';

type ActionButtonProps = {
  activeStep: JiraSetupStep;
};

export const ActionButton = ({ activeStep }: ActionButtonProps) => {
  const { t } = useTranslation();

  const [, /*isDisconnectDialogOpened*/ setIsDisconnectDialogOpened] = useState(false);

  if (activeStep === JiraSetupStep.CONNECTION) return null;

  return (
    <>
      <Button variant="solid" size="M" color="neutral" onClick={() => setIsDisconnectDialogOpened(true)}>
        {t('PREFERENCES.INTEGRATIONS.JIRA.DISCONNECT_BUTTON')}
      </Button>
      {/* TODO: Implement Dialog as part of GREEN-1035 */}
      {/* <DisconnectJiraDialog
        isOpened={isDisconnectDialogOpened}
        isLoading={isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress}
        onSubmit={deactivateJiraIntegration}
        onClose={() => setIsDisconnectDialogOpened(false)}
      /> */}
    </>
  );
};
